import { ValueObjectMetaEnum } from "@pscsrvlab/psc-react-components";
import { TFunction } from "i18next";

export function isNullish<T>(v: T | null | undefined): v is null | undefined {
  return v === null || v === undefined;
}

export function hasValue<T>(v: T | null | undefined): v is T {
  return !isNullish(v);
}

export function filterNotNullish<T>(arr: (T | null | undefined)[]): T[] {
  return arr.filter(hasValue);
}

export const isAnyValueOf =
  <T extends readonly (string | number)[]>(values: T) =>
  (value: unknown): value is T[number] =>
    values.some((v) => value === v);

export const sleep = (waitTimeMillis: number) =>
  new Promise((resolve) => setTimeout(resolve, waitTimeMillis));

/**
 * メタデータのcodeからメッセージを取得する
 * @param t
 * @param valueObjectMeta
 * @param value
 */
export const getMessageFromEnumValue = <T extends [string, ...string[]]>(
  t: TFunction<"translation", undefined, "translation">,
  valueObjectMeta: ValueObjectMetaEnum<T>,
  value?: T[number] | null,
): string | undefined => {
  if (isNullish(value)) {
    return undefined;
  }

  // メタデータからcodeを取得
  const code = valueObjectMeta.dict[value];

  // codeからメッセージを取得
  return t(code) ?? undefined;
};

export function parseIntOrNull(text: string | null | undefined): number | null {
  if (isNullish(text)) return null;
  const intValue = Number.parseInt(text);
  if (Number.isNaN(intValue)) return null;
  return intValue;
}

export function capitalizeFirstLetter(text: string): string {
  if (text.length <= 0) return "";
  return text.charAt(0).toUpperCase() + text.slice(1);
}

// 参考: https://stackoverflow.com/a/54974076/12579447
export function hasDuplicates(arr: any[]): boolean {
  return new Set(arr).size !== arr.length;
}
