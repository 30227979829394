import { AttachmentFileInformation } from "../../../../../../lib/object/value/attachment-file-information";
import { CMButtonFormCommentProps } from "@pscsrvlab/psc-react-components/src/components/form/comment";
import { ComponentStyleProps } from "../../../../../../lib/styles/props/component-style-props";
import React, { memo, useCallback, useState } from "react";
import {
  CMFormFileUpload,
  DeleteConfirmationModal,
} from "@pscsrvlab/psc-react-components";
import { UploadButtonEmpty } from "../../../../../ui/button/UploadButtonEmpty/UploadButtonEmpty";
import useFileUpload from "../../../../../../hooks/use-file-upload";
import useFileDownload from "../../../../../../hooks/use-file-download";
import { isNullish } from "../../../../../../lib/util/common-util";
import { useDisclosure } from "@chakra-ui/react";
import { useAppTranslation } from "../../../../../../hooks/use-app-translation";
import { FormSection } from "../../../../../ui/form/FormSection/FormSection";

export type AttachmentFilesReportSectionProps = {
  editMode: "editable" | "readOnly";

  projectId: number;

  value: AttachmentFileInformation[];

  onChange: (
    change: (
      before: AttachmentFileInformation[],
    ) => AttachmentFileInformation[],
  ) => void;

  /**
   * コメントボタンのprops。
   * これが存在すれば、コメントボタンを表示する。
   */
  commentButtonProps?: CMButtonFormCommentProps | undefined;
} & ComponentStyleProps;

/**
 * 添付資料セクション
 */
export const AttachmentFilesReportSection = memo(
  function AttachmentFilesReportSection({
    editMode,

    projectId,

    value,
    onChange,
    commentButtonProps,
    sx,
    ...rest
  }: AttachmentFilesReportSectionProps) {
    const { t } = useAppTranslation();
    const { fileUpload } = useFileUpload();
    const { fileDownload } = useFileDownload();

    //ファイルアップロード時の処理。
    const handleAddFile = useCallback(
      async (_sectionId: string, _subSectionId: string, file: File) => {
        const response = await fileUpload(file, "public", null, projectId);
        if (isNullish(response)) return;

        const newFile: AttachmentFileInformation = {
          storageFileId: response.id,
          attachmentFileType: "other",
          attachmentFileName: file.name,
          uploadedAt: response.created.datetime,
        };
        onChange?.((before) => [...before, newFile]);
      },
      [fileUpload, onChange, projectId],
    );

    //添付ファイルダウンロード時の処理。
    const handleDownloadFile = useCallback(
      (fileId: string, fileName: string) => {
        void fileDownload(fileId, fileName);
      },
      [fileDownload],
    );

    // モーダル制御
    const {
      isOpen: isOpenDeleteModal,
      onOpen: onOpenDeleteModal,
      onClose: onCloseDeleteModal,
    } = useDisclosure();

    const [deletingFileId, setDeletingFileId] = useState("");

    //添付ファイル削除時の処理。
    const handleDeleteFile = useCallback(() => {
      onChange?.((before) =>
        before.filter((val) => val?.storageFileId !== parseInt(deletingFileId)),
      );
    }, [deletingFileId, onChange]);

    const handleFileDeleteButtonClick = useCallback(
      (_sectionId: string, _subSectionId: string, fileId: string) => {
        setDeletingFileId(fileId);
        onOpenDeleteModal();
      },
      [onOpenDeleteModal],
    );

    return (
      <>
        <FormSection
          title={t("lbl.添付資料")}
          commentButtonProps={commentButtonProps}
          sx={sx}
          {...rest}
        >
          {/*todo:修正依頼コメントが書類外に表示することはできるか？*/}
          <CMFormFileUpload
            value={{
              subSectionContents: [
                {
                  editMode: editMode,
                  childrenButton: <UploadButtonEmpty />,
                  files: value.map((val) => {
                    return {
                      id: val.storageFileId,
                      name: val.attachmentFileName,
                      url: "",
                      date: val.uploadedAt,
                      deleteButtonTip: t("lbl.ファイルを削除"),
                    };
                  }),
                },
              ],
            }}
            onDownloadFile={handleDownloadFile}
            onAddFile={handleAddFile}
            onDeleteFile={(
              sectionId: string,
              subSectionId: string,
              fileId: string,
            ) => handleFileDeleteButtonClick(sectionId, subSectionId, fileId)}
          />
        </FormSection>
        <DeleteConfirmationModal
          isOpen={isOpenDeleteModal}
          title={t("lbl.確認ポップアップタイトル")}
          message={t("mes.添付資料削除確認メッセージ")}
          deleteButtonLabel={t("btn.削除ボタン")}
          cancelButtonLabel={t("btn.キャンセルボタン")}
          onConfirm={handleDeleteFile}
          onCancel={onCloseDeleteModal}
          onClose={onCloseDeleteModal}
        />
      </>
    );
  },
);
