import {
  CMFormFileUpload,
  DeleteConfirmationModal,
} from "@pscsrvlab/psc-react-components";
import React, { memo, useCallback, useMemo, useState } from "react";
import {
  isNullish,
  parseIntOrNull,
} from "../../../../../../lib/util/common-util";
import { UploadButtonEmpty } from "../../../../../ui/button/UploadButtonEmpty/UploadButtonEmpty";
import { FileUploadItemContent } from "@pscsrvlab/psc-react-components/src/components/file-upload/types";
import useFileDownload from "../../../../../../hooks/use-file-download";
import useFileUpload from "../../../../../../hooks/use-file-upload";
import { AttachmentFileInformation } from "../../../../../../lib/object/value/attachment-file-information";
import { attachmentFileToFileUploadItem } from "../../../../../../lib/util/app-util";
import { useAppTranslation } from "../../../../../../hooks/use-app-translation";
import { FormSection } from "../../../../../ui/form/FormSection/FormSection";
import { useDisclosure } from "@chakra-ui/react";

export type CellStockDeliveryAttachmentFilesSectionProps = {
  /**
   * 画面編集モード
   */
  editMode: "editable" | "readOnly";

  projectId: number;

  /**
   * 添付ファイル
   */
  value: AttachmentFileInformation[];

  /**
   * ユーザーの権限情報
   * 事務局ユーザーの場合,true
   */
  isOfficeMember: boolean;

  onChange: (
    change: (
      before: AttachmentFileInformation[],
    ) => AttachmentFileInformation[],
  ) => void;
};

/**
 * FN30-S09細胞発送管理
 * 添付資料セクション
 */
export const CellStockDeliveryAttachmentFilesSection = memo(
  function CellStockDeliveryAttachmentFilesSection({
    editMode,

    projectId,

    value,
    isOfficeMember,
    onChange,
  }: CellStockDeliveryAttachmentFilesSectionProps) {
    const { t } = useAppTranslation();
    const { fileUpload } = useFileUpload();
    const { fileDownload } = useFileDownload();

    const {
      isOpen: isOpenDeleteModal,
      onOpen: onOpenDeleteModal,
      onClose: onCloseDeleteModal,
    } = useDisclosure();
    const [deletingFileId, setDeletingFileId] = useState<number | null>(null);

    const getFileList = useCallback(
      (
        attachmentFileType:
          | "cell_treatment_warnings"
          | "donor_information"
          | "test_report"
          | "other",
      ): FileUploadItemContent[] => {
        return value
          .filter((value) => value.attachmentFileType === attachmentFileType)
          .map((v) => attachmentFileToFileUploadItem(t, v));
      },
      [t, value],
    );

    const filesCellTreatmentWarnings = useMemo(() => {
      return getFileList("cell_treatment_warnings");
    }, [getFileList]);
    const filesDonorInformation = useMemo(() => {
      return getFileList("donor_information");
    }, [getFileList]);
    const filesTestReport = useMemo(() => {
      return getFileList("test_report");
    }, [getFileList]);
    const filesOther = useMemo(() => {
      return getFileList("other");
    }, [getFileList]);

    /**
     * ファイルアップロード処理
     */
    const handleAddFile = useCallback(
      async (
        _sectionId: string,
        subSectionId:
          | "cell_treatment_warnings"
          | "donor_information"
          | "test_report"
          | "other",
        file: File,
      ) => {
        const response = await fileUpload(file, "public", null, projectId);
        if (isNullish(response)) return;

        const newFile: AttachmentFileInformation = {
          storageFileId: response.id,
          attachmentFileType: subSectionId,
          attachmentFileName: file.name,
          uploadedAt: response.created.datetime,
        };
        // 呼び出し元に通知
        onChange?.((before) => [...before, newFile]);
      },
      [fileUpload, onChange, projectId],
    );

    /**
     * ファイルダウンロード処理
     */
    const handleDownloadFile = useCallback(
      (fileId: string, fileName: string) => {
        // ダウンロード処理の呼び出し
        void fileDownload(fileId, fileName);
      },
      [fileDownload],
    );

    /**
     * 添付ファイル削除時に呼ばれる。
     */
    const handleDeleteFile = useCallback(
      async (_sectionId: string, _subSectionId: string, fileId: string) => {
        const intFileId = parseIntOrNull(fileId);
        if (isNullish(intFileId)) return;
        setDeletingFileId(intFileId);
        onOpenDeleteModal();
      },
      [onOpenDeleteModal],
    );
    const handleConfirmDelete = useCallback(async () => {
      onChange?.((before) =>
        before.filter((v) => v.storageFileId !== deletingFileId),
      );
      onCloseDeleteModal();
      setDeletingFileId(null);
    }, [deletingFileId, onCloseDeleteModal, onChange]);

    return (
      <>
        <FormSection title={t("lbl.添付資料")}>
          <CMFormFileUpload
            onAddFile={handleAddFile}
            onDeleteFile={handleDeleteFile}
            onDownloadFile={handleDownloadFile}
            value={{
              id: "cell_delivery",
              title: "",
              subSectionContents: [
                {
                  editMode: isOfficeMember ? editMode : "readOnly",
                  id: "cell_treatment_warnings",
                  title: t("lbl.細胞の取り扱い注意情報"),
                  childrenButton: <UploadButtonEmpty />,
                  files: filesCellTreatmentWarnings,
                },
                {
                  editMode: isOfficeMember ? editMode : "readOnly",
                  id: "donor_information",
                  title: t("lbl.ドナー情報"),
                  childrenButton: <UploadButtonEmpty />,
                  files: filesDonorInformation,
                },
                {
                  editMode: isOfficeMember ? editMode : "readOnly",
                  id: "test_report",
                  title: t("lbl.試験成績書"),
                  childrenButton: <UploadButtonEmpty />,
                  files: filesTestReport,
                },
                {
                  editMode: isOfficeMember ? editMode : "readOnly",
                  id: "other",
                  title: t("lbl.その他"),
                  childrenButton: <UploadButtonEmpty />,
                  files: filesOther,
                },
              ],
            }}
          />
        </FormSection>

        <DeleteConfirmationModal
          isOpen={isOpenDeleteModal}
          onClose={onCloseDeleteModal}
          title={t("lbl.確認ポップアップタイトル")}
          message={t("mes.添付資料削除確認メッセージ")}
          deleteButtonLabel={t("btn.削除ボタン")}
          cancelButtonLabel={t("btn.キャンセルボタン")}
          onConfirm={handleConfirmDelete}
        />
      </>
    );
  },
);
