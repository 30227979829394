import { ComponentStyleProps } from "../../../../../../lib/styles/props/component-style-props";
import { memo } from "react";
import { useAppSelector } from "../../../../../../hooks/redux-hooks";
import { selectUserInfo } from "../../../../../../store/auth/slice";
import { hasValue } from "../../../../../../lib/util/common-util";
import { AnnualReportDocument } from "../AnnualReportDocument/AnnualReportDocument";
import { RevisionRequestItemViewModel } from "../../../../../../lib/object/vm/revision-request-view-model";
import { AnnualReportViewModel } from "../../../../../../lib/object/vm/annual-report-view-model";

export type AnnualReportDocumentReadOnlyProps = {
  vm: AnnualReportViewModel;

  /**
   * 修正依頼。
   */
  revisionRequestItems?: RevisionRequestItemViewModel[];
  /**
   * 修正依頼コメントを選択したときのコールバック。
   * 未選択状態になる場合はnullを送出する。
   */
  onSelectComment?: (path: string | null) => void;
  revisionMode?: "none" | "readOnly";
} & ComponentStyleProps;

export const AnnualReportDocumentReadOnly = memo(
  function AnnualReportDocumentReadOnly({
    vm,

    revisionRequestItems,
    onSelectComment,
    revisionMode,

    sx,
    ...rest
  }: AnnualReportDocumentReadOnlyProps) {
    const loginUserInfo = useAppSelector(selectUserInfo);

    return (
      <>
        {hasValue(loginUserInfo) && (
          <AnnualReportDocument
            loginUserInfo={loginUserInfo}
            editMode={"readOnly"}
            value={vm}
            revisionRequestItems={revisionRequestItems}
            onSelectComment={onSelectComment}
            revisionMode={revisionMode}
            sx={sx}
            {...rest}
          />
        )}
      </>
    );
  },
);
