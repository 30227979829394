import {
  CMFormControlHeader,
  CMFormInputText,
} from "@pscsrvlab/psc-react-components";
import { HStack, Stack } from "@chakra-ui/react";
import { memo } from "react";
import { CMButtonFormCommentProps } from "@pscsrvlab/psc-react-components/src/components/form/comment";
import { UserInformation } from "../../../../../../lib/object/value/user-information";
import { ComponentStyleProps } from "../../../../../../lib/styles/props/component-style-props";
import { titleAndPositionMeta } from "../../../../../../lib/object/value/title-and-position";
import { phoneNumberMeta } from "../../../../../../lib/object/value/phone-number";
import { mailAddressMeta } from "../../../../../../lib/object/value/mail-address";
import { AppLink } from "../../../../../ui/link/AppLink/AppLink";
import { useAppTranslation } from "../../../../../../hooks/use-app-translation";
import { FormSection } from "../../../../../ui/form/FormSection/FormSection";

export type PrincipalInvestigatorReportSectionProps = {
  value: Partial<UserInformation>;

  /**
   * コメントボタンのprops。
   * これが存在すれば、コメントボタンを表示する。
   */
  commentButtonProps?: CMButtonFormCommentProps;
} & ComponentStyleProps;

/**
 * 研究責任者セクション
 */
export const PrincipalInvestigatorReportSection = memo(
  function PrincipalInvestigatorReportSection({
    value,
    commentButtonProps,
    sx,
    ...rest
  }: PrincipalInvestigatorReportSectionProps) {
    const { t } = useAppTranslation();

    return (
      <FormSection
        title={t("lbl.研究責任者")}
        commentButtonProps={commentButtonProps}
        sx={sx}
        {...rest}
      >
        <Stack direction={"column"} spacing={"10px"}>
          <Stack direction={"row"}>
            <Stack w={"50%"}>
              <CMFormControlHeader label={t("lbl.氏名")} />
              <AppLink
                isExternal
                to={`/user/${value.appUserId}`}
                pl={"10px"}
                mt={"10px"}
              >
                {value.fullName}
              </AppLink>
            </Stack>
            <CMFormInputText
              label={t("lbl.所属・職名")}
              editMode={"readOnly"}
              value={value.titleAndPosition}
              valueObjectMeta={titleAndPositionMeta}
              sx={{ maxW: "50%" }}
            />
          </Stack>
          <HStack>
            <CMFormInputText
              label={t("lbl.電話番号")}
              editMode={"readOnly"}
              value={value.phoneNumber}
              valueObjectMeta={phoneNumberMeta}
              sx={{ maxW: "50%" }}
            />
            <CMFormInputText
              label={t("lbl.メールアドレス")}
              editMode={"readOnly"}
              value={value.mailAddress}
              valueObjectMeta={mailAddressMeta}
              sx={{ maxW: "50%" }}
            />
          </HStack>
        </Stack>
      </FormSection>
    );
  },
);
