import { Outlet } from "react-router-dom";
import { Flex } from "@chakra-ui/react";
import { FN00S01MainMenu } from "../../model/menu/FN00S01MainMenu/FN00S01MainMenu";

export const MainLayout = () => {
  return (
    <Flex
      flexFlow={"column nowrap"}
      justifyContent={"stretch"}
      alignItems={"stretch"}
      height={"100%"}
      overflow={"hidden"}
      className={"MainLayout"}
    >
      <FN00S01MainMenu />
      <Flex
        flex={1}
        minH={0}
        flexFlow={"column nowrap"}
        justifyContent={"stretch"}
        alignItems={"stretch"}
        overflow={"hidden"}
        className={"MainLayout-body"}
      >
        <Outlet />
      </Flex>
    </Flex>
  );
};
