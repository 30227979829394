import { Divider, useDisclosure, VStack } from "@chakra-ui/react";
import {
  CMSectionMultipleCardAddable,
  DeleteConfirmationModal,
} from "@pscsrvlab/psc-react-components";
import { ComponentStyleProps } from "../../../../../../../../../lib/styles/props/component-style-props";
import React, { memo, useCallback, useMemo, useState } from "react";
import { RequestingCell } from "../../../../../../../../../lib/object/value/requesting-cell";
import { CellType } from "../../../../../../../../../lib/object/value/cell-type";
import { CMButtonFormCommentProps } from "@pscsrvlab/psc-react-components/src/components/form/comment";
import { useSectionDiffMode } from "../../../../../../../../../hooks/document/change-application/use-section-diff-mode";
import { ChangeReasonForm } from "../../form/ChangeReasonForm/ChangeReasonForm";
import { RequestingCellViewModel } from "../../../ApplicationDocument";
import { nextGroupLocalKey } from "../../../../../../../../../lib/util/app-util";
import { RequestingCellCard } from "../../card/RequestingCellCard/RequestingCellCard";
import { useKeyedData } from "../../../../../../../../../hooks/use-keyed-data";
import { FormSection } from "../../../../../../../../ui/form/FormSection/FormSection";
import { useAppTranslation } from "../../../../../../../../../hooks/use-app-translation";
import { ChangeReasonItemViewModel } from "../../../../../../../../../lib/object/vm/change-application-view-model";

export type RequestingCellsSectionProps = {
  documentType:
    | "new_application"
    | "change_application"
    | "project_force_update"
    | "project_force_create"
    | "project_content";
  editMode: "editable" | "readOnly";

  valueResearchCells: RequestingCellViewModel[];
  valueClinicalCells: RequestingCellViewModel[];

  onChangeResearchCells?: (
    change: (before: RequestingCellViewModel[]) => RequestingCellViewModel[],
  ) => void;
  onChangeClinicalCells?: (
    change: (before: RequestingCellViewModel[]) => RequestingCellViewModel[],
  ) => void;

  changedFromResearchCells?: RequestingCell[];
  changedFromClinicalCells?: RequestingCell[];
  changeReason?: string;
  onChangeReason?: (
    change: (before: ChangeReasonItemViewModel) => ChangeReasonItemViewModel,
  ) => void;
  displayChangesOnly?: boolean;

  /**
   * コメントボタンのprops。
   * これが存在すれば、コメントボタンを表示する。
   */
  commentButtonProps?: CMButtonFormCommentProps;
  // TODO 途中で追加・削除したときのコメントの挙動を確認する。
  /**
   * 研究用株のコメントボタンのprops。
   * これが存在すれば、コメントボタンを表示する。
   */
  commentButtonPropsResearchCells?: {
    key: number;
    value?: CMButtonFormCommentProps;
  }[];
  /**
   * 臨床用株のコメントボタンのprops。
   * これが存在すれば、コメントボタンを表示する。
   */
  commentButtonPropsClinicalCells?: {
    key: number;
    value?: CMButtonFormCommentProps;
  }[];
} & ComponentStyleProps;

/**
 * 使用する細胞セクション
 */
export const RequestingCellsSection = memo(function RequestingCellsSection({
  documentType,
  editMode,

  valueResearchCells,
  valueClinicalCells,
  onChangeResearchCells,
  onChangeClinicalCells,

  changedFromResearchCells,
  changedFromClinicalCells,
  changeReason,
  onChangeReason,
  displayChangesOnly,

  commentButtonProps,
  commentButtonPropsResearchCells,
  commentButtonPropsClinicalCells,

  sx,
  ...rest
}: RequestingCellsSectionProps) {
  const { t } = useAppTranslation();

  const { keyedValues: allResearchCellsIncludingDeleted } = useKeyedData(
    valueResearchCells,
    changedFromResearchCells,
  );
  const { keyedValues: allClinicalCellsIncludingDeleted } = useKeyedData(
    valueClinicalCells,
    changedFromClinicalCells,
  );

  const getEmptyCell: (cellType: CellType) => RequestingCellViewModel =
    useCallback(
      (cellType) => {
        const nextKey = nextGroupLocalKey(
          (cellType === "research_purpose"
            ? allResearchCellsIncludingDeleted
            : allClinicalCellsIncludingDeleted
          ).map((v) => v.value),
        );
        return {
          key: nextKey,
          cellStockId: undefined,
          cellNameJa: "",
          cellNameEn: "",
          cellType: cellType,
          cellNumber: undefined,
          cellCategoryNameJa: "",
          cellCategoryNameEn: "",
          cellCategoryNumber: undefined,
          cellProvisionType: "from_foundation",
          stockProviderOrganizationName: undefined,
          stockProviderProjectControlNumber: undefined,
        };
      },
      [allClinicalCellsIncludingDeleted, allResearchCellsIncludingDeleted],
    );

  /**
   * 細胞株を追加する。
   */
  const handleAdd = useCallback(
    (cellType: CellType) => {
      switch (cellType) {
        case "research_purpose":
          onChangeResearchCells?.((before) => [
            ...before,
            getEmptyCell("research_purpose"),
          ]);
          break;
        case "clinical_purpose":
          onChangeClinicalCells?.((before) => [
            ...before,
            getEmptyCell("clinical_purpose"),
          ]);
          break;
      }
    },
    [getEmptyCell, onChangeClinicalCells, onChangeResearchCells],
  );

  const handleChange = useCallback(
    (
      cellType: CellType,
      key: number,
      change: (before: RequestingCellViewModel) => RequestingCellViewModel,
    ) => {
      switch (cellType) {
        case "research_purpose": {
          onChangeResearchCells?.((before) => {
            return before.map((cell) => {
              if (cell.key === key) {
                return change(cell);
              } else {
                return cell;
              }
            });
          });
          break;
        }
        case "clinical_purpose": {
          onChangeClinicalCells?.((before) => {
            return before.map((cell) => {
              if (cell.key === key) {
                return change(cell);
              } else {
                return cell;
              }
            });
          });
          break;
        }
      }
    },
    [onChangeClinicalCells, onChangeResearchCells],
  );

  const {
    isOpen: isOpenDeleteResearchCellModal,
    onOpen: onOpenDeleteResearchCellModal,
    onClose: onCloseDeleteResearchCellModal,
  } = useDisclosure();

  const {
    isOpen: isOpenDeleteClinicalCellModal,
    onOpen: onOpenDeleteClinicalCellModal,
    onClose: onCloseDeleteClinicalCellModal,
  } = useDisclosure();

  /**
   * カード削除対象のカードインデックス
   */
  const [researchCellDeletingKey, setResearchCellDeletingKey] = useState(0);
  const [clinicalCellDeletingKey, setClinicalCellDeletingKey] = useState(0);

  /**
   * 研究用株カードの削除ボタン押下時の処理。
   */
  const handleResearchCellDeleteButtonClick = useCallback(
    (key: number) => {
      setResearchCellDeletingKey(key);
      onOpenDeleteResearchCellModal();
    },
    [onOpenDeleteResearchCellModal],
  );

  /**
   * 臨床用株カードの削除ボタン押下時の処理。
   */
  const handleClinicalCellDeleteButtonClick = useCallback(
    (key: number) => {
      setClinicalCellDeletingKey(key);
      onOpenDeleteClinicalCellModal();
    },
    [onOpenDeleteClinicalCellModal],
  );

  /**
   * モーダルの削除ボタン押下により、研究株カードが削除される。
   */
  const handleResearchCellConfirmDelete = useCallback(() => {
    onChangeResearchCells?.((before) =>
      before.filter((cell) => cell.key !== researchCellDeletingKey),
    );
    onCloseDeleteResearchCellModal();
  }, [
    onChangeResearchCells,
    onCloseDeleteResearchCellModal,
    researchCellDeletingKey,
  ]);

  /**
   * モーダルの削除ボタン押下により、臨床株カードが削除される。
   */
  const handleClinicalCellConfirmDelete = useCallback(() => {
    onChangeClinicalCells?.((before) =>
      before.filter((cell) => cell.key !== clinicalCellDeletingKey),
    );
    onCloseDeleteClinicalCellModal();
  }, [
    clinicalCellDeletingKey,
    onChangeClinicalCells,
    onCloseDeleteClinicalCellModal,
  ]);

  const mergedChangedFrom = useMemo(
    () => ({
      researchCells: changedFromResearchCells,
      clinicalCells: changedFromClinicalCells,
    }),
    [changedFromResearchCells, changedFromClinicalCells],
  );
  const mergedValue = useMemo(
    () => ({
      researchCells: valueResearchCells,
      clinicalCells: valueClinicalCells,
    }),
    [valueResearchCells, valueClinicalCells],
  );
  const sectionDiffMode = useSectionDiffMode(
    documentType,
    mergedChangedFrom,
    mergedValue,
    onChangeReason,
  );

  return (
    <>
      <FormSection
        title={t("lbl.使用する細胞")}
        displayMode={sectionDiffMode}
        displayChangesOnly={displayChangesOnly}
        commentButtonProps={commentButtonProps}
        sx={sx}
        {...rest}
      >
        <CMSectionMultipleCardAddable
          editMode={editMode}
          onAddSection={() => handleAdd("research_purpose")}
          buttonLabel={t("btn.研究用株を追加ボタン")}
        >
          <VStack spacing={"10px"} alignItems={"stretch"}>
            {allResearchCellsIncludingDeleted.map(
              ({ mode, value: v }, index) => (
                <RequestingCellCard
                  key={index}
                  documentType={documentType}
                  editMode={editMode}
                  mode={mode}
                  cellType={"research_purpose"}
                  index={index}
                  value={v}
                  onChange={handleChange}
                  onDelete={handleResearchCellDeleteButtonClick}
                  changedFrom={changedFromResearchCells?.find(
                    (cf) => cf.key === v.key,
                  )}
                  commentButtonProps={
                    commentButtonPropsResearchCells?.find(
                      (p) => p.key === v.key,
                    )?.value
                  }
                />
              ),
            )}
          </VStack>
        </CMSectionMultipleCardAddable>
        <Divider borderColor={"gray.400"} />
        <CMSectionMultipleCardAddable
          editMode={editMode}
          onAddSection={() => handleAdd("clinical_purpose")}
          buttonLabel={t("btn.臨床用株を追加ボタン")}
        >
          <VStack spacing={"10px"} alignItems={"stretch"}>
            {allClinicalCellsIncludingDeleted.map(
              ({ mode, value: v }, index) => (
                <RequestingCellCard
                  key={index}
                  documentType={documentType}
                  editMode={editMode}
                  mode={mode}
                  cellType={"clinical_purpose"}
                  index={index}
                  value={v}
                  onChange={handleChange}
                  onDelete={handleClinicalCellDeleteButtonClick}
                  changedFrom={changedFromClinicalCells?.find(
                    (cf) => cf.key === v.key,
                  )}
                  commentButtonProps={
                    commentButtonPropsClinicalCells?.find(
                      (p) => p.key === v.key,
                    )?.value
                  }
                />
              ),
            )}
          </VStack>
        </CMSectionMultipleCardAddable>
        {sectionDiffMode === "updated" && (
          <ChangeReasonForm
            value={changeReason}
            onChange={onChangeReason}
            editMode={editMode}
          />
        )}
      </FormSection>
      {/*研究用株カード削除時に出現するモーダル*/}
      <DeleteConfirmationModal
        isOpen={isOpenDeleteResearchCellModal}
        onClose={onCloseDeleteResearchCellModal}
        title={t("lbl.確認ポップアップタイトル")}
        message={t("mes.細胞削除確認メッセージ")}
        deleteButtonLabel={t("btn.削除ボタン")}
        cancelButtonLabel={t("btn.キャンセルボタン")}
        onConfirm={handleResearchCellConfirmDelete}
        onCancel={undefined}
      />
      {/*臨床用株カード削除時に出現するモーダル*/}
      <DeleteConfirmationModal
        isOpen={isOpenDeleteClinicalCellModal}
        onClose={onCloseDeleteClinicalCellModal}
        title={t("lbl.確認ポップアップタイトル")}
        message={t("mes.細胞削除確認メッセージ")}
        deleteButtonLabel={t("btn.削除ボタン")}
        cancelButtonLabel={t("btn.キャンセルボタン")}
        onConfirm={handleClinicalCellConfirmDelete}
        onCancel={undefined}
      />
    </>
  );
});
