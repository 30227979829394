import { ComponentStyleProps } from "../../../../../../lib/styles/props/component-style-props";
import { CMCommentThread } from "@pscsrvlab/psc-react-components";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  getMessageFromEnumValue,
  hasValue,
  isNullish,
} from "../../../../../../lib/util/common-util";
import log from "loglevel";
import {
  useReadInquiryMutation,
  useRespondInquiryMutation,
} from "../../../../../../store/api/generated/stock-request-api";
import { Inquiry } from "../../../../../../lib/object/entity/inquiry";
import {
  CommentHeaderContent,
  CommentThreadInputContent,
  MainCommentBlockItemContent,
  ReplyCommentBlockItemContent,
} from "@pscsrvlab/psc-react-components/src/components/comment/types";
import { FileUploadItemContent } from "@pscsrvlab/psc-react-components/src/components/file-upload/types";
import { Role, roleMeta } from "../../../../../../lib/object/value/role";
import { documentTypeMeta } from "../../../../../../lib/object/value/document-type";
import { stockRequestApi as api } from "../../../../../../store/api/enhanced-api";
import { useAppSelector } from "../../../../../../hooks/redux-hooks";
import {
  selectHasRole,
  selectUserInfo,
} from "../../../../../../store/auth/slice";
import { getDateTimeStr } from "../../../../../../lib/util/common-date-util";
import { AttachmentFileInformation } from "../../../../../../lib/object/value/attachment-file-information";
import {
  InquiryReply,
  inquiryReplyMeta,
} from "../../../../../../lib/object/value/inquiry-reply";
import useFileDownload from "../../../../../../hooks/use-file-download";
import useFileUpload from "../../../../../../hooks/use-file-upload";
import { datetimeMeta } from "../../../../../../lib/object/value/datetime";
import {
  backendErrorToErrorMessage,
  validationErrorToToastMessage,
} from "../../../../../../lib/util/error-util";
import useCustomToast from "../../../../../../hooks/use-custom-toast";
import { useDisclosure } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { useAppTranslation } from "../../../../../../hooks/use-app-translation";
import { useAppGetAppUserQuery } from "../../../../../../hooks/query/use-app-get-app-user-query";
import { useAppGetProjectQuery } from "../../../../../../hooks/query/use-app-get-project-query";
import { useAppGetInstitutionQuery } from "../../../../../../hooks/query/use-app-get-institution-query";
import { useAppGetDocumentQuery } from "../../../../../../hooks/query/use-app-get-document-query";
import { ConfirmationModal } from "../../../../../ui/modal/ConfirmationModal/ConfirmationModal";

/**
 * 確認モーダル用のイベント
 */
type ModalEvent = {
  name: ModalEventType;
  message: string;
  func: (...args: any[]) => Promise<void>;
  funcArgs?: any[];
};

/**
 * 確認モーダルの種別
 */
type ModalEventType =
  // 添付ファイル削除
  | "deleteFile"
  // 質疑応答送信
  | "respondInquiry";

export type CommentThreadProps = {
  /**
   * 表示コンテンツ1件(質疑応答 検索結果)
   */
  inquiry: Inquiry;
} & ComponentStyleProps;
export const CommentThread = ({ inquiry }: CommentThreadProps) => {
  const { t } = useAppTranslation();
  const { errorToast } = useCustomToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  // ファイルアップロード
  const { fileUpload } = useFileUpload();
  // ファイルダウンロード
  const { fileDownload } = useFileDownload();

  // ログインユーザー情報
  const currentUserInfo = useAppSelector(selectUserInfo);
  // ログインユーザーの権限情報
  const { isOfficeMember, isApplicant } = useSelector(selectHasRole);

  // メインコメントユーザー
  const { data: mainUser } = useAppGetAppUserQuery(
    {
      appUserId: inquiry.created?.appUserId ?? -1,
    },
    {
      skip:
        inquiry.issuerRole === "office_member" || // 投稿者が事務局の場合、名前としては「事務局」と表示するだけだから、取得しなくて良い。
        isNullish(inquiry.created?.appUserId),
    },
  );

  // 返信コメントユーザー取得遅延クエリ
  const [getReplyUser] = api.useLazyGetAppUserQuery();

  // 案件情報取得クエリ
  const { data: project } = useAppGetProjectQuery(
    {
      projectId: inquiry.projectId ?? -1,
    },
    {
      skip: isNullish(inquiry.projectId),
    },
  );

  // 書類情報取得クエリ
  const { data: document } = useAppGetDocumentQuery(
    {
      documentId: inquiry.documentId ?? -1,
    },
    {
      skip: isNullish(inquiry.documentId),
    },
  );

  // 機関取得クエリ
  const { data: institution } = useAppGetInstitutionQuery(
    {
      institutionId: inquiry.institutionId ?? -1,
    },
    {
      skip: isNullish(inquiry.institutionId),
    },
  );

  // 質疑応答既読更新ミューテーション
  const [updateReadStatus] = useReadInquiryMutation();
  // 質疑応答送信ミューテーション
  const [respondInquiry] = useRespondInquiryMutation();
  // 現在日時取得用遅延クエリ
  const [lazyGetCurrentDatetimeQuery] = api.useLazyGetCurrentDatetimeQuery();

  // 確認モーダル制御用
  const [modalEvent, setModalEvent] = useState<ModalEvent>();
  // 返信コメント
  const [comment, setComment] = useState("");
  // 添付ファイル
  const [files, setFiles] = useState<FileUploadItemContent[]>([]);
  // 返信コメントエリアの表示項目リスト
  const [replyContents, setReplyContents] = useState<
    ReplyCommentBlockItemContent[]
  >([]);

  /**
   * 添付ファイルの表示用の形式への変換処理
   */
  const convertFiles = useCallback(
    (files: AttachmentFileInformation[]): FileUploadItemContent[] => {
      return files.map((file) => {
        return {
          id: file.storageFileId.toString(),
          name: file.attachmentFileName,
          date: file.uploadedAt,
        };
      });
    },
    [],
  );

  /**
   * ユーザー表示名の変換処理
   */
  const getDisplayUserName = useCallback(
    (userRole: Role, userFullName: string | undefined): string => {
      // 事務局メンバーの場合はロール名
      if (userRole === "office_member") {
        return getMessageFromEnumValue(t, roleMeta, userRole) ?? "-";
      }
      // 申請者の場合はユーザー名
      else if (userRole === "applicant") {
        return userFullName ?? "-";
      }
      return "-";
    },
    [t],
  );

  /**
   * 書類の表示名
   */
  const displayDocumentName = useMemo((): string => {
    if (isNullish(document)) {
      return "-";
    }
    const documentTypeLabel = getMessageFromEnumValue(
      t,
      documentTypeMeta,
      document.documentType,
    );
    if (hasValue(documentTypeLabel)) {
      return `${document.documentControlNumber} ${documentTypeLabel} `;
    }
    // それ以外の場合
    return "-";
  }, [document, t]);

  /**
   * 案件の遷移先URL
   */
  const projectUrl = useMemo((): string | undefined => {
    if (isNullish(inquiry.projectId)) {
      return undefined;
    }
    // 「FN30-S04（案件内容）」
    return `/project/${inquiry.projectId}`;
  }, [inquiry.projectId]);

  /**
   * 書類の遷移先URL
   */
  const documentUrl = useMemo((): string | undefined => {
    if (isNullish(inquiry.documentId)) {
      return undefined;
    }
    return `/document/${inquiry.documentId}`;
  }, [inquiry.documentId]);

  /**
   * 未読状態
   */
  const isUnread = useMemo(() => {
    if (isOfficeMember) {
      return inquiry.officeMemberReadState === "unread";
    }
    if (isApplicant) {
      return inquiry.applicantReadState === "unread";
    }
    return true;
  }, [
    inquiry.applicantReadState,
    inquiry.officeMemberReadState,
    isApplicant,
    isOfficeMember,
  ]);

  /**
   * 表示データの整形
   * 質疑応答：ヘッダエリア
   */
  const headerItem: CommentHeaderContent[] = useMemo(() => {
    return [
      {
        label: t("lbl.機関"),
        value: institution?.name ?? "-",
      },
      {
        label: t("lbl.案件"),
        value: project?.projectControlNumber ?? "-",
        url: projectUrl,
      },
      {
        label: t("lbl.書類"),
        value: displayDocumentName,
        url: documentUrl,
      },
    ];
  }, [
    t,
    institution?.name,
    project?.projectControlNumber,
    projectUrl,
    displayDocumentName,
    documentUrl,
  ]);

  /**
   * 表示データの整形
   * 質疑応答：メインコメントエリア（バッヂなど）
   */
  const mainItem: MainCommentBlockItemContent = useMemo(() => {
    return {
      senderLabel: t("lbl.質疑応答From"),
      senderName: getDisplayUserName(inquiry.issuerRole, mainUser?.fullName),
      replyCommentCount: inquiry.replies.length,
      replyCommentLabel: t("lbl.質疑返信件数"),
      mainText: inquiry.content,
      files: convertFiles(inquiry.attachmentFiles),
      buttonLabelOpen: t("btn.質疑応答開くボタン"),
      buttonLabelClose: t("btn.質疑応答閉じるボタン"),
      buttonLabelRead: t("btn.質疑応答既読ボタン"),
    };
  }, [
    t,
    getDisplayUserName,
    inquiry.issuerRole,
    inquiry.replies.length,
    inquiry.content,
    inquiry.attachmentFiles,
    mainUser,
    convertFiles,
  ]);

  /**
   * 表示データの整形
   * 質疑応答：メインコメントエリア
   */
  const mainContent = useMemo(() => {
    return {
      headerItem: headerItem,
      date: inquiry.created?.datetime,
      isUnread: isUnread,
      messageUnread: t("code.質疑応答既読状況.未読"),
      messageRead: t("code.質疑応答既読状況.既読"),
      title: inquiry.subject,
      mainItem: mainItem,
    };
  }, [
    headerItem,
    inquiry.created?.datetime,
    inquiry.subject,
    isUnread,
    t,
    mainItem,
  ]);

  /**
   * 表示データの整形
   * 質疑応答：返信コメントエリア
   */
  useEffect(() => {
    const init = async () => {
      const replyContents = await Promise.all(
        inquiry.replies.map(async (reply: InquiryReply) => {
          // ユーザー情報を取得
          const replyUser =
            reply.replierRole === "office_member"
              ? null // 投稿者が事務局の場合、名前としては「事務局」と表示するだけだから、取得しなくて良い。
              : await getReplyUser({
                  appUserId: reply.replierAppUserId,
                }).unwrap();
          return {
            senderLabel: t("lbl.質疑応答From"),
            senderName: getDisplayUserName(
              reply.replierRole,
              replyUser?.fullName,
            ),
            date: reply.replyDatetime,
            mainText: reply.content,
            files: convertFiles(reply.attachmentFiles),
          };
        }),
      );
      setReplyContents(replyContents);
    };
    init().then();
  }, [inquiry.replies, convertFiles, getDisplayUserName, getReplyUser, t]);

  /**
   * 表示データの整形
   * 質疑応答：新規コメントエリア
   */
  const commentInputContent: CommentThreadInputContent = useMemo(() => {
    return {
      files: files,
      comment: comment,
      buttonUploadLabel: t("btn.質疑応答アップロードボタン"),
      uploadingLabel: t("mes.アップロード中メッセージ"),
      buttonSubmitLabel: t("btn.質疑応答送信ボタン"),
    };
  }, [comment, files, t]);

  /**
   * ファイルアップロード処理
   */
  const handleAddFile = useCallback(
    async (file: File) => {
      // APIのファイルアップロード処理を呼び出し
      const response = await fileUpload(
        file,
        "public",
        inquiry.institutionId,
        inquiry.projectId,
      );
      // ファイルアップロード成功時
      if (hasValue(response)) {
        setFiles((before) => [
          ...before,
          {
            id: response.id.toString(),
            name: file.name,
            date: response.created.datetime,
          },
        ]);
      }
      // ファイルアップロード失敗時
      else {
        // コンポーネント側でエラーを出力している為、ここではログのみ出力
        log.error("ファイルアップロードの非同期処理でエラー発生");
      }
    },
    [fileUpload, inquiry.institutionId, inquiry.projectId],
  );

  /**
   * ファイルダウンロードのイベントハンドラ
   */
  const handleDownloadFile = useCallback(
    async (_commentThreadId: string, fileId: string, fileName: string) => {
      // ダウンロード処理の呼び出し
      await fileDownload(fileId, fileName);
    },
    [fileDownload],
  );

  /**
   * 既読状態更新のイベントハンドラ
   */
  const handleUpdateReadStatus = useCallback(async () => {
    // ログインユーザー情報、または質疑応答IDが取得できない場合は処理を行わない
    if (isNullish(currentUserInfo) || isNullish(inquiry.id)) {
      log.debug("handleUpdateReadStatus", "引数エラー");
      return;
    }
    try {
      // 既読更新処理
      await updateReadStatus({
        inquiryId: inquiry.id,
      }).unwrap();
    } catch (e) {
      log.error("既読状態更新の非同期処理でエラー発生");
      errorToast(backendErrorToErrorMessage(t, e));
    }
  }, [currentUserInfo, errorToast, inquiry.id, t, updateReadStatus]);

  /**
   * 添付ファイル削除のイベントハンドラ
   */
  const handleDeleteFile = useCallback(async (fileId: string) => {
    // 画面上の要素のみ削除を行い、実データは削除しない
    setFiles((before) => before.filter((file) => file.id !== fileId));
  }, []);

  /**
   * 質疑応答送信のイベントハンドラ
   */
  const handleRespondInquiry = useCallback(async () => {
    // ログインユーザー情報、または質疑応答IDが取得できない場合は処理を行わない
    if (isNullish(currentUserInfo) || isNullish(inquiry.id)) {
      log.debug("handleRespondInquiry", "引数エラー");
      return;
    }

    // 現在日時を取得
    const response = await lazyGetCurrentDatetimeQuery().unwrap();
    const datetime = datetimeMeta.toDomainObjectOrNull(
      response.currentDatetime,
    );

    // バリデーションを実行
    const validate = inquiryReplyMeta.validate({
      replierAppUserId: currentUserInfo.id,
      replierRole: currentUserInfo.role,
      content: comment,
      attachmentFiles: files.map((file) => {
        return {
          storageFileId: parseInt(file.id),
          attachmentFileType: "inquiry_attachment",
          attachmentFileName: file.name,
          uploadedAt: getDateTimeStr(file.date),
        };
      }),
      replyDatetime: datetime,
    });
    // バリデーションチェックNGの場合
    if (validate.state === "error") {
      // 最初のエラーをトースト表示
      errorToast(validationErrorToToastMessage(validate?.errors?.at(0)));
      return;
    }
    // バリデーションチェックOKの場合
    if (validate.state === "ok") {
      try {
        // JSON形式へ変換
        const json = inquiryReplyMeta.toJsonObjectOrNull(validate.value);
        if (isNullish(json)) {
          log.error("質疑応答送信のJSON変換処理でエラー発生");
          return;
        }
        // APIの質疑応答送信処理を呼び出し
        await respondInquiry({
          inquiryId: inquiry.id,
          inquiryReply: json,
        }).unwrap();

        // 処理に成功した場合はコメントと書類をクリア
        setComment("");
        setFiles([]);
      } catch (e) {
        log.error("質疑応答送信の非同期処理でエラー発生");
        errorToast(backendErrorToErrorMessage(t, e));
        return;
      }
    }
  }, [
    comment,
    currentUserInfo,
    errorToast,
    files,
    inquiry.id,
    lazyGetCurrentDatetimeQuery,
    respondInquiry,
    t,
  ]);

  /**
   * 確認モーダル表示のイベントハンドラ
   * @param event モーダルイベント
   */
  const handleOpenModal = useCallback(
    (event: ModalEvent) => {
      setModalEvent(event);
      onOpen();
    },
    [onOpen],
  );

  /**
   * 確認モーダルのOKボタン押下時のイベントハンドラ
   */
  const handleSubmit = useCallback(async () => {
    if (modalEvent?.funcArgs) {
      // イベント実行
      await modalEvent?.func(...modalEvent.funcArgs);
    } else {
      // イベント実行
      await modalEvent?.func();
    }
    setModalEvent(undefined);
    onClose();
  }, [modalEvent, onClose]);

  return (
    <>
      <CMCommentThread
        commentThreadId={inquiry.id ?? -1}
        mainContent={mainContent}
        replyContents={replyContents}
        commentInputContent={commentInputContent}
        onOpenArea={() => {}}
        onRead={handleUpdateReadStatus}
        onSubmit={() =>
          handleOpenModal({
            name: "respondInquiry",
            message: t("mes.質疑応答送信確認メッセージ"),
            func: handleRespondInquiry,
          })
        }
        onAddFile={handleAddFile}
        onDeleteFile={(fileId: string) => {
          handleOpenModal({
            name: "deleteFile",
            message: t("mes.添付資料削除確認メッセージ"),
            func: handleDeleteFile,
            funcArgs: [fileId],
          });
        }}
        onDownloadFile={handleDownloadFile}
        onChangeComment={setComment}
      />

      {/* 確認用モーダル */}
      <ConfirmationModal
        isOpen={isOpen}
        message={modalEvent?.message ?? ""}
        onSubmit={handleSubmit}
        onCancel={() => {
          // モーダルイベントの初期化
          setModalEvent(undefined);
          onClose();
        }}
      />
    </>
  );
};
