import { CollaborativePartnerOnReport } from "../../../../../../../../lib/object/value/collaborative-partner-on-report";
import { ComponentStyleProps } from "../../../../../../../../lib/styles/props/component-style-props";
import { HStack, Stack, useDisclosure } from "@chakra-ui/react";
import {
  CMFormInputRadio,
  CMFormInputText,
  CMFrameCard,
  CMSectionMultipleCardAddable,
  DeleteConfirmationModal,
} from "@pscsrvlab/psc-react-components";
import { collaborativePartnerNameMeta } from "../../../../../../../../lib/object/value/collaborative-partner-name";
import { collaborativePartnerRoleMeta } from "../../../../../../../../lib/object/value/collaborative-partner-role";
import { fullNameMeta } from "../../../../../../../../lib/object/value/full-name";
import { CellProvisionCard } from "./CellProvisionCard";
import { differentiatedCellProvisionTypeMeta } from "../../../../../../../../lib/object/value/differentiated-cell-provision-type";
import { DifferentiatedCellProvision } from "../../../../../../../../lib/object/value/differentiated-cell-provision";
import { hasValue } from "../../../../../../../../lib/util/common-util";
import { RiDeleteBin5Line } from "react-icons/ri";
import React, { memo, useCallback, useState } from "react";
import { CMButtonFormCommentProps } from "@pscsrvlab/psc-react-components/src/components/form/comment";
import { SectionContentFrame } from "../../../../../application/_components/ApplicationDocument/_components/frame/SectionContentFrame/SectionContentFrame";
import { nextGroupLocalKey } from "../../../../../../../../lib/util/app-util";
import { useAppTranslation } from "../../../../../../../../hooks/use-app-translation";
import { isEqual } from "lodash";
import log from "loglevel";

export type PartnerReportCardProps = {
  editMode: "editable" | "readOnly";

  index: number;

  value: Partial<CollaborativePartnerOnReport>;
  onChange: (
    index: number,
    change: (
      before: Partial<CollaborativePartnerOnReport>,
    ) => Partial<CollaborativePartnerOnReport>,
  ) => void;

  /**
   * コメントボタンのprops。
   * これが存在すれば、コメントボタンを表示する。
   */
  commentButtonProps?: CMButtonFormCommentProps;
  // TODO 途中で追加・削除したときのコメントの挙動を確認する。
  /**
   * 子要素のコメントボタンのprops。
   * これが存在すれば、コメントボタンを表示する。
   * valueと同じ要素数であること。
   */
  commentButtonPropsChildren?: (CMButtonFormCommentProps | undefined)[];
} & ComponentStyleProps;
export const PartnerReportCard = memo(
  function PartnerReportCard({
    editMode,

    index,

    value,
    onChange,

    commentButtonProps,
    commentButtonPropsChildren,
    sx,
    ...rest
  }: PartnerReportCardProps) {
    const { t } = useAppTranslation();

    /**
     * 追加ボタン押下時に空の提供先を追加する。
     */
    const handleAddCellProvision = useCallback(() => {
      onChange?.(index, (before) => {
        const cellProvisions = before.cellProvisions ?? [];
        const nextKey = nextGroupLocalKey(cellProvisions);
        const newCellProvisions: DifferentiatedCellProvision[] = [
          ...cellProvisions,
          {
            key: nextKey,
            provisionReason: "",
            cellInformation: "",
            cellDisposalType: "",
            recipientName: "",
          },
        ];
        return {
          ...before,
          cellProvisions: newCellProvisions,
        };
      });
    }, [index, onChange]);

    // 提供先で無が選択されると表示するモーダルの制御
    const {
      isOpen: isOpenResetModal,
      onOpen: onOpenResetModal,
      onClose: onCloseResetModal,
    } = useDisclosure();

    const handleChangeCellProvisionType = useCallback(
      (selectedValue: "provided" | "none") => {
        if (selectedValue === "none") {
          onOpenResetModal();
        }
        if (selectedValue === "provided") {
          onChange?.(index, (before) => ({
            ...before,
            cellProvisionType: selectedValue,
          }));
        }
      },
      [index, onChange, onOpenResetModal],
    );

    const handleChangeCellProvision = useCallback(
      (
        changeIndex: number,
        change: (
          before: DifferentiatedCellProvision,
        ) => DifferentiatedCellProvision,
      ) => {
        onChange?.(index, (before) => {
          const newCellProvisions: DifferentiatedCellProvision[] = (
            before.cellProvisions ?? []
          ).map((val, index) => {
            if (changeIndex === index) {
              return change(val);
            } else {
              return val;
            }
          });
          return { ...before, cellProvisions: newCellProvisions };
        });
      },
      [index, onChange],
    );

    const handleConfirmReset = useCallback(() => {
      onChange?.(index, (before) => ({
        ...before,
        cellProvisions: [],
        cellProvisionType: "none",
      }));
    }, [index, onChange]);

    // カード削除時に表示するモーダルの制御
    const {
      isOpen: isOpenDeleteCardModal,
      onOpen: onOpenDeleteCardModal,
      onClose: onCloseDeleteCardModal,
    } = useDisclosure();

    /**
     * カード削除対象のカードインデックス
     */
    const [deletingIndex, setDeletingIndex] = useState(0);

    const handleDeleteButtonClick = useCallback(
      (index: number) => {
        setDeletingIndex(index);
        onOpenDeleteCardModal();
      },
      [onOpenDeleteCardModal],
    );

    /**
     * モーダルの削除ボタンを押下すると呼ばれ、該当カードを削除する。
     */
    const handleConfirmDelete = useCallback(() => {
      onChange?.(index, (before) => {
        const newCellProvisions = (before.cellProvisions ?? []).filter(
          (v, index) => index !== deletingIndex,
        );
        return {
          ...before,
          cellProvisions: newCellProvisions,
        };
      });
      onCloseDeleteCardModal();
    }, [index, deletingIndex, onChange, onCloseDeleteCardModal]);

    return (
      <>
        <Stack direction={"column"} spacing={"20px"} sx={sx} {...rest}>
          <HStack spacing={"20px"}>
            <CMFormInputText
              label={t("lbl.機関名")}
              value={value.collaborativePartnerName}
              valueObjectMeta={collaborativePartnerNameMeta}
              editMode={"readOnly"}
              sx={{ maxW: "50%" }}
            />
            <CMFormInputText
              label={t("lbl.研究責任者名")}
              value={value.principalInvestigator?.fullName}
              valueObjectMeta={fullNameMeta}
              editMode={"readOnly"}
              sx={{ maxW: "50%" }}
            />
          </HStack>
          <CMFormInputText
            label={t("lbl.役割")}
            value={value.collaborativePartnerRole}
            valueObjectMeta={collaborativePartnerRoleMeta}
            editMode={"readOnly"}
          />
          <CMFormInputRadio<["provided", "none"]>
            label={t("lbl.分化細胞等の提供先の有無")}
            direction={"row"}
            value={value.cellProvisionType}
            valueObjectMeta={differentiatedCellProvisionTypeMeta}
            onChange={handleChangeCellProvisionType}
            editMode={editMode}
          />
          {value.cellProvisionType === "provided" && (
            <CMSectionMultipleCardAddable
              onAddSection={handleAddCellProvision}
              buttonLabel={t("btn.分化細胞の提供先を追加ボタン")}
              editMode={editMode}
            >
              <Stack direction={"column"} spacing={"10px"}>
                {value.cellProvisions?.map((val, index) => (
                  <CMFrameCard
                    key={index}
                    backgroundColor={"gray.200"}
                    title={t("lbl.提供先") + (index + 1).toString(10)}
                    deleteButtonIcon={
                      editMode === "editable" && <RiDeleteBin5Line />
                    }
                    onDelete={() => handleDeleteButtonClick(index)}
                    commentButtonProps={
                      hasValue(commentButtonPropsChildren) &&
                      index < commentButtonPropsChildren.length
                        ? commentButtonPropsChildren[index]
                        : undefined
                    }
                  >
                    <SectionContentFrame>
                      <CellProvisionCard
                        editMode={editMode}
                        index={index}
                        value={val}
                        onChange={handleChangeCellProvision}
                      />
                    </SectionContentFrame>
                  </CMFrameCard>
                ))}
              </Stack>
            </CMSectionMultipleCardAddable>
          )}
        </Stack>
        {/*カード削除時に出現するモーダル*/}
        <DeleteConfirmationModal
          isOpen={isOpenDeleteCardModal}
          title={t("lbl.確認ポップアップタイトル")}
          message={t("mes.連絡担当者削除確認メッセージ")}
          deleteButtonLabel={t("btn.削除ボタン")}
          cancelButtonLabel={t("btn.キャンセルボタン")}
          onConfirm={handleConfirmDelete}
          onCancel={onCloseDeleteCardModal}
          onClose={onCloseDeleteCardModal}
        />
        {/*提供先で無が選択されると出現するモーダル*/}
        <DeleteConfirmationModal
          isOpen={isOpenResetModal}
          title={t("lbl.確認ポップアップタイトル")}
          message={t("mes.分化細胞の提供先リセット確認メッセージ")}
          deleteButtonLabel={t("btn.削除ボタン")}
          cancelButtonLabel={t("btn.キャンセルボタン")}
          onConfirm={handleConfirmReset}
          onCancel={onCloseResetModal}
          onClose={onCloseResetModal}
        />
      </>
    );
  },
  (prevProps, nextProps) => {
    const _isEqual = isEqual(prevProps, nextProps);
    log.debug(`PartnerReportCard: _isEqual=${_isEqual}`);
    return _isEqual;
  },
);
