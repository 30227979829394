import { ComponentStyleProps } from "../../../../../../../../../lib/styles/props/component-style-props";
import { planSummaryMeta } from "../../../../../../../../../lib/object/value/plan-summary";
import { CMFormInputTextArea } from "@pscsrvlab/psc-react-components";
import React, { memo } from "react";
import { CMButtonFormCommentProps } from "@pscsrvlab/psc-react-components/src/components/form/comment";
import { useSectionDiffMode } from "../../../../../../../../../hooks/document/change-application/use-section-diff-mode";
import { useChangeBadgeProperties } from "../../../../../../../../../hooks/document/change-application/use-change-badge-properties";
import { ChangeReasonForm } from "../../form/ChangeReasonForm/ChangeReasonForm";
import { FormSection } from "../../../../../../../../ui/form/FormSection/FormSection";
import { useAppTranslation } from "../../../../../../../../../hooks/use-app-translation";
import { hasValue } from "../../../../../../../../../lib/util/common-util";
import { ChangeReasonItemViewModel } from "../../../../../../../../../lib/object/vm/change-application-view-model";

export type PlanSummarySectionProps = {
  documentType:
    | "new_application"
    | "change_application"
    | "project_force_update"
    | "project_force_create"
    | "project_content";
  editMode: "editable" | "readOnly";

  value: string;
  onChange?: (value: string) => void;

  changedFrom?: string;
  changeReason?: string;
  onChangeReason?: (
    change: (before: ChangeReasonItemViewModel) => ChangeReasonItemViewModel,
  ) => void;
  displayChangesOnly?: boolean;

  /**
   * コメントボタンのprops。
   * これが存在すれば、コメントボタンを表示する。
   */
  commentButtonProps?: CMButtonFormCommentProps;
} & ComponentStyleProps;

/**
 * 計画の概要セクション
 */
export const PlanSummarySection = memo(function PlanSummaryFormSection({
  documentType,
  editMode,

  value,
  onChange,

  changedFrom,
  changeReason,
  onChangeReason,
  displayChangesOnly,

  commentButtonProps,

  sx,
  ...rest
}: PlanSummarySectionProps) {
  const { t } = useAppTranslation();

  const sectionDiffMode = useSectionDiffMode(
    documentType,
    changedFrom,
    value,
    onChangeReason,
  );
  const changeBadgeProperties = useChangeBadgeProperties(
    documentType,
    changedFrom,
  );

  return (
    <FormSection
      title={t("lbl.計画の概要")}
      description={
        editMode === "editable" ? t("gdc.計画の概要補足") : undefined
      }
      commentButtonProps={commentButtonProps}
      displayMode={sectionDiffMode}
      displayChangesOnly={displayChangesOnly}
      sx={sx}
      {...rest}
    >
      <CMFormInputTextArea
        editMode={editMode}
        valueObjectMeta={planSummaryMeta}
        value={value}
        noHeader={!hasValue(changedFrom)}
        resize={editMode === "editable" ? "vertical" : "none"}
        onChange={onChange}
        changeBadgeProperties={changeBadgeProperties}
      />
      {sectionDiffMode === "updated" && (
        <ChangeReasonForm
          value={changeReason}
          onChange={onChangeReason}
          editMode={editMode}
        />
      )}
    </FormSection>
  );
});
