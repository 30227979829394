import React, { memo, useCallback, useEffect, useMemo } from "react";
import { PartnerContent } from "../../content/PartnerContent/PartnerContent";
import { Text, VStack } from "@chakra-ui/react";
import { AttachmentFilesContent } from "../../content/AttachmentFilesContent/AttachmentFilesContent";
import { PartnerInfo } from "../../section/PartnersSection/PartnersSection";
import { CMButtonFormCommentProps } from "@pscsrvlab/psc-react-components/src/components/form/comment";
import { CollaborativePartner } from "../../../../../../../../../lib/object/value/collaborative-partner";
import { useSectionDiffMode } from "../../../../../../../../../hooks/document/change-application/use-section-diff-mode";
import { IpsCellStockUsePurposeType } from "../../../../../../../../../lib/object/value/ips-cell-stock-use-purpose-type";
import { ChangeReasonForm } from "../../form/ChangeReasonForm/ChangeReasonForm";
import { FormCard } from "../../../../../../../../ui/form/FormCard/FormCard";
import { AttachmentFilesSectionViewModel } from "../../../../../../../../../lib/object/vm/attachment-files-section-view-model";
import { useAppTranslation } from "../../../../../../../../../hooks/use-app-translation";
import { ChangeReasonItemViewModel } from "../../../../../../../../../lib/object/vm/change-application-view-model";
import log from "loglevel";

export type PartnerCardProps = {
  _key: number;

  documentType:
    | "new_application"
    | "change_application"
    | "project_force_update"
    | "project_force_create"
    | "project_content";
  editMode: "editable" | "readOnly";

  institutionId: number | null;
  projectId: number | null;

  mode: "none" | "added" | "deleted";

  /**
   * iPS細胞の使用目的。
   * この値により、添付資料の表示が変わる。
   */
  purposeType?: IpsCellStockUsePurposeType;

  index: number;
  value: CollaborativePartner;

  onChangePartnerInfo: (
    key: number,
    change: (before: PartnerInfo) => PartnerInfo,
  ) => void;
  onChangeAttachmentFiles: (
    key: number,
    change: (
      before: AttachmentFilesSectionViewModel,
    ) => AttachmentFilesSectionViewModel,
  ) => void;
  onDelete: (key: number) => void;

  changedFrom?: CollaborativePartner;
  changeReason?: string;
  // onChangeReason?: (
  //   change: (before: ChangeReasonItemViewModel) => ChangeReasonItemViewModel,
  // ) => void;
  onChangeReason?: (
    key: number,
    change: (before: ChangeReasonItemViewModel) => ChangeReasonItemViewModel,
  ) => void;

  commentButtonProps?: {
    root: CMButtonFormCommentProps | undefined;
    collaborativePartnerName: CMButtonFormCommentProps | undefined;
    principalInvestigator: CMButtonFormCommentProps | undefined;
    contactPerson: CMButtonFormCommentProps | undefined;
    attachmentFiles: {
      research_proposal: CMButtonFormCommentProps | undefined;
      certificate_of_ethics_committee_approval:
        | CMButtonFormCommentProps
        | undefined;
      management_system: CMButtonFormCommentProps | undefined;
      principal_investigator_resume: CMButtonFormCommentProps | undefined;
      organization_overview: CMButtonFormCommentProps | undefined;
      research_achievement: CMButtonFormCommentProps | undefined;
      facility_gmp_compliance: CMButtonFormCommentProps | undefined;
      other: CMButtonFormCommentProps | undefined;
    };
  };
};

export const PartnerCard = memo(function PartnerCard({
  _key,

  documentType,
  editMode,

  institutionId,
  projectId,

  mode,

  purposeType,

  index,
  value,

  onChangePartnerInfo,
  onChangeAttachmentFiles,
  onDelete,

  changedFrom,
  changeReason,
  onChangeReason,

  commentButtonProps,
}: PartnerCardProps) {
  const { t } = useAppTranslation();

  const cardColor = useMemo(() => {
    const colorList = ["pink.50", "orange.50"];
    const colorIndex = index % colorList.length;
    return colorList[colorIndex];
  }, [index]);
  const cardTitle = useMemo(() => {
    return t("lbl.分担機関") + (index + 1).toString(10);
  }, [t, index]);
  const _editMode: "readOnly" | "editable" = useMemo(() => {
    if (mode === "deleted") return "readOnly";
    return editMode;
  }, [editMode, mode]);

  const handleChangePartnerInfo = useCallback(
    (change: (before: PartnerInfo) => PartnerInfo) => {
      onChangePartnerInfo(value.key, change);
    },
    [onChangePartnerInfo, value.key],
  );

  const handleChangeAttachmentFiles = useCallback(
    (
      change: (
        before: AttachmentFilesSectionViewModel,
      ) => AttachmentFilesSectionViewModel,
    ) => onChangeAttachmentFiles(value.key, change),
    [onChangeAttachmentFiles, value.key],
  );
  const handleDelete = useCallback(
    () => onDelete(value.key),
    [onDelete, value.key],
  );

  const handleChangeReason = useCallback(
    (
      change: (before: ChangeReasonItemViewModel) => ChangeReasonItemViewModel,
    ) => {
      onChangeReason?.(_key, change);
    },
    [_key, onChangeReason],
  );

  const sectionDiffModeOverwrite = useMemo(() => {
    return mode === "none" ? undefined : mode;
  }, [mode]);
  const sectionDiffMode = useSectionDiffMode(
    documentType,
    changedFrom,
    value,
    handleChangeReason,
    sectionDiffModeOverwrite,
  );

  useEffect(() => {
    log.debug(
      `_key=${_key}, mode=${mode}, commentButtonProps=`,
      commentButtonProps,
    );
  }, [_key, commentButtonProps, mode]);

  return (
    <FormCard
      editMode={_editMode}
      backgroundColor={cardColor}
      title={cardTitle}
      displayMode={sectionDiffMode}
      onDelete={handleDelete}
      spacing={"20px"}
      commentButtonProps={commentButtonProps?.root}
      className={"PartnerCard"}
    >
      <PartnerContent
        documentType={documentType}
        editMode={_editMode}
        onChange={handleChangePartnerInfo}
        value={value}
        changedFrom={changedFrom}
        commentButtonProps={commentButtonProps}
      />
      <VStack alignItems={"stretch"}>
        <Text fontWeight={"bold"}>{t("lbl.添付資料")}</Text>
        <AttachmentFilesContent
          documentType={documentType}
          editMode={_editMode}
          institutionId={institutionId}
          projectId={projectId}
          sectionType={"partners"}
          purposeType={purposeType}
          valueResearchPlanProjectName={value.researchPlanProjectName}
          valueAttachmentFiles={value.attachmentFiles}
          onChange={handleChangeAttachmentFiles}
          commentButtonProps={commentButtonProps?.attachmentFiles}
          changedFromResearchPlanProjectName={
            changedFrom?.researchPlanProjectName
          }
          changedFromAttachmentFiles={changedFrom?.attachmentFiles}
        />
      </VStack>
      {(sectionDiffMode === "updated" ||
        sectionDiffMode === "added" ||
        sectionDiffMode === "deleted") && (
        <ChangeReasonForm
          value={changeReason}
          onChange={handleChangeReason}
          editMode={editMode}
        />
      )}
    </FormCard>
  );
});
