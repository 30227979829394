import { ComponentStyleProps } from "../../../../../../../lib/styles/props/component-style-props";
import {
  Center,
  Flex,
  HStack,
  Link,
  useDisclosure,
  Text,
} from "@chakra-ui/react";
import { SharedAttachmentFileSaved } from "../../../../../../../lib/object/entity/shared-attachment-file";
import useFileDownload from "../../../../../../../hooks/use-file-download";
import React, { useCallback, useMemo } from "react";
import { useDeleteSharedAttachmentFileMutation } from "../../../../../../../store/api/generated/stock-request-api";
import {
  CMDatetimeText,
  DeleteConfirmationModal,
  DeleteIconButton,
} from "@pscsrvlab/psc-react-components";
import { useAppTranslation } from "../../../../../../../hooks/use-app-translation";
import { useAppSelector } from "../../../../../../../hooks/redux-hooks";
import { selectHasRole } from "../../../../../../../store/auth/slice";

export type SharedAttachmentFilesTableRowProps = {
  file: SharedAttachmentFileSaved;
  styleType: 0 | 1;
} & ComponentStyleProps;

export const SharedAttachmentFilesTableRow = ({
  file,
  styleType,

  sx,
  ...rest
}: SharedAttachmentFilesTableRowProps) => {
  const { t } = useAppTranslation();
  const { fileDownload } = useFileDownload();
  const { isOfficeMember } = useAppSelector(selectHasRole);

  const [deleteSharedAttachmentFile] = useDeleteSharedAttachmentFileMutation();

  const bgColor = useMemo(
    () => (styleType === 0 ? "white" : "blue.50"),
    [styleType],
  );

  // モーダル制御
  const {
    isOpen: isOpenDeleteModal,
    onOpen: onOpenDeleteModal,
    onClose: onCloseDeleteModal,
  } = useDisclosure();

  const handleClickFileName = useCallback(() => {
    fileDownload(
      file.attachmentFileInformation.storageFileId,
      file.attachmentFileInformation.attachmentFileName,
    );
  }, [
    file.attachmentFileInformation.attachmentFileName,
    file.attachmentFileInformation.storageFileId,
    fileDownload,
  ]);

  const handleClickDelete = useCallback(() => {
    onOpenDeleteModal();
  }, [onOpenDeleteModal]);
  const handleConfirmDelete = useCallback(() => {
    deleteSharedAttachmentFile({
      sharedAttachmentFileId: file.id,
    });
  }, [deleteSharedAttachmentFile, file.id]);

  return (
    <>
      <Flex
        direction={"row"}
        justifyContent={"flex-start"}
        h={"38px"}
        bgColor={bgColor}
        sx={sx}
        {...rest}
      >
        <Center flex={"0 0 150px"}>
          <CMDatetimeText
            sx={{
              color: "gray.500",
              fontSize: "sm",
            }}
            value={file.created.datetime}
          />
        </Center>
        <HStack flex={1} justifyContent={"flex-start"} maxW={"470px"}>
          <Link
            onClick={handleClickFileName}
            color={"blue.600"}
            overflow={"hidden"}
          >
            <Text
              overflowWrap={"break-word"}
              color={"blue.600"}
              overflow={"hidden"}
              whiteSpace={"nowrap"}
              textOverflow={"ellipsis"}
            >
              {file.attachmentFileInformation.attachmentFileName}
            </Text>
          </Link>
        </HStack>
        {isOfficeMember && (
          <Center ml={"auto"} mr={"4px"}>
            <DeleteIconButton
              ariaLabel={"delete file"}
              onClick={handleClickDelete}
            />
          </Center>
        )}
      </Flex>
      <DeleteConfirmationModal
        isOpen={isOpenDeleteModal}
        title={t("lbl.確認ポップアップタイトル")}
        message={t("mes.添付資料削除確認メッセージ")}
        deleteButtonLabel={t("btn.削除ボタン")}
        cancelButtonLabel={t("btn.キャンセルボタン")}
        onConfirm={handleConfirmDelete}
        onCancel={onCloseDeleteModal}
        onClose={onCloseDeleteModal}
      />
    </>
  );
};
