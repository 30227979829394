import { ComponentStyleProps } from "../../../../../../../../../lib/styles/props/component-style-props";
import {
  CMFormInputRadio,
  CMFormInputYearMonth,
} from "@pscsrvlab/psc-react-components";
import { HStack, Text } from "@chakra-ui/react";
import { collaborativeResearchContractStateWithFoundationTypeMeta } from "../../../../../../../../../lib/object/value/collaborative-research-contract-state-with-foundation-type";
import React, { memo, useEffect, useMemo } from "react";
import { CMButtonFormCommentProps } from "@pscsrvlab/psc-react-components/src/components/form/comment";
import { CollaborativeResearchContractStateWithFoundation } from "../../../../../../../../../lib/object/value/collaborative-research-contract-state-with-foundation";
import { useSectionDiffMode } from "../../../../../../../../../hooks/document/change-application/use-section-diff-mode";
import { useChangeBadgeProperties } from "../../../../../../../../../hooks/document/change-application/use-change-badge-properties";
import { ChangeReasonForm } from "../../form/ChangeReasonForm/ChangeReasonForm";
import { FormSection } from "../../../../../../../../ui/form/FormSection/FormSection";
import { useAppTranslation } from "../../../../../../../../../hooks/use-app-translation";
import {
  hasValue,
  isNullish,
} from "../../../../../../../../../lib/util/common-util";
import { ymToYearMonthText } from "../../../../../../../../../lib/util/common-date-util";
import { ChangeReasonItemViewModel } from "../../../../../../../../../lib/object/vm/change-application-view-model";
import log from "loglevel";

export type FoundationContractStateSectionProps = {
  documentType:
    | "new_application"
    | "change_application"
    | "project_force_update"
    | "project_force_create"
    | "project_content";
  editMode: "editable" | "readOnly";

  value: CollaborativeResearchContractStateWithFoundation;
  onChange: (value: CollaborativeResearchContractStateWithFoundation) => void;

  changedFrom?: CollaborativeResearchContractStateWithFoundation;
  changeReason?: string;
  onChangeReason?: (
    change: (before: ChangeReasonItemViewModel) => ChangeReasonItemViewModel,
  ) => void;
  displayChangesOnly?: boolean;

  /**
   * コメントボタンのprops。
   * これが存在すれば、コメントボタンを表示する。
   */
  commentButtonProps?: CMButtonFormCommentProps;
} & ComponentStyleProps;

export const FoundationContractStateSection = memo(
  function FoundationContractStateSection({
    documentType,
    editMode,

    value,
    onChange,

    changedFrom,
    changeReason,
    onChangeReason,
    displayChangesOnly,

    commentButtonProps,

    sx,
    ...rest
  }: FoundationContractStateSectionProps) {
    const { t } = useAppTranslation();

    const handleChangeStatus = (v: "not_yet" | "discussing" | "agreed") => {
      onChange({
        state: v,
        estimatedAgreementYearMonth:
          v === "discussing" ? value.estimatedAgreementYearMonth : undefined,
      });
    };
    const handleChangeYearMonth = (v: { year: number; month: number }) => {
      onChange({
        state: value.state,
        estimatedAgreementYearMonth: v,
      });
    };

    const sectionDiffMode = useSectionDiffMode(
      documentType,
      changedFrom,
      value,
      onChangeReason,
    );

    const changeBadgePropertiesState = useChangeBadgeProperties(
      documentType,
      changedFrom?.state,
    );
    const changeBadgePropertiesEstimatedAgreementYearMonth =
      useChangeBadgeProperties(
        documentType,
        changedFrom?.estimatedAgreementYearMonth,
      );

    const readOnlyValue: string = useMemo(() => {
      if (editMode !== "readOnly" || isNullish(value)) return "";

      switch (value.state) {
        case "agreed":
          return t("code.財団との共同研究契約状況区分.締結済" + "");
        case "not_yet":
          return t("code.財団との共同研究契約状況区分.締結前");
        case "discussing":
          if (
            isNullish(value.estimatedAgreementYearMonth) ||
            isNullish(value.estimatedAgreementYearMonth.year) ||
            isNullish(value.estimatedAgreementYearMonth.month)
          )
            return "";
          return (
            t("code.財団との共同研究契約状況区分.協議中") +
            " (" +
            ymToYearMonthText(
              value.estimatedAgreementYearMonth.year,
              value.estimatedAgreementYearMonth.month,
            ) +
            t("lbl.締結見込み") +
            ")"
          );
      }
    }, [editMode, value, t]);

    useEffect(() => {
      log.debug("readOnlyValue=", readOnlyValue);
    }, [readOnlyValue]);

    useEffect(() => {
      log.debug("value=", value);
    }, [value]);

    return (
      <FormSection
        title={t("lbl.本申請に基づくiPS財団との共同研究契約の状況")}
        displayMode={sectionDiffMode}
        displayChangesOnly={displayChangesOnly}
        commentButtonProps={commentButtonProps}
        sx={sx}
        {...rest}
      >
        {editMode === "readOnly" ? (
          <Text>{readOnlyValue}</Text>
        ) : (
          <CMFormInputRadio
            value={value.state}
            valueObjectMeta={
              collaborativeResearchContractStateWithFoundationTypeMeta
            }
            editMode={editMode}
            noHeader={!hasValue(changedFrom?.state)}
            onChange={handleChangeStatus}
            changeBadgeProperties={changeBadgePropertiesState}
            insertedNodeOnSelected={
              <HStack>
                <CMFormInputYearMonth
                  size={"md"}
                  value={value.estimatedAgreementYearMonth}
                  noHeader={!hasValue(changedFrom?.estimatedAgreementYearMonth)}
                  editMode={editMode}
                  onChange={handleChangeYearMonth}
                  changeBadgeProperties={
                    changeBadgePropertiesEstimatedAgreementYearMonth
                  }
                  ml={"30px"}
                  w={"220px"}
                />
                <Text whiteSpace={"nowrap"}>{t("lbl.締結見込み")}</Text>
              </HStack>
            }
            insertValue={"discussing"}
          />
        )}

        {sectionDiffMode === "updated" && (
          <ChangeReasonForm
            value={changeReason}
            onChange={onChangeReason}
            editMode={editMode}
          />
        )}
      </FormSection>
    );
  },
);
