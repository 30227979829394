import { memo, ReactNode, useMemo } from "react";
import { ComponentStyleProps } from "../../../../lib/styles/props/component-style-props";
import { SectionContentFrame } from "../../../model/document/application/_components/ApplicationDocument/_components/frame/SectionContentFrame/SectionContentFrame";
import { CMFrameSection } from "@pscsrvlab/psc-react-components";
import { CMButtonFormCommentProps } from "@pscsrvlab/psc-react-components/src/components/form/comment";
import { useAppTranslation } from "../../../../hooks/use-app-translation";

export type FormSectionProps = {
  title?: string;
  description?: string;

  /**
   * 表示モード
   */
  displayMode?: "normal" | "added" | "updated" | "deleted";
  displayChangesOnly?: boolean;

  /**
   * コメントボタンのprops。
   * これが存在すれば、コメントボタンを表示する。
   */
  commentButtonProps?: CMButtonFormCommentProps;
  /**
   * 折り畳みボタンを表示するための折り畳み状態。 これが存在すれば、折り畳みボタンを表示する。
   */
  foldState?: "close" | "open";
  /**
   * 折り畳みボタンクリック時に呼ばれる。
   */
  onClickFoldButton?: (value: "close" | "open") => void;

  children?: ReactNode;
} & ComponentStyleProps;
export const FormSection = memo(function FormSection({
  title,
  description,

  displayMode = "normal",
  displayChangesOnly = false,

  commentButtonProps,

  foldState,
  onClickFoldButton,

  children,

  sx,
  ...rest
}: FormSectionProps) {
  const { t } = useAppTranslation();

  const displayModeMessage = useMemo(() => {
    return {
      added: t("lbl.追加"),
      deleted: t("lbl.削除"),
    };
  }, [t]);

  const capitalizedTitle = title;

  return (
    <>
      {(!displayChangesOnly || displayMode !== "normal") && (
        <CMFrameSection
          title={capitalizedTitle}
          description={description}
          displayMode={displayMode}
          displayModeMessage={displayModeMessage}
          commentButtonProps={commentButtonProps}
          foldButtonProps={foldState}
          onClickFoldButton={onClickFoldButton}
          sx={sx}
          {...rest}
        >
          <SectionContentFrame>{children}</SectionContentFrame>
        </CMFrameSection>
      )}
    </>
  );
});
