import React, { memo, Ref } from "react";
import { ComponentStyleProps } from "../../../../../../lib/styles/props/component-style-props";
import { useAppSelector } from "../../../../../../hooks/redux-hooks";
import { selectUserInfo } from "../../../../../../store/auth/slice";
import { hasValue } from "../../../../../../lib/util/common-util";
import { RevisionRequestItemViewModel } from "../../../../../../lib/object/vm/revision-request-view-model";
import { ChangeApplicationDocument } from "../ChangeApplicationDocument/ChangeApplicationDocument";
import { ChangeApplicationViewModel } from "../../../../../../lib/object/vm/change-application-view-model";
import { PositionProps } from "@chakra-ui/react";

export type ChangeApplicationDocumentReadOnlyProps = {
  vm: ChangeApplicationViewModel;

  /**
   * 修正依頼。
   */
  revisionRequestItems?: RevisionRequestItemViewModel[];
  /**
   * 修正依頼コメントを選択したときのコールバック。
   * 未選択状態になる場合はnullを送出する。
   */
  onSelectComment?: (path: string | null) => void;
  revisionMode?: "none" | "readOnly";

  /**
   * stickyなエリアのtopの値。
   */
  stickyAreaTop?: PositionProps["top"];

  scrollableRef: Ref<any>;
  scrollOffset: number;
} & ComponentStyleProps;
export const ChangeApplicationDocumentReadOnly = memo(
  function ChangeApplicationDocumentReadOnly({
    vm,

    revisionRequestItems,
    onSelectComment,
    revisionMode,

    stickyAreaTop,

    scrollableRef,
    scrollOffset,

    sx,
    ...rest
  }: ChangeApplicationDocumentReadOnlyProps) {
    const loginUserInfo = useAppSelector(selectUserInfo);

    return (
      <>
        {hasValue(loginUserInfo) && (
          <ChangeApplicationDocument
            loginUserInfo={loginUserInfo}
            editMode={"readOnly"}
            value={vm}
            revisionRequestItems={revisionRequestItems}
            onSelectComment={onSelectComment}
            revisionMode={revisionMode}
            stickyAreaTop={stickyAreaTop}
            scrollableRef={scrollableRef}
            scrollOffset={scrollOffset}
            sx={sx}
            {...rest}
          />
        )}
      </>
    );
  },
);
