import { ComponentStyleProps } from "../../../../../../../../../lib/styles/props/component-style-props";
import { useDisclosure, VStack } from "@chakra-ui/react";
import {
  CMSectionMultipleCardAddable,
  DeleteConfirmationModal,
} from "@pscsrvlab/psc-react-components";
import { memo, useCallback, useMemo, useState } from "react";
import { CMButtonFormCommentProps } from "@pscsrvlab/psc-react-components/src/components/form/comment";
import { CollaborativePartner } from "../../../../../../../../../lib/object/value/collaborative-partner";
import { CollaborativePartnerUserInformation } from "../../../../../../../../../lib/object/value/collaborative-partner-user-information";
import { ForeignType } from "../../../../../../../../../lib/object/value/foreign-type";
import { CommercialType } from "../../../../../../../../../lib/object/value/commercial-type";
import { nextGroupLocalKey } from "../../../../../../../../../lib/util/app-util";
import { PartnerCard } from "../../card/PartnerCard/PartnerCard";
import { useSectionDiffMode } from "../../../../../../../../../hooks/document/change-application/use-section-diff-mode";
import { useKeyedData } from "../../../../../../../../../hooks/use-keyed-data";
import { FormSection } from "../../../../../../../../ui/form/FormSection/FormSection";
import { FoldedPartnerCard } from "../../card/PartnerCard/FoldedPartnerCard";
import { AttachmentFilesSectionViewModel } from "../../../../../../../../../lib/object/vm/attachment-files-section-view-model";
import { useAppTranslation } from "../../../../../../../../../hooks/use-app-translation";
import { ChangeReasonItemViewModel } from "../../../../../../../../../lib/object/vm/change-application-view-model";

export type PartnerInfo = {
  key: number;
  collaborativePartnerName: string;
  foreignType: ForeignType;
  commercialType: CommercialType;
  collaborativePartnerRole: string;
  principalInvestigator: CollaborativePartnerUserInformation;
  contactPerson: CollaborativePartnerUserInformation;
};

export type PartnersSectionProps = {
  documentType:
    | "new_application"
    | "change_application"
    | "project_force_update"
    | "project_force_create"
    | "project_content";
  editMode: "editable" | "readOnly";

  institutionId: number | null;
  projectId: number | null;

  /**
   * 分担機関の有無
   */
  hasCollaborativePartner: boolean;
  /**
   * iPS細胞の使用目的
   */
  purposeType?: "clinical" | "research";

  value: CollaborativePartner[];
  onChange?: (
    change: (before: CollaborativePartner[]) => CollaborativePartner[],
  ) => void;

  changedFrom?: CollaborativePartner[];
  changeReason?: { key: number; value: ChangeReasonItemViewModel }[];
  onChangeReason?: (
    change: (
      before: { key: number; value: ChangeReasonItemViewModel }[],
    ) => { key: number; value: ChangeReasonItemViewModel }[],
  ) => void;
  displayChangesOnly?: boolean;

  /**
   * コメントボタンのprops。
   * これが存在すれば、コメントボタンを表示する。
   */
  commentButtonProps?: CMButtonFormCommentProps;
  /**
   * 子要素のコメントボタンのprops。
   * これが存在すれば、コメントボタンを表示する。
   */
  commentButtonPropsChildren?: {
    key: number;
    value: {
      root: CMButtonFormCommentProps | undefined;
      collaborativePartnerName: CMButtonFormCommentProps | undefined;
      principalInvestigator: CMButtonFormCommentProps | undefined;
      contactPerson: CMButtonFormCommentProps | undefined;
      attachmentFiles: {
        research_proposal: CMButtonFormCommentProps | undefined;
        certificate_of_ethics_committee_approval:
          | CMButtonFormCommentProps
          | undefined;
        management_system: CMButtonFormCommentProps | undefined;
        principal_investigator_resume: CMButtonFormCommentProps | undefined;
        organization_overview: CMButtonFormCommentProps | undefined;
        research_achievement: CMButtonFormCommentProps | undefined;
        facility_gmp_compliance: CMButtonFormCommentProps | undefined;
        other: CMButtonFormCommentProps | undefined;
      };
    };
  }[];
} & ComponentStyleProps;

export const PartnersSection = memo(function PartnersSection({
  documentType,
  editMode,

  institutionId,
  projectId,

  hasCollaborativePartner,
  purposeType,

  value,
  onChange,

  changedFrom,
  changeReason,
  onChangeReason,
  displayChangesOnly,

  commentButtonProps,
  commentButtonPropsChildren,

  sx,
  ...rest
}: PartnersSectionProps) {
  const { t } = useAppTranslation();

  const { keyedValues: allValuesIncludingDeleted } = useKeyedData(
    value,
    changedFrom,
  );

  const handleAddPartner = useCallback(() => {
    onChange?.((before) => {
      const nextKey = nextGroupLocalKey(
        allValuesIncludingDeleted.map((v) => v.value),
      );
      return [...before, getEmptyPartner(nextKey)];
    });
  }, [onChange, allValuesIncludingDeleted]);

  const handleChangePartnerInfo = useCallback(
    (key: number, change: (before: PartnerInfo) => PartnerInfo) => {
      onChange?.((before) => {
        return before.map((partner) => {
          if (key === partner.key) {
            return {
              ...partner,
              ...change(partner),
            };
          }
          return partner;
        });
      });
    },
    [onChange],
  );

  const handleChangeAttachmentFiles = useCallback(
    (
      key: number,
      change: (
        before: AttachmentFilesSectionViewModel,
      ) => AttachmentFilesSectionViewModel,
    ) => {
      onChange?.((before) => {
        return before.map((partner) => {
          if (partner.key === key) {
            const updated = change({
              researchPlanProjectName: partner.researchPlanProjectName,
              attachmentFiles: partner.attachmentFiles,
            });
            return {
              ...partner,
              ...updated,
            };
          } else {
            return partner;
          }
        });
      });
    },
    [onChange],
  );

  // モーダル制御
  const {
    isOpen: isOpenDeleteModal,
    onOpen: onOpenDeleteModal,
    onClose: onCloseDeleteModal,
  } = useDisclosure();

  /**
   * カード削除対象のカードインデックス
   */
  const [deletingKey, setDeletingKey] = useState(0);

  /**
   * カード削除ボタンが押下されると呼ばれ、モーダルを出現させる。
   */
  const handleDeleteButtonClick = useCallback(
    (key: number) => {
      setDeletingKey(key);
      onOpenDeleteModal();
    },
    [onOpenDeleteModal],
  );

  /**
   * モーダルの削除ボタンを押下すると呼ばれ、該当カードを削除する。
   */
  const handleConfirmDelete = useCallback(() => {
    onCloseDeleteModal();
    onChange?.((before) => {
      return before.filter((v) => v.key !== deletingKey);
    });
    onChangeReason?.((before) => {
      return before.filter((v) => v.key !== deletingKey);
    });
  }, [onCloseDeleteModal, onChange, onChangeReason, deletingKey]);

  const sectionDiffMode = useSectionDiffMode(
    documentType,
    changedFrom,
    value,
    undefined,
  );

  const handleChangeReason = useCallback(
    (
      key: number,
      change: (before: ChangeReasonItemViewModel) => ChangeReasonItemViewModel,
    ) => {
      onChangeReason?.((before) => {
        const beforeItem = before.find((v) => v.key === key)?.value ?? {
          reason: "",
          changed: false,
        };
        return [
          ...before.filter((v) => v.key !== key),
          { key, value: change(beforeItem) },
        ];
      });
    },
    [onChangeReason],
  );

  const [foldStateInternal, setFoldStateInternal] = useState<"open" | "close">(
    "open",
  );
  const handleClickFoldButton = useCallback((v: "open" | "close") => {
    setFoldStateInternal(v);
  }, []);
  const foldState = useMemo(
    () => (editMode === "readOnly" ? foldStateInternal : undefined),
    [editMode, foldStateInternal],
  );

  const showPartnersSection = useMemo(() => {
    return hasCollaborativePartner || documentType === "change_application";
  }, [documentType, hasCollaborativePartner]);
  const addButtonEditMode = useMemo(() => {
    if (editMode === "readOnly") return "readOnly";
    return hasCollaborativePartner ? "editable" : "disabled";
  }, [editMode, hasCollaborativePartner]);

  const formSectionDescription = useMemo(
    () => (editMode === "editable" ? t("gdc.分担機関補足") : undefined),
    [editMode, t],
  );

  return (
    <>
      {showPartnersSection && foldStateInternal === "open" && (
        <FormSection
          title={t("lbl.分担機関")}
          description={formSectionDescription}
          displayMode={sectionDiffMode}
          displayChangesOnly={displayChangesOnly}
          commentButtonProps={commentButtonProps}
          foldState={foldState}
          onClickFoldButton={handleClickFoldButton}
          sx={sx}
          {...rest}
          className={"PartnersSection"}
        >
          <CMSectionMultipleCardAddable
            editMode={addButtonEditMode}
            onAddSection={handleAddPartner}
            buttonLabel={t("btn.分担機関を追加ボタン")}
          >
            <VStack
              spacing={"10px"}
              alignItems={"stretch"}
              className={"VStack"}
            >
              {allValuesIncludingDeleted.map(({ mode, value: v }, index) => (
                <PartnerCard
                  key={v.key}
                  _key={v.key}
                  documentType={documentType}
                  editMode={editMode}
                  institutionId={institutionId}
                  projectId={projectId}
                  mode={mode}
                  purposeType={purposeType}
                  index={index}
                  value={v}
                  onChangePartnerInfo={handleChangePartnerInfo}
                  onChangeAttachmentFiles={handleChangeAttachmentFiles}
                  onDelete={handleDeleteButtonClick}
                  changedFrom={changedFrom?.find((cf) => cf.key === v.key)}
                  changeReason={
                    changeReason?.find((cr) => cr.key === v.key)?.value?.reason
                  }
                  onChangeReason={handleChangeReason}
                  commentButtonProps={
                    commentButtonPropsChildren?.find((p) => p.key === v.key)
                      ?.value
                  }
                />
              ))}
            </VStack>
          </CMSectionMultipleCardAddable>
        </FormSection>
      )}
      {showPartnersSection && foldStateInternal === "close" && (
        <FormSection
          title={t("lbl.分担機関")}
          displayMode={sectionDiffMode}
          displayChangesOnly={displayChangesOnly}
          commentButtonProps={commentButtonProps}
          foldState={editMode === "readOnly" ? foldStateInternal : undefined}
          onClickFoldButton={handleClickFoldButton}
          sx={sx}
          {...rest}
        >
          <CMSectionMultipleCardAddable
            editMode={addButtonEditMode}
            onAddSection={handleAddPartner}
            buttonLabel={t("btn.分担機関を追加ボタン")}
          >
            <VStack spacing={"10px"} alignItems={"stretch"}>
              {allValuesIncludingDeleted.map(({ mode, value: v }, index) => (
                <FoldedPartnerCard
                  key={v.key}
                  documentType={documentType}
                  editMode={editMode}
                  mode={mode}
                  purposeType={purposeType}
                  index={index}
                  value={v}
                  changedFrom={changedFrom?.find((cf) => cf.key === v.key)}
                  commentButtonProps={
                    commentButtonPropsChildren?.find((p) => p.key === v.key)
                      ?.value.root
                  }
                />
              ))}
            </VStack>
          </CMSectionMultipleCardAddable>
        </FormSection>
      )}
      {/*カード削除時に出現するモーダル*/}
      <DeleteConfirmationModal
        isOpen={isOpenDeleteModal}
        onClose={onCloseDeleteModal}
        title={t("lbl.確認ポップアップタイトル")}
        message={t("mes.分担機関削除確認メッセージ")}
        deleteButtonLabel={t("btn.削除ボタン")}
        cancelButtonLabel={t("btn.キャンセルボタン")}
        onConfirm={handleConfirmDelete}
        onCancel={onCloseDeleteModal}
      />
    </>
  );
});

function getEmptyPartner(nextKey: number): CollaborativePartner {
  return {
    key: nextKey,
    collaborativePartnerName: "",
    foreignType: "domestic",
    commercialType: "commercial",
    principalInvestigator: {
      fullName: "",
      titleAndPosition: "",
      phoneNumber: "",
      mailAddress: "",
    },
    contactPerson: {
      fullName: "",
      titleAndPosition: "",
      phoneNumber: "",
      mailAddress: "",
    },
    collaborativePartnerRole: "",
    researchPlanProjectName: "",
    attachmentFiles: [],
  };
}
