import { ComponentStyleProps } from "../../../../../../../../../lib/styles/props/component-style-props";
import { projectNameMeta } from "../../../../../../../../../lib/object/value/project-name";
import { CMFormInputText } from "@pscsrvlab/psc-react-components";
import React, { memo } from "react";
import { ChangeReasonForm } from "../../form/ChangeReasonForm/ChangeReasonForm";
import { CMButtonFormCommentProps } from "@pscsrvlab/psc-react-components/src/components/form/comment";
import { useSectionDiffMode } from "../../../../../../../../../hooks/document/change-application/use-section-diff-mode";
import { useChangeBadgeProperties } from "../../../../../../../../../hooks/document/change-application/use-change-badge-properties";
import { FormSection } from "../../../../../../../../ui/form/FormSection/FormSection";
import { useAppTranslation } from "../../../../../../../../../hooks/use-app-translation";
import { ChangeReasonItemViewModel } from "../../../../../../../../../lib/object/vm/change-application-view-model";

export type ProjectNameFormSectionProps = {
  documentType:
    | "new_application"
    | "change_application"
    | "project_force_update"
    | "project_force_create"
    | "project_content";
  editMode: "editable" | "readOnly";

  /**
   * 値。
   */
  value: string;
  onChange: (value: string) => void;

  /**
   * 変更前の値。
   * 変更申請では、この値を与えること。
   */
  changedFrom?: string;
  /**
   * 変更理由の値。
   */
  changeReason?: string;
  onChangeReason?: (
    change: (before: ChangeReasonItemViewModel) => ChangeReasonItemViewModel,
  ) => void;
  displayChangesOnly?: boolean;

  /**
   * コメントボタンのprops。
   * これが存在すれば、コメントボタンを表示する。
   */
  commentButtonProps?: CMButtonFormCommentProps;
} & ComponentStyleProps;

/**
 * 課題名セクション
 */
export const ProjectNameFormSection = memo(function ProjectNameFormSection({
  documentType,
  editMode,

  value,
  onChange,

  changedFrom,
  changeReason,
  onChangeReason,
  displayChangesOnly,

  commentButtonProps,

  sx,
  ...rest
}: ProjectNameFormSectionProps) {
  const { t } = useAppTranslation();

  const sectionDiffMode = useSectionDiffMode(
    documentType,
    changedFrom,
    value,
    onChangeReason,
  );

  const changeBadgeProperties = useChangeBadgeProperties(
    documentType,
    changedFrom,
  );

  return (
    <FormSection
      title={t("lbl.課題名")}
      displayMode={sectionDiffMode}
      displayChangesOnly={displayChangesOnly}
      commentButtonProps={commentButtonProps}
      sx={sx}
      {...rest}
    >
      <CMFormInputText
        editMode={editMode}
        valueObjectMeta={projectNameMeta}
        value={value}
        noHeader={
          documentType === "new_application" ||
          (documentType === "change_application" && value === changedFrom)
        }
        onChange={onChange}
        changeBadgeProperties={changeBadgeProperties}
      />
      {sectionDiffMode === "updated" && (
        <ChangeReasonForm
          value={changeReason}
          onChange={onChangeReason}
          editMode={editMode}
        />
      )}
    </FormSection>
  );
});
