import { createBrowserRouter } from "react-router-dom";
import { GuestRoute } from "./components/route/GuestRoute";
import { FN10S01Login } from "./components/page/FN10/FN10S01Login/FN10S01Login";
import * as React from "react";
import { FN10SXXLoginCallback } from "./components/page/FN10/FN10SXXLoginCallback/FN10SXXLoginCallback";
import { FN10S06ResetPasswordConfirm } from "./components/page/FN10/FN10S06ResetPasswordConfirm/FN10S06ResetPasswordConfirm";
import { FN10S05ResetPasswordRequest } from "./components/page/FN10/FN10S05ResetPasswordRequest/FN10S05ResetPasswordRequest";
import { ProtectedRoute as PR } from "./components/route/ProtectedRoute";
import { MainLayout } from "./components/layout/MainLayout/MainLayout";
import { FN20S01Home } from "./components/page/FN20/FN20S01Home/FN20S01Home";
import { FN10S03MFASettings } from "./components/page/FN10/FN10S03MFASettings/FN10S03MFASettings";
import { FN10S07Profile } from "./components/page/FN10/FN10S07Profile/FN10S07Profile";
import { FN10S08ChangePassword } from "./components/page/FN10/FN10S08ChangePassword/FN10S08ChangePassword";
import { FN40S01DocumentSearch } from "./components/page/FN40/FN40S01DocumentSearch/FN40S01DocumentSearch";
import { FN30S01ProjectSearch } from "./components/page/FN30/FN30S01ProjectSearch/FN30S01ProjectSearch";
import { _documentCreateRoute } from "./routes/document-create-route";
import { _projectDetailsRoute } from "./routes/project-details-route";
import { _documentDetailsRoute } from "./routes/document-details-route";
import { _reviewConferenceRoute } from "./routes/review-conference-route";
import { _inquiryRoute } from "./routes/inquiry-route";
import { _workLogRoute } from "./routes/work-log-route";
import { _institutionRoute } from "./routes/institution-route";
import { _userRoute } from "./routes/user-route";
import { ForbiddenPage } from "./components/page/error/Forbidden/ForbiddenPage";
import { ServiceTemporarilyUnavailablePage } from "./components/page/error/ServiceTemporarilyUnavailable/ServiceTemporarilyUnavailablePage";
import { InternalServerErrorPage } from "./components/page/error/InternalServerError/InternalServerErrorPage";
import { NotFoundPage } from "./components/page/error/NotFound/NotFoundPage";
import { App } from "./App";
import { _mailLogRoute } from "./routes/mail-log-route";
import { FN10S02PasswordSettings } from "./components/page/FN10/FN10S02PasswordSettings/FN10S02PasswordSettings";
import { FN10S04MFA } from "./components/page/FN10/FN10S04MFA/FN10S04MFA";

export const router = createBrowserRouter([
  {
    element: <App />,
    children: [
      {
        element: <MainLayout />,
        children: [
          {
            element: <GuestRoute />,
            children: [
              {
                path: "login",
                children: [
                  { index: true, element: <FN10S01Login /> },
                  { path: "callback", element: <FN10SXXLoginCallback /> },
                  {
                    path: "password-settings",
                    element: <FN10S02PasswordSettings />,
                  },
                  {
                    path: "/login/mfa",
                    element: <FN10S04MFA />,
                  },
                ],
              },
              {
                path: "reset-password",
                children: [
                  {
                    path: "request",
                    element: <FN10S05ResetPasswordRequest />,
                  },
                  {
                    path: "confirm",
                    element: <FN10S06ResetPasswordConfirm />,
                  },
                ],
              },
            ],
          },
          {
            element: (
              <PR
                roles={[
                  "applicant",
                  "internal",
                  "office_member",
                  "committee_member",
                  "executive_director",
                ]}
              />
            ),
            children: [
              {
                index: true,
                element: <FN20S01Home />,
              },
              {
                path: "mfa-settings",
                element: <FN10S03MFASettings />,
              },
              {
                path: "profile",
                element: <FN10S07Profile />,
              },
              {
                path: "change-password",
                element: <FN10S08ChangePassword />,
              },
              {
                element: (
                  <PR
                    roles={[
                      "applicant",
                      "office_member",
                      "committee_member",
                      "executive_director",
                    ]}
                  />
                ),
                children: [
                  {
                    path: "project",
                    element: <FN30S01ProjectSearch />,
                  },
                  {
                    path: "document",
                    element: <FN40S01DocumentSearch />,
                  },
                ],
              },
              _documentCreateRoute,
              _projectDetailsRoute,
              _documentDetailsRoute,
              _reviewConferenceRoute,
              _inquiryRoute,
              _mailLogRoute,
              _workLogRoute,
              _institutionRoute,
              _userRoute,
            ],
          },
        ],
      },
    ],
  },
  {
    path: "error",
    children: [
      {
        path: "403",
        element: <ForbiddenPage />,
      },
      {
        path: "500",
        element: <InternalServerErrorPage />,
      },
      {
        path: "503",
        element: <ServiceTemporarilyUnavailablePage />,
      },
    ],
  },
  {
    path: "*",
    element: <NotFoundPage />,
  },
]);
