import { ComponentStyleProps } from "../../../../../../../../../lib/styles/props/component-style-props";
import { CMFormInputRadio } from "@pscsrvlab/psc-react-components";
import {
  IpsCultureExperience,
  ipsCultureExperienceMeta,
} from "../../../../../../../../../lib/object/value/ips-culture-experience";
import { Box } from "@chakra-ui/react";
import React, { memo } from "react";
import { CMButtonFormCommentProps } from "@pscsrvlab/psc-react-components/src/components/form/comment";
import { useSectionDiffMode } from "../../../../../../../../../hooks/document/change-application/use-section-diff-mode";
import { useChangeBadgeProperties } from "../../../../../../../../../hooks/document/change-application/use-change-badge-properties";
import { ChangeReasonForm } from "../../form/ChangeReasonForm/ChangeReasonForm";
import { FormSection } from "../../../../../../../../ui/form/FormSection/FormSection";
import { useAppTranslation } from "../../../../../../../../../hooks/use-app-translation";
import { hasValue } from "../../../../../../../../../lib/util/common-util";
import { ChangeReasonItemViewModel } from "../../../../../../../../../lib/object/vm/change-application-view-model";

export type IpsCultureExperienceSectionProps = {
  documentType:
    | "new_application"
    | "change_application"
    | "project_force_update"
    | "project_force_create"
    | "project_content";
  editMode: "editable" | "readOnly";

  value: IpsCultureExperience;
  onChange?: (value: IpsCultureExperience) => void;

  changedFrom?: string;
  changeReason?: string;
  onChangeReason?: (
    change: (before: ChangeReasonItemViewModel) => ChangeReasonItemViewModel,
  ) => void;
  displayChangesOnly?: boolean;

  /**
   * コメントボタンのprops。
   * これが存在すれば、コメントボタンを表示する。
   */
  commentButtonProps?: CMButtonFormCommentProps;
} & ComponentStyleProps;

export const IpsCultureExperienceSection = memo(
  function IpsCultureExperienceSection({
    documentType,
    editMode,

    value,
    onChange,

    changedFrom,
    changeReason,
    onChangeReason,
    displayChangesOnly,

    commentButtonProps,

    sx,
    ...rest
  }: IpsCultureExperienceSectionProps) {
    const { t } = useAppTranslation();

    const sectionDiffMode = useSectionDiffMode(
      documentType,
      changedFrom,
      value,
      onChangeReason,
    );

    const changeBadgeProperties = useChangeBadgeProperties(
      documentType,
      changedFrom,
    );

    return (
      <FormSection
        title={t("lbl.ヒトiPS細胞の培養経験")}
        displayMode={sectionDiffMode}
        displayChangesOnly={displayChangesOnly}
        commentButtonProps={commentButtonProps}
        sx={{ w: "100%", ...sx }}
        {...rest}
      >
        <Box mb={"20px"} fontSize={"sm"}>
          {t("gdc.ヒトiPS細胞の培養経験説明")}
        </Box>
        <CMFormInputRadio
          editMode={editMode}
          noHeader={!hasValue(changedFrom)}
          value={value}
          valueObjectMeta={ipsCultureExperienceMeta}
          onChange={onChange}
          direction={"row"}
          changeBadgeProperties={changeBadgeProperties}
        />
        {sectionDiffMode === "updated" && (
          <ChangeReasonForm
            value={changeReason}
            onChange={onChangeReason}
            editMode={editMode}
          />
        )}
      </FormSection>
    );
  },
);
