import { CMFormInputText } from "@pscsrvlab/psc-react-components";
import React, { memo } from "react";
import { CMButtonFormCommentProps } from "@pscsrvlab/psc-react-components/src/components/form/comment";
import { ComponentStyleProps } from "../../../../../../lib/styles/props/component-style-props";
import { projectNameMeta } from "../../../../../../lib/object/value/project-name";
import { useAppTranslation } from "../../../../../../hooks/use-app-translation";
import { FormSection } from "../../../../../ui/form/FormSection/FormSection";

export type ProjectNameFormReportSectionProps = {
  /**
   * 現在の値。
   */
  value: string;

  /**
   * コメントボタンのprops。
   * これが存在すれば、コメントボタンを表示する。
   */
  commentButtonProps?: CMButtonFormCommentProps;
} & ComponentStyleProps;

/**
 * 課題名セクション
 */
export const ProjectNameFormReportSection = memo(
  function ProjectNameFormReportSection({
    value,
    commentButtonProps,
    sx,
    ...rest
  }: ProjectNameFormReportSectionProps) {
    const { t } = useAppTranslation();

    return (
      <FormSection
        title={t("lbl.課題名")}
        commentButtonProps={commentButtonProps}
        sx={sx}
        {...rest}
      >
        <CMFormInputText
          editMode={"readOnly"}
          valueObjectMeta={projectNameMeta}
          value={value}
        />
      </FormSection>
    );
  },
);
