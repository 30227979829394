import { CMButtonFormCommentProps } from "@pscsrvlab/psc-react-components/src/components/form/comment";
import { ComponentStyleProps } from "../../../../../../lib/styles/props/component-style-props";
import { memo, useCallback } from "react";
import { VStack } from "@chakra-ui/react";
import { CMFrameCard } from "@pscsrvlab/psc-react-components";
import { SectionContentFrame } from "../../../application/_components/ApplicationDocument/_components/frame/SectionContentFrame/SectionContentFrame";
import { CollaborativePartnerOnReport } from "../../../../../../lib/object/value/collaborative-partner-on-report";
import { PartnerReportCard } from "../../annual-report/AnnualReportCreatePage/_components/card/PartnerReportCard";
import { useAppTranslation } from "../../../../../../hooks/use-app-translation";
import { FormSection } from "../../../../../ui/form/FormSection/FormSection";

export type PartnersReportSectionProps = {
  editMode: "editable" | "readOnly";
  value: Partial<CollaborativePartnerOnReport>[];
  onChange: (
    change: (
      before: Partial<CollaborativePartnerOnReport>[],
    ) => Partial<CollaborativePartnerOnReport>[],
  ) => void;

  /**
   * コメントボタンのprops。
   * これが存在すれば、コメントボタンを表示する。
   */
  commentButtonProps?: CMButtonFormCommentProps;
  /**
   * 子要素のコメントボタンのprops。
   * これが存在すれば、コメントボタンを表示する。
   */
  commentButtonPropsChildren?: {
    root: CMButtonFormCommentProps | undefined;
    cellProvisionsN: (CMButtonFormCommentProps | undefined)[];
  }[];
} & ComponentStyleProps;

/**
 * 分担機関セクション(年次報告)
 */
export const PartnersReportSection = memo(function PartnersReportSection({
  editMode,

  value,

  onChange,

  commentButtonProps,
  commentButtonPropsChildren,

  sx,
  ...rest
}: PartnersReportSectionProps) {
  const { t } = useAppTranslation();

  const handleChange = useCallback(
    (
      changeIndex: number,
      change: (
        before: Partial<CollaborativePartnerOnReport>,
      ) => Partial<CollaborativePartnerOnReport>,
    ) => {
      onChange((before) => {
        return before.map((val, index) => {
          if (changeIndex === index) {
            return change(val);
          } else {
            return val;
          }
        });
      });
    },
    [onChange],
  );

  return (
    <>
      <FormSection
        title={t("lbl.分担機関")}
        description={
          editMode === "editable" ? t("gdc.分担機関補足(報告)") : undefined
        }
        commentButtonProps={commentButtonProps}
        sx={sx}
        {...rest}
      >
        <VStack spacing={"10px"} alignItems={"stretch"}>
          {value.map((v, index) => (
            <CMFrameCard
              key={index}
              backgroundColor={partnerCardColor(index)}
              title={t("lbl.分担機関") + (index + 1)}
              commentButtonProps={commentButtonPropsChildren?.[index].root}
            >
              <SectionContentFrame>
                <PartnerReportCard
                  editMode={editMode}
                  index={index}
                  value={v}
                  onChange={handleChange}
                  commentButtonPropsChildren={
                    commentButtonPropsChildren?.[index].cellProvisionsN
                  }
                />
              </SectionContentFrame>
            </CMFrameCard>
          ))}
        </VStack>
      </FormSection>
    </>
  );
});

const partnerCardColor = (cardIndex: number): string => {
  const colorList = ["pink.50", "orange.50"];
  const colorIndex = cardIndex % colorList.length;
  return colorList[colorIndex];
};
