import { ComponentStyleProps } from "../../../../../../../../../lib/styles/props/component-style-props";
import { HStack, Text, VStack } from "@chakra-ui/react";
import {
  CMButtonFormComment,
  CMButtonFormCommentProps,
  CMFormInputBaseCheckbox,
} from "@pscsrvlab/psc-react-components";
import React, { memo, useCallback, useEffect, useMemo } from "react";
import { hasValue } from "../../../../../../../../../lib/util/common-util";
import { typedObjectEntries } from "../../../../../../../../../lib/util/common-object-util";
import { useAppTranslation } from "../../../../../../../../../hooks/use-app-translation";
import log from "loglevel";

export type ChecklistSectionProps = {
  editMode: "editable" | "readOnly";

  valueChecklistItem1: "checked" | "unchecked";
  valueChecklistItem2: "checked" | "unchecked";
  valueChecklistItem3: "checked" | "unchecked";
  valueChecklistItem4: "checked" | "unchecked";
  valueChecklistItem5: "checked" | "unchecked";
  valueChecklistItem6: "checked" | "unchecked";

  onChange?: (value: {
    checklistItem1: "checked" | "unchecked";
    checklistItem2: "checked" | "unchecked";
    checklistItem3: "checked" | "unchecked";
    checklistItem4: "checked" | "unchecked";
    checklistItem5: "checked" | "unchecked";
    checklistItem6: "checked" | "unchecked";
  }) => void;

  /**
   * コメントボタンのprops。
   * これが存在すれば、コメントボタンを表示する。
   */
  commentButtonProps?: CMButtonFormCommentProps;
} & ComponentStyleProps;

export const CheckListSection = memo(function ChecklistSection({
  editMode,

  valueChecklistItem1,
  valueChecklistItem2,
  valueChecklistItem3,
  valueChecklistItem4,
  valueChecklistItem5,
  valueChecklistItem6,

  onChange,

  commentButtonProps,

  sx,
  ...rest
}: ChecklistSectionProps) {
  const { t } = useAppTranslation();

  useEffect(() => {
    log.debug(`commentButtonProps=`, commentButtonProps);
  }, [commentButtonProps]);

  const handleChangeCommon = useCallback(
    (vs: string[]) => {
      const toCheckedEnum = (key: string) =>
        vs.includes(key) ? "checked" : "unchecked";
      onChange?.({
        checklistItem1: toCheckedEnum("checklistItem1"),
        checklistItem2: toCheckedEnum("checklistItem2"),
        checklistItem3: valueChecklistItem3,
        checklistItem4: valueChecklistItem4,
        checklistItem5: valueChecklistItem5,
        checklistItem6: valueChecklistItem6,
      });
    },
    [
      onChange,
      valueChecklistItem3,
      valueChecklistItem4,
      valueChecklistItem5,
      valueChecklistItem6,
    ],
  );

  const handleChangeClinical = useCallback(
    (vs: string[]) => {
      const toCheckedEnum = (key: string) =>
        vs.includes(key) ? "checked" : "unchecked";
      onChange?.({
        checklistItem1: valueChecklistItem1,
        checklistItem2: valueChecklistItem2,
        checklistItem3: toCheckedEnum("checklistItem3"),
        checklistItem4: toCheckedEnum("checklistItem4"),
        checklistItem5: toCheckedEnum("checklistItem5"),
        checklistItem6: toCheckedEnum("checklistItem6"),
      });
    },
    [onChange, valueChecklistItem1, valueChecklistItem2],
  );

  /**
   * ("checked" | "unchecked")[] → チェック済キーの配列への変換。
   */
  const commonCheckValues = useMemo(() => {
    return typedObjectEntries({
      checklistItem1: valueChecklistItem1,
      checklistItem2: valueChecklistItem2,
    })
      .map(([k, v]) => (v === "checked" ? k : null))
      .filter(hasValue);
  }, [valueChecklistItem1, valueChecklistItem2]);
  const clinicalCheckValues = useMemo(() => {
    return typedObjectEntries({
      checklistItem3: valueChecklistItem3,
      checklistItem4: valueChecklistItem4,
      checklistItem5: valueChecklistItem5,
      checklistItem6: valueChecklistItem6,
    })
      .map(([k, v]) => (v === "checked" ? k : null))
      .filter(hasValue);
  }, [
    valueChecklistItem3,
    valueChecklistItem4,
    valueChecklistItem5,
    valueChecklistItem6,
  ]);

  return (
    <VStack sx={sx} {...rest} maxW={"720px"} alignItems={"flex-start"}>
      <HStack alignSelf={"center"} pt={"30px"} pb={"10px"}>
        <Text fontWeight={"bold"} alignSelf={"center"}>
          {t("lbl.iPS細胞ストックに使用に係るチェックリスト")}
        </Text>
        {hasValue(commentButtonProps) && (
          <CMButtonFormComment {...commentButtonProps} />
        )}
      </HStack>
      <CMFormInputBaseCheckbox
        sx={{ color: "gray.900" }}
        editMode={editMode === "editable" ? "editable" : "disabled"}
        onChange={handleChangeCommon}
        values={commonCheckValues}
        content={{
          options: [
            { label: t("lbl.チェック項目1"), value: "checklistItem1" },
            { label: t("lbl.チェック項目2"), value: "checklistItem2" },
          ],
        }}
        pb={"10px"}
      />
      {editMode === "editable" && (
        <Text pb={"10px"}>{t("gdc.チェックリスト案内")}</Text>
      )}
      <CMFormInputBaseCheckbox
        editMode={editMode === "editable" ? "editable" : "disabled"}
        onChange={handleChangeClinical}
        values={clinicalCheckValues}
        content={{
          options: [
            { label: t("lbl.チェック項目3"), value: "checklistItem3" },
            { label: t("lbl.チェック項目4"), value: "checklistItem4" },
            { label: t("lbl.チェック項目5"), value: "checklistItem5" },
            { label: t("lbl.チェック項目6"), value: "checklistItem6" },
          ],
        }}
      />
    </VStack>
  );
});
