import { ComponentStyleProps } from "../../../../../../../../../lib/styles/props/component-style-props";
import {
  CMFormInputRadio,
  DeleteConfirmationModal,
} from "@pscsrvlab/psc-react-components";
import {
  HasCollaborativePartner,
  hasCollaborativePartnerMeta,
} from "../../../../../../../../../lib/object/value/has-collaborative-partner";
import { useDisclosure } from "@chakra-ui/react";
import React, { memo, useCallback } from "react";
import { CMButtonFormCommentProps } from "@pscsrvlab/psc-react-components/src/components/form/comment";
import { useSectionDiffMode } from "../../../../../../../../../hooks/document/change-application/use-section-diff-mode";
import { useChangeBadgeProperties } from "../../../../../../../../../hooks/document/change-application/use-change-badge-properties";
import { ChangeReasonForm } from "../../form/ChangeReasonForm/ChangeReasonForm";
import { FormSection } from "../../../../../../../../ui/form/FormSection/FormSection";
import { useAppTranslation } from "../../../../../../../../../hooks/use-app-translation";
import { hasValue } from "../../../../../../../../../lib/util/common-util";
import { ChangeReasonItemViewModel } from "../../../../../../../../../lib/object/vm/change-application-view-model";

export type HasCollaborativePartnerSectionProps = {
  documentType:
    | "new_application"
    | "change_application"
    | "project_force_update"
    | "project_force_create"
    | "project_content";
  editMode: "editable" | "readOnly";

  value: HasCollaborativePartner;
  onChange: (value: HasCollaborativePartner) => void;

  changedFrom?: string;
  changeReason?: string;
  onChangeReason?: (
    change: (before: ChangeReasonItemViewModel) => ChangeReasonItemViewModel,
  ) => void;
  displayChangesOnly?: boolean;

  /**
   * コメントボタンのprops。
   * これが存在すれば、コメントボタンを表示する。
   */
  commentButtonProps?: CMButtonFormCommentProps;
} & ComponentStyleProps;

/**
 * 分担機関の有無セクション
 */
export const HasCollaborativePartnerSection = memo(
  function HasCollaborativePartnerSection({
    documentType,
    editMode,

    value,
    onChange,

    changedFrom,
    changeReason,
    onChangeReason,
    displayChangesOnly,

    commentButtonProps,

    sx,
    ...rest
  }: HasCollaborativePartnerSectionProps) {
    const { t } = useAppTranslation();

    // モーダル制御
    const {
      isOpen: isOpenDeleteModal,
      onOpen: onOpenDeleteModal,
      onClose: onCloseDeleteModal,
    } = useDisclosure();

    // todo:変更申請時に分担機関の有無で「無」が選択されると、分担機関セクションは非表示にしない。
    //  また、有→無が選択されると分担機関セクションのカードをすべて削除モードに変更する。
    const handleChange = useCallback(
      (v: "has_partner" | "no_partner") => {
        switch (v) {
          case "no_partner":
            onOpenDeleteModal();
            break;
          case "has_partner":
            onChange?.("has_partner");
            break;
        }
      },
      [onChange, onOpenDeleteModal],
    );

    /**
     * モーダルの削除ボタンを押下すると呼ばれる。
     */
    const handleConfirmDelete = useCallback(() => {
      onChange?.("no_partner");
      onCloseDeleteModal();
    }, [onChange, onCloseDeleteModal]);

    /**
     * モーダルのキャンセルボタンを押下すると呼ばれる。
     */
    const handleCancelModal = useCallback(() => {
      onCloseDeleteModal();
    }, [onCloseDeleteModal]);

    const sectionDiffMode = useSectionDiffMode(
      documentType,
      changedFrom,
      value,
      onChangeReason,
    );

    const changeBadgeProperties = useChangeBadgeProperties(
      documentType,
      changedFrom,
    );

    return (
      <>
        <FormSection
          title={t("lbl.分担機関の有無")}
          description={
            editMode === "editable" ? t("gdc.分担機関の有無補足") : undefined
          }
          displayMode={sectionDiffMode}
          displayChangesOnly={displayChangesOnly}
          commentButtonProps={commentButtonProps}
          sx={sx}
          {...rest}
        >
          <CMFormInputRadio
            direction={"row"}
            noHeader={!hasValue(changedFrom)}
            editMode={editMode}
            value={value}
            valueObjectMeta={hasCollaborativePartnerMeta}
            onChange={handleChange}
            changeBadgeProperties={changeBadgeProperties}
          />
          {sectionDiffMode === "updated" && (
            <ChangeReasonForm
              value={changeReason}
              onChange={onChangeReason}
              editMode={editMode}
            />
          )}
        </FormSection>
        {/*分担機関有無で無が選択された際に、分担機関情報のリセット確認のために出現するモーダル*/}
        <DeleteConfirmationModal
          isOpen={isOpenDeleteModal}
          onClose={handleCancelModal}
          title={t("lbl.確認ポップアップタイトル")}
          message={t("mes.分担機関リセット確認メッセージ")}
          deleteButtonLabel={t("btn.削除ボタン")}
          cancelButtonLabel={t("btn.キャンセルボタン")}
          onConfirm={handleConfirmDelete}
        />
      </>
    );
  },
);
