import {
  CMFrameCard,
  CMSectionMultipleCardAddable,
  DeleteConfirmationModal,
} from "@pscsrvlab/psc-react-components";
import { useDisclosure, VStack } from "@chakra-ui/react";
import React, { memo, useCallback, useState } from "react";
import { RiDeleteBin5Line } from "react-icons/ri";
import { CMButtonFormCommentProps } from "@pscsrvlab/psc-react-components/src/components/form/comment";
import { DifferentiatedCellProvision } from "../../../../../../lib/object/value/differentiated-cell-provision";
import { ComponentStyleProps } from "../../../../../../lib/styles/props/component-style-props";
import { hasValue } from "../../../../../../lib/util/common-util";
import { SectionContentFrame } from "../../../application/_components/ApplicationDocument/_components/frame/SectionContentFrame/SectionContentFrame";
import { CellProvisionCard } from "../../annual-report/AnnualReportCreatePage/_components/card/CellProvisionCard";
import { useAppTranslation } from "../../../../../../hooks/use-app-translation";
import { useKeyedData } from "../../../../../../hooks/use-keyed-data";
import { nextGroupLocalKey } from "../../../../../../lib/util/app-util";
import { FormSection } from "../../../../../ui/form/FormSection/FormSection";

export type CellProvisionsReportSectionProps = {
  documentType?: DocumentType;
  editMode: "editable" | "readOnly";

  value: DifferentiatedCellProvision[];

  onChange: (
    change: (
      before: DifferentiatedCellProvision[],
    ) => DifferentiatedCellProvision[],
  ) => void;

  /**
   * コメントボタンのprops。
   * これが存在すれば、コメントボタンを表示する。
   */
  commentButtonProps?: CMButtonFormCommentProps;
  /**
   * 子要素のコメントボタンのprops。
   * これが存在すれば、コメントボタンを表示する。
   * valueと同じ要素数であること。
   */
  commentButtonPropsChildren?: (CMButtonFormCommentProps | undefined)[];
} & ComponentStyleProps;

/**
 * 分化細胞の提供先セクション
 */
export const CellProvisionsReportSection = memo(
  function CellProvisionsReportSection({
    editMode,
    value,
    onChange,
    commentButtonProps,
    commentButtonPropsChildren,

    sx,
    ...rest
  }: CellProvisionsReportSectionProps) {
    const { t } = useAppTranslation();

    const { keyedValues } = useKeyedData(value);

    /**
     * 追加ボタン押下時に空の提供先を追加する。
     */
    const handleAddCellProvision = useCallback(() => {
      const nextKey = nextGroupLocalKey(keyedValues.map((v) => v.value));
      onChange?.((before) => [
        ...before,
        {
          key: nextKey,
          cellInformation: "",
          provisionReason: "",
          cellDisposalType: "",
          recipientName: "",
        },
      ]);
    }, [keyedValues, onChange]);

    // モーダル制御
    const {
      isOpen: isOpenDeleteModal,
      onOpen: onOpenDeleteModal,
      onClose: onCloseDeleteModal,
    } = useDisclosure();

    /**
     * カード削除対象のカードインデックス
     */
    const [deletingIndex, setDeletingIndex] = useState(0);

    /**
     * カード削除ボタンが押下されると呼ばれ、モーダルを出現させる。
     * @param index
     */
    const handleDeleteButtonClick = useCallback(
      (index: number) => {
        setDeletingIndex(index);
        onOpenDeleteModal();
      },
      [onOpenDeleteModal],
    );

    /**
     * モーダルの削除ボタンを押下すると呼ばれ、該当カードを削除する。
     */
    const handleConfirmDelete = useCallback(() => {
      onChange?.((before) =>
        before.filter((v, index) => index !== deletingIndex),
      );
      onCloseDeleteModal();
    }, [deletingIndex, onChange, onCloseDeleteModal]);

    const handleChange = useCallback(
      (
        changeIndex: number,
        change: (
          before: DifferentiatedCellProvision,
        ) => DifferentiatedCellProvision,
      ) => {
        onChange?.((before) =>
          before.map((val, index) => {
            if (changeIndex === index) {
              return change(val);
            } else {
              return val;
            }
          }),
        );
      },
      [onChange],
    );

    return (
      <>
        <FormSection
          title={t("lbl.分化細胞等の提供先(分担機関以外)")}
          description={
            editMode === "editable"
              ? t("gdc.分化細胞等の提供先補足")
              : undefined
          }
          commentButtonProps={commentButtonProps}
          sx={sx}
          {...rest}
        >
          <CMSectionMultipleCardAddable
            editMode={editMode}
            onAddSection={handleAddCellProvision}
            buttonLabel={t("btn.分化細胞の提供先を追加ボタン")}
          >
            <VStack spacing={"10px"} alignItems={"stretch"}>
              {/*contactPeople配列にあるユーザーを全てカードで出力*/}
              {value.map((v, index) => (
                <CMFrameCard
                  key={index}
                  backgroundColor={"gray.200"}
                  title={t("lbl.提供先") + (index + 1).toString(10)}
                  deleteButtonIcon={
                    editMode === "editable" && <RiDeleteBin5Line />
                  }
                  onDelete={() => handleDeleteButtonClick(index)}
                  commentButtonProps={
                    hasValue(commentButtonPropsChildren) &&
                    index < commentButtonPropsChildren.length
                      ? commentButtonPropsChildren[index]
                      : undefined
                  }
                >
                  {hasValue(v) && (
                    <SectionContentFrame>
                      <CellProvisionCard
                        editMode={editMode}
                        index={index}
                        value={v}
                        onChange={handleChange}
                      />
                    </SectionContentFrame>
                  )}
                </CMFrameCard>
              ))}
            </VStack>
          </CMSectionMultipleCardAddable>
        </FormSection>
        {/*カード削除時に出現するモーダル*/}
        <DeleteConfirmationModal
          isOpen={isOpenDeleteModal}
          title={t("lbl.確認ポップアップタイトル")}
          message={t("mes.分化細胞の提供先削除確認メッセージ")}
          deleteButtonLabel={t("btn.削除ボタン")}
          cancelButtonLabel={t("btn.キャンセルボタン")}
          onConfirm={handleConfirmDelete}
          onCancel={onCloseDeleteModal}
          onClose={onCloseDeleteModal}
        />
      </>
    );
  },
);
