import { ComponentStyleProps } from "../../../../../../lib/styles/props/component-style-props";
import { ProjectNameFormSection } from "./_components/section/ProjectNameFormSection/ProjectNameFormSection";
import {
  Container,
  HStack,
  PositionProps,
  Spacer,
  Text,
  VStack,
} from "@chakra-ui/react";
import { PlanSummarySection } from "./_components/section/PlanSumamryFormSection/PlanSummarySection";
import { ContactPeopleSection } from "./_components/section/ContactPeopleSection/ContactPeopleSection";
import { PrincipalInvestigatorSection } from "./_components/section/PrincipalInvestigatorSection/PrincipalInvestigatorSection";
import { InstitutionNameSection } from "./_components/section/InstitutionNameSection/InstitutionNameSection";
import { RequestingCellsSection } from "./_components/section/RequestingCellsSection/RequestingCellsSection";
import { AttachmentFilesSection } from "./_components/section/AttachmentFilesSection/AttachmentFilesSection";
import { IpsCellStockUsePurposeSection } from "./_components/section/IpsCellStockUsePurposeSection/IpsCellStockUsePurposeSection";
import { IpsCellStockRequirementReasonSection } from "./_components/section/IpsCellStockRequirementReasonSection/IpsCellStockRequirementReasonSection";
import { HasCollaborativePartnerSection } from "./_components/section/HasCollaborativePartnerSection/HasCollaborativePartnerSection";
import { EthicalApplicationStatusSection } from "./_components/section/EthicalApplicationStatusSection/EthicalApplicationStatusSection";
import { UsageEndDateSection } from "./_components/section/UsageEndDateSection/UsageEndDateSection";
import { ResearchSiteSection } from "./_components/section/ResearchSiteSection/ResearchSiteSection";
import { IpsCultureExperienceSection } from "./_components/section/IpsCultureExperienceSection/IpsCultureExperienceSection";
import { PartnersSection } from "./_components/section/PartnersSection/PartnersSection";
import { HandlingAfterUseSection } from "./_components/section/HandlingAfterUseSection/HandlingAfterUseSection";
import { CheckListSection } from "./_components/section/CheckListSection/CheckListSection";
import { SignatureSection } from "./_components/section/SignatureSection/SignatureSection";
import {
  ApplicationContent,
  ApplicationContentSaved,
} from "../../../../../../lib/object/value/application-content";
import { FoundationContractStateSection } from "./_components/section/FoundationContractStateSection/FoundationContractStateSection";
import { UserInformation } from "../../../../../../lib/object/value/user-information";
import React, { memo, Ref, useCallback, useMemo, useState } from "react";
import { RequestingCell } from "../../../../../../lib/object/value/requesting-cell";
import { HasCollaborativePartner } from "../../../../../../lib/object/value/has-collaborative-partner";
import { YearMonthDay } from "../../../../../../lib/object/value/year-month-day";
import { IpsCultureExperience } from "../../../../../../lib/object/value/ips-culture-experience";
import { InstitutionInformation } from "../../../../../../lib/object/value/institution-information";
import { EthicalApplicationStatus } from "../../../../../../lib/object/value/ethical-application-status";
import { HandlingAfterUse } from "../../../../../../lib/object/value/handling-after-use";
import { IpsCellStockUsePurpose } from "../../../../../../lib/object/value/ips-cell-stock-use-purpose";
import { LoginUserInfo } from "../../../../../../store/auth/types";
import { useApplicationDocumentCommentProps } from "./_hooks/use-application-document-comment-props";
import { CollaborativeResearchContractStateWithFoundation } from "../../../../../../lib/object/value/collaborative-research-contract-state-with-foundation";
import { CollaborativePartner } from "../../../../../../lib/object/value/collaborative-partner";
import { UserInformationKeyed } from "../../../../../../lib/object/value/user-information-keyed";
import { SetRequired } from "type-fest";
import { ToggleDisplayChangesSwitch } from "./_components/switch/ToggleDisplayChangesSwitch/ToggleDisplayChangesSwitch";
import {
  ChangeReasonItemViewModel,
  ChangeReasonViewModel,
} from "../../../../../../lib/object/vm/change-application-view-model";
import { SignatureValue } from "../../../../../../lib/object/value/signature-value";
import { RevisionRequestItemViewModel } from "../../../../../../lib/object/vm/revision-request-view-model";
import { AttachmentFilesSectionViewModel } from "../../../../../../lib/object/vm/attachment-files-section-view-model";
import { InPageLinkApplicationDocument } from "./_components/link/InPageLinkApplicationDocument/InPageLinkApplicationDocument";
import { ErrorMessageArea } from "../../../../../ui/form/ErrorMessageArea/ErrorMessageArea";
import { ValidationError } from "@pscsrvlab/psc-react-components";
import { hasValue } from "../../../../../../lib/util/common-util";
import { useAppTranslation } from "../../../../../../hooks/use-app-translation";
import { DocumentHeaderControlNumbers } from "../../../_components/DocumentHeaderControlNumbers/DocumentHeaderControlNumbers";
import { DocumentHeaderDates } from "../../../_components/DocumentHeaderDates/DocumentHeaderDates";

export type ApplicationDocumentProps = {
  loginUserInfo: LoginUserInfo;

  documentType:
    | "new_application"
    | "change_application"
    | "project_force_update"
    | "project_force_create"
    | "project_content";
  editMode: "editable" | "readOnly";

  /**
   * ファイルアップロード時の権限設定に用いる、案件ID。
   * 読み取り専用ページでは、nullでも問題ない。
   */
  projectId: number | null;

  showControlNumbers: boolean;
  projectControlNumber: string | null | undefined;
  documentControlNumber: string | null | undefined;

  showDates: boolean;
  submissionDate: YearMonthDay | null | undefined;
  receptionDate: YearMonthDay | null | undefined;
  approvalDate: YearMonthDay | null | undefined;
  documentCompletionDate: YearMonthDay | null | undefined;

  /**
   * 値。申請内容。
   */
  value: ApplicationContentViewModel;
  onChange?: (
    change: (
      before: ApplicationContentViewModel,
    ) => ApplicationContentViewModel,
  ) => void;

  signatureValue?: SignatureValue;
  onChangeSignatureValue?: (
    change: (before: SignatureValue) => SignatureValue,
  ) => void;

  /**
   * 変更申請前の内容。
   * 変更申請の場合のみ必須。
   */
  beforeValue?: ApplicationContentSaved;

  /**
   * 変更申請の変更理由。
   * 変更申請の場合のみ必須。
   */
  changeReasonVM?: ChangeReasonViewModel;
  onChangeReason?: (
    change: (before: ChangeReasonViewModel) => ChangeReasonViewModel,
  ) => void;

  /**
   * 修正依頼。
   */
  revisionRequestItems?: RevisionRequestItemViewModel[];
  /**
   * 修正依頼コメントを選択したときのコールバック。
   * 未選択状態になる場合はnullを送出する。
   */
  onSelectComment?: (path: string | null) => void;
  /**
   * 修正依頼の変更。
   */
  onChangeRevisionRequestItems?: (
    change: (
      before: RevisionRequestItemViewModel[],
    ) => RevisionRequestItemViewModel[],
  ) => void;

  revisionMode?:
    | "none"
    | "readOnly"
    | "office_member_editable"
    | "applicant_editable";

  /**
   * 編集モード時、連絡担当者の最初の一人目を編集不能にしたければtrueを設定する。
   */
  freezeFirstContactPerson?: boolean;

  /**
   * バリデーションエラー。
   * 編集画面でのみ必要。
   */
  validationErrors?: ValidationError[];

  /**
   * stickyなエリアのtopの値。
   */
  stickyAreaTop?: PositionProps["top"];

  scrollableRef: Ref<any>;
  scrollOffset: number;
} & ComponentStyleProps;

/**
 * 申請書類(ドメインコンポーネント)
 */
export const ApplicationDocument = memo(function ApplicationDocument({
  loginUserInfo,

  documentType,
  editMode,

  projectId,

  showControlNumbers,
  projectControlNumber,
  documentControlNumber,

  showDates,
  submissionDate,
  receptionDate,
  approvalDate,
  documentCompletionDate,

  value,
  onChange,

  signatureValue,
  onChangeSignatureValue,

  beforeValue,
  changeReasonVM,
  onChangeReason,

  revisionRequestItems,
  onSelectComment,
  onChangeRevisionRequestItems,

  revisionMode = "none",

  freezeFirstContactPerson = false,

  validationErrors,

  stickyAreaTop = 0,

  scrollableRef,
  scrollOffset,

  sx,
  ...rest
}: ApplicationDocumentProps) {
  const { t } = useAppTranslation();

  const institutionId: number | undefined = useMemo(
    () => value.institution.institutionId,
    [value.institution.institutionId],
  );
  const principalInvestigatorAppUserId: number | undefined = useMemo(
    () => value.principalInvestigator?.appUserId,
    [value.principalInvestigator?.appUserId],
  );

  const {
    handleChangeProjectNameFormSection,
    handleChangeInstitutionNameSection,
    handleChangePrincipalInvestigatorSection,
    handleChangeContactPeopleSection,
    handleChangeRequestingCellsSectionResearch,
    handleChangeRequestingCellsSectionClinical,
    handleChangeIpsCellStockUsePurposeSection,
    handleChangeIpsCellStockRequirementReasonSection,
    handleChangePlanSummaryFormSection,
    handleChangeHasCollaborativePartnerSection,
    handleChangeUsageEndDateSection,
    handleChangeResearchSiteSection,
    handleChangeIpsCultureExperienceSection,
    handleChangeEthicalApplicationStatusSection,
    handleChangeHandlingAfterUseSection,
    handleChangeFoundationContractStateSection,
    handleChangeAttachmentFilesSection,
    handleChangePartnersSection,
    handleChangeChecklistSection,
    handleChangeSignatureSection,
  } = useChangeHandlers(onChange, onChangeSignatureValue);

  const {
    handleChangeReasonProjectName,
    handleChangeReasonInstitutionName,
    handleChangeReasonPrincipalInvestigator,
    handleChangeReasonContactPeople,
    handleChangeReasonRequestingCells,
    handleChangeReasonIpsCellStockUsePurpose,
    handleChangeReasonIpsCellStockRequirementReason,
    handleChangeReasonPlanSummary,
    handleChangeReasonHasCollaborativePartner,
    handleChangeReasonUsageEndDate,
    handleChangeReasonResearchSite,
    handleChangeReasonIpsCultureExperience,
    handleChangeReasonEthicalApplicationStatus,
    handleChangeReasonHandlingAfterUse,
    handleChangeReasonFoundationContractState,
    handleChangeReasonAttachmentFiles,
    handleChangeReasonPartners,
  } = useChangeReasonHandlers(onChangeReason);

  const commentProps = useApplicationDocumentCommentProps(
    loginUserInfo,
    value,
    beforeValue,
    revisionMode,
    revisionRequestItems,
    onSelectComment,
    onChangeRevisionRequestItems,
  );

  const [displayChangesOnly, setDisplayChangesOnly] = useState(false);

  return (
    <Container
      maxW={"720px"}
      px={0}
      className={"ApplicationDocument"}
      sx={sx}
      {...rest}
    >
      <VStack justifyContent={"flex-start"} alignItems={"stretch"}>
        <VStack
          alignItems={"stretch"}
          pt={"10px"}
          position={"sticky"}
          top={stickyAreaTop}
          bgColor={"white"}
          zIndex={20}
        >
          {hasValue(validationErrors) && validationErrors.length > 0 && (
            <ErrorMessageArea validationErrors={validationErrors} />
          )}
          <InPageLinkApplicationDocument
            scrollableRef={scrollableRef}
            scrollOffset={scrollOffset}
          />
        </VStack>
        {showControlNumbers && (
          <DocumentHeaderControlNumbers
            projectControlNumber={projectControlNumber}
            documentControlNumber={documentControlNumber}
          />
        )}
        {documentType === "new_application" ? (
          <VStack alignItems={"stretch"}>
            <Text alignSelf={"center"} fontWeight={"bold"}>
              {t("lbl.新規申請書タイトル")}
            </Text>
            <HStack mt={"20px"}>
              <VStack alignItems={"flex-start"}>
                <Text>{t("lbl.書類宛先")}</Text>
                <Text>{t("mes.新規申請宣言")}</Text>
              </VStack>
              {showDates && (
                <DocumentHeaderDates
                  submissionDate={submissionDate}
                  receptionDate={receptionDate}
                  approvalDate={approvalDate}
                  documentCompletionDate={documentCompletionDate}
                  sx={{ flex: "1 1 auto" }}
                />
              )}
            </HStack>
          </VStack>
        ) : documentType === "change_application" ? (
          <VStack alignItems={"stretch"}>
            <Text alignSelf={"center"} fontWeight={"bold"}>
              {t("lbl.変更申請書タイトル")}
            </Text>
            <HStack>
              <VStack mt={"20px"} alignItems={"flex-start"} flex={"1 1 auto"}>
                <Text>{t("lbl.書類宛先")}</Text>
                <Text>{t("mes.変更申請宣言")}</Text>
              </VStack>
              <VStack
                ml={"auto"}
                alignSelf={"stretch"}
                alignItems={"flex-end"}
                spacing={"2px"}
              >
                {showDates && (
                  <DocumentHeaderDates
                    submissionDate={submissionDate}
                    receptionDate={receptionDate}
                    approvalDate={approvalDate}
                    documentCompletionDate={documentCompletionDate}
                  />
                )}
                <Spacer />
                <ToggleDisplayChangesSwitch
                  isChecked={displayChangesOnly}
                  onChange={setDisplayChangesOnly}
                />
              </VStack>
            </HStack>
          </VStack>
        ) : null}

        <VStack
          alignItems={"stretch"}
          overflowX={"hidden"}
          overflowY={"hidden"}
        >
          {/*書類内リンク用のタグ*/}
          <div id={"main"}></div>
          <ProjectNameFormSection
            documentType={documentType}
            editMode={editMode}
            value={value.projectName}
            onChange={handleChangeProjectNameFormSection}
            changedFrom={beforeValue?.projectName}
            changeReason={changeReasonVM?.projectName?.reason}
            onChangeReason={handleChangeReasonProjectName}
            displayChangesOnly={displayChangesOnly}
            commentButtonProps={commentProps.projectName}
          />

          <InstitutionNameSection
            documentType={documentType}
            editMode={editMode}
            value={value.institution}
            onChange={handleChangeInstitutionNameSection}
            changedFrom={beforeValue?.institution}
            changeReason={changeReasonVM?.institutionName?.reason}
            onChangeReason={handleChangeReasonInstitutionName}
            displayChangesOnly={displayChangesOnly}
            commentButtonProps={commentProps.institution}
          />

          <PrincipalInvestigatorSection
            documentType={documentType}
            editMode={editMode}
            institutionId={institutionId}
            value={value.principalInvestigator ?? null}
            onChange={handleChangePrincipalInvestigatorSection}
            changedFrom={beforeValue?.principalInvestigator}
            changeReason={changeReasonVM?.principalInvestigator?.reason}
            onChangeReason={handleChangeReasonPrincipalInvestigator}
            displayChangesOnly={displayChangesOnly}
            commentButtonProps={commentProps.principalInvestigator}
          />

          <ContactPeopleSection
            documentType={documentType}
            editMode={editMode}
            institutionId={institutionId}
            value={value.contactPeople}
            onChange={handleChangeContactPeopleSection}
            freezeFirst={freezeFirstContactPerson}
            changedFrom={beforeValue?.contactPeople}
            changeReason={changeReasonVM?.contactPeople?.reason}
            onChangeReason={handleChangeReasonContactPeople}
            displayChangesOnly={displayChangesOnly}
            commentButtonProps={commentProps.contactPeople}
            commentButtonPropsChildren={commentProps.contactPeopleN}
          />

          <RequestingCellsSection
            documentType={documentType}
            editMode={editMode}
            valueResearchCells={value.requestingResearchCells}
            valueClinicalCells={value.requestingClinicalCells}
            onChangeResearchCells={handleChangeRequestingCellsSectionResearch}
            onChangeClinicalCells={handleChangeRequestingCellsSectionClinical}
            changedFromResearchCells={beforeValue?.requestingResearchCells}
            changedFromClinicalCells={beforeValue?.requestingClinicalCells}
            changeReason={changeReasonVM?.requestingCells?.reason}
            onChangeReason={handleChangeReasonRequestingCells}
            displayChangesOnly={displayChangesOnly}
            commentButtonProps={commentProps.requestingCells}
            commentButtonPropsResearchCells={
              commentProps.requestingResearchCellsN
            }
            commentButtonPropsClinicalCells={
              commentProps.requestingClinicalCellsN
            }
          />

          <IpsCellStockUsePurposeSection
            documentType={documentType}
            editMode={editMode}
            value={value.ipsCellStockUsePurpose}
            onChange={handleChangeIpsCellStockUsePurposeSection}
            changedFrom={beforeValue?.ipsCellStockUsePurpose}
            changeReason={changeReasonVM?.ipsCellStockUsePurpose?.reason}
            onChangeReason={handleChangeReasonIpsCellStockUsePurpose}
            displayChangesOnly={displayChangesOnly}
            commentButtonProps={commentProps.ipsCellStockUsePurpose}
          />

          <IpsCellStockRequirementReasonSection
            documentType={documentType}
            value={value.ipsCellStockRequirementReason}
            editMode={editMode}
            onChange={handleChangeIpsCellStockRequirementReasonSection}
            changedFrom={beforeValue?.ipsCellStockRequirementReason}
            changeReason={changeReasonVM?.ipsCellStockRequirementReason?.reason}
            onChangeReason={handleChangeReasonIpsCellStockRequirementReason}
            displayChangesOnly={displayChangesOnly}
            commentButtonProps={commentProps.ipsCellStockRequirementReason}
          />

          <PlanSummarySection
            documentType={documentType}
            editMode={editMode}
            value={value.planSummary}
            onChange={handleChangePlanSummaryFormSection}
            changedFrom={beforeValue?.planSummary}
            changeReason={changeReasonVM?.planSummary?.reason}
            onChangeReason={handleChangeReasonPlanSummary}
            displayChangesOnly={displayChangesOnly}
            commentButtonProps={commentProps.planSummary}
          />

          <HasCollaborativePartnerSection
            documentType={documentType}
            editMode={editMode}
            value={value.hasCollaborativePartner}
            onChange={handleChangeHasCollaborativePartnerSection}
            changedFrom={beforeValue?.hasCollaborativePartner}
            changeReason={changeReasonVM?.hasCollaborativePartner?.reason}
            onChangeReason={handleChangeReasonHasCollaborativePartner}
            displayChangesOnly={displayChangesOnly}
            commentButtonProps={commentProps.hasCollaborativePartner}
          />

          <UsageEndDateSection
            documentType={documentType}
            editMode={editMode}
            value={value.usageEndDate}
            onChange={handleChangeUsageEndDateSection}
            changedFrom={beforeValue?.usageEndDate}
            changeReason={changeReasonVM?.usageEndDate?.reason}
            onChangeReason={handleChangeReasonUsageEndDate}
            displayChangesOnly={displayChangesOnly}
            commentButtonProps={commentProps.usageEndDate}
          />

          <ResearchSiteSection
            documentType={documentType}
            editMode={editMode}
            value={value.researchSite}
            onChange={handleChangeResearchSiteSection}
            changedFrom={beforeValue?.researchSite}
            changeReason={changeReasonVM?.researchSite?.reason}
            onChangeReason={handleChangeReasonResearchSite}
            displayChangesOnly={displayChangesOnly}
            commentButtonProps={commentProps.researchSite}
          />

          <IpsCultureExperienceSection
            documentType={documentType}
            editMode={editMode}
            value={value.ipsCultureExperience}
            onChange={handleChangeIpsCultureExperienceSection}
            changedFrom={beforeValue?.ipsCultureExperience}
            changeReason={changeReasonVM?.ipsCultureExperience?.reason}
            onChangeReason={handleChangeReasonIpsCultureExperience}
            displayChangesOnly={displayChangesOnly}
            commentButtonProps={commentProps.ipsCultureExperience}
          />
          <EthicalApplicationStatusSection
            documentType={documentType}
            editMode={editMode}
            value={value.ethicalApplicationStatus}
            onChange={handleChangeEthicalApplicationStatusSection}
            changedFrom={beforeValue?.ethicalApplicationStatus}
            changeReason={changeReasonVM?.ethicalApplicationStatus?.reason}
            onChangeReason={handleChangeReasonEthicalApplicationStatus}
            displayChangesOnly={displayChangesOnly}
            commentButtonProps={commentProps.ethicalApplicationStatus}
          />

          <HandlingAfterUseSection
            documentType={documentType}
            editMode={editMode}
            value={value.handlingAfterUse}
            onChange={handleChangeHandlingAfterUseSection}
            changedFrom={beforeValue?.handlingAfterUse}
            changeReason={changeReasonVM?.handlingAfterUse?.reason}
            onChangeReason={handleChangeReasonHandlingAfterUse}
            displayChangesOnly={displayChangesOnly}
            commentButtonProps={commentProps.handlingAfterUse}
          />
          <FoundationContractStateSection
            documentType={documentType}
            editMode={editMode}
            value={value.foundationContractState}
            onChange={handleChangeFoundationContractStateSection}
            changedFrom={beforeValue?.foundationContractState}
            changeReason={changeReasonVM?.foundationContractState?.reason}
            onChangeReason={handleChangeReasonFoundationContractState}
            displayChangesOnly={displayChangesOnly}
            commentButtonProps={commentProps.foundationContractState}
          />
          {/*書類内リンク用のタグ*/}
          <div id={"attachmentFiles"}></div>
          <AttachmentFilesSection
            documentType={documentType}
            principalInvestigatorAppUserId={principalInvestigatorAppUserId}
            purposeType={value.ipsCellStockUsePurpose.purposeType}
            editMode={editMode}
            institutionId={institutionId ?? null}
            projectId={projectId}
            valueResearchPlanProjectName={value.researchPlanProjectName}
            valueAttachmentFiles={value.attachmentFiles}
            changedFromResearchPlanProjectName={
              beforeValue?.researchPlanProjectName
            }
            changedFromAttachmentFiles={beforeValue?.attachmentFiles}
            changeReason={changeReasonVM?.attachmentFiles?.reason}
            onChangeReason={handleChangeReasonAttachmentFiles}
            displayChangesOnly={displayChangesOnly}
            onChange={handleChangeAttachmentFilesSection}
            commentButtonProps={commentProps.attachmentFiles}
          />
          {/*書類内リンク用のタグ*/}
          <div id={"partners"}></div>
          <PartnersSection
            documentType={documentType}
            hasCollaborativePartner={
              value.hasCollaborativePartner === "has_partner"
            }
            purposeType={value.ipsCellStockUsePurpose.purposeType}
            editMode={editMode}
            institutionId={institutionId ?? null}
            projectId={projectId}
            value={value.partners}
            onChange={handleChangePartnersSection}
            changedFrom={beforeValue?.partners}
            changeReason={changeReasonVM?.partners}
            onChangeReason={handleChangeReasonPartners}
            displayChangesOnly={displayChangesOnly}
            commentButtonProps={commentProps.partners}
            commentButtonPropsChildren={commentProps.partnersN}
          />

          {documentType === "new_application" && (
            <CheckListSection
              editMode={editMode}
              valueChecklistItem1={value.checklistItem1}
              valueChecklistItem2={value.checklistItem2}
              valueChecklistItem3={value.checklistItem3}
              valueChecklistItem4={value.checklistItem4}
              valueChecklistItem5={value.checklistItem5}
              valueChecklistItem6={value.checklistItem6}
              onChange={handleChangeChecklistSection}
              commentButtonProps={commentProps.checklists}
            />
          )}
          {(documentType === "new_application" ||
            documentType === "change_application") && (
            <SignatureSection
              principalInvestigatorName={value.principalInvestigator?.fullName}
              editMode={editMode}
              valueCorrectnessCheck={signatureValue?.correctnessCheck}
              valueCheckDate={signatureValue?.checkDate}
              onCheck={handleChangeSignatureSection}
            />
          )}
        </VStack>
      </VStack>
    </Container>
  );
});

export type ApplicationContentViewModel = Omit<
  ApplicationContent,
  | "institution"
  | "contactPeople"
  | "requestingResearchCells"
  | "requestingClinicalCells"
> & {
  institution: Partial<InstitutionInformation>;
  contactPeople: UserInformationKeyedViewModel[];
  requestingResearchCells: RequestingCellViewModel[];
  requestingClinicalCells: RequestingCellViewModel[];
};

export type UserInformationKeyedViewModel = SetRequired<
  Partial<UserInformationKeyed>,
  "key"
>;
export type RequestingCellViewModel = SetRequired<
  Partial<RequestingCell>,
  "key"
>;

function useChangeHandlers(
  onChange?: (
    change: (
      before: ApplicationContentViewModel,
    ) => ApplicationContentViewModel,
  ) => void,
  onChangeSignatureValue?: (
    change: (before: SignatureValue) => SignatureValue,
  ) => void,
) {
  const handleChange = useCallback(
    (
      change: (
        before: ApplicationContentViewModel,
      ) => ApplicationContentViewModel,
    ) => {
      onChange?.(change);
    },
    [onChange],
  );

  const handleChangeProjectNameFormSection = useCallback(
    (v: string) =>
      handleChange((before) => ({
        ...before,
        projectName: v,
      })),
    [handleChange],
  );
  const handleChangeInstitutionNameSection = useCallback(
    (v: Partial<InstitutionInformation>) =>
      handleChange((before) => ({
        ...before,
        institution: v,
      })),
    [handleChange],
  );
  const handleChangePrincipalInvestigatorSection = useCallback(
    (v: UserInformation | null) =>
      handleChange((before) => ({
        ...before,
        principalInvestigator: v ?? undefined,
      })),
    [handleChange],
  );
  const handleChangeContactPeopleSection = useCallback(
    (
      change: (
        before: UserInformationKeyedViewModel[],
      ) => UserInformationKeyedViewModel[],
    ) =>
      handleChange((before) => ({
        ...before,
        contactPeople: change(before.contactPeople),
      })),
    [handleChange],
  );
  const handleChangeRequestingCellsSectionResearch = useCallback(
    (
      change: (before: RequestingCellViewModel[]) => RequestingCellViewModel[],
    ) =>
      handleChange((before) => ({
        ...before,
        requestingResearchCells: change(before.requestingResearchCells),
      })),
    [handleChange],
  );
  const handleChangeRequestingCellsSectionClinical = useCallback(
    (
      change: (before: RequestingCellViewModel[]) => RequestingCellViewModel[],
    ) =>
      handleChange((before) => ({
        ...before,
        requestingClinicalCells: change(before.requestingClinicalCells),
      })),
    [handleChange],
  );
  const handleChangeIpsCellStockUsePurposeSection = useCallback(
    (v: IpsCellStockUsePurpose) =>
      handleChange((before) => ({
        ...before,
        ipsCellStockUsePurpose: v,
      })),
    [handleChange],
  );
  const handleChangeIpsCellStockRequirementReasonSection = useCallback(
    (v: { reasonType: "risk_reduction" | "other"; other?: string }) =>
      handleChange((before) => ({
        ...before,
        ipsCellStockRequirementReason: v,
      })),
    [handleChange],
  );
  const handleChangePlanSummaryFormSection = useCallback(
    (v: string) =>
      handleChange((before) => ({
        ...before,
        planSummary: v,
      })),
    [handleChange],
  );
  const handleChangeHasCollaborativePartnerSection = useCallback(
    (v: HasCollaborativePartner) =>
      handleChange((before) => ({
        ...before,
        hasCollaborativePartner: v,
        partners: v === "no_partner" ? [] : before.partners,
      })),
    [handleChange],
  );
  const handleChangeUsageEndDateSection = useCallback(
    (v: YearMonthDay) =>
      handleChange((before) => ({
        ...before,
        usageEndDate: v,
      })),
    [handleChange],
  );
  const handleChangeResearchSiteSection = useCallback(
    (v: string) =>
      handleChange((before) => ({
        ...before,
        researchSite: v,
      })),
    [handleChange],
  );
  const handleChangeIpsCultureExperienceSection = useCallback(
    (v: IpsCultureExperience) =>
      handleChange((before) => ({
        ...before,
        ipsCultureExperience: v,
      })),
    [handleChange],
  );
  const handleChangeEthicalApplicationStatusSection = useCallback(
    (change: (before: EthicalApplicationStatus) => EthicalApplicationStatus) =>
      handleChange((before) => ({
        ...before,
        ethicalApplicationStatus: change(before.ethicalApplicationStatus),
      })),
    [handleChange],
  );
  const handleChangeHandlingAfterUseSection = useCallback(
    (v: HandlingAfterUse) =>
      handleChange((before) => ({
        ...before,
        handlingAfterUse: v,
      })),
    [handleChange],
  );
  const handleChangeFoundationContractStateSection = useCallback(
    (v: CollaborativeResearchContractStateWithFoundation) =>
      handleChange((before) => ({
        ...before,
        foundationContractState: v,
      })),
    [handleChange],
  );
  const handleChangeAttachmentFilesSection = useCallback(
    (
      change: (
        before: AttachmentFilesSectionViewModel,
      ) => AttachmentFilesSectionViewModel,
    ) =>
      handleChange((before) => {
        const updated = change({
          researchPlanProjectName: before.researchPlanProjectName,
          attachmentFiles: before.attachmentFiles,
        });
        return {
          ...before,
          ...updated,
        };
      }),
    [handleChange],
  );
  const handleChangePartnersSection = useCallback(
    (change: (before: CollaborativePartner[]) => CollaborativePartner[]) =>
      handleChange((before) => ({
        ...before,
        partners: change(before.partners),
      })),
    [handleChange],
  );
  const handleChangeChecklistSection = useCallback(
    (v: {
      checklistItem1: "checked" | "unchecked";
      checklistItem2: "checked" | "unchecked";
      checklistItem3: "checked" | "unchecked";
      checklistItem4: "checked" | "unchecked";
      checklistItem5: "checked" | "unchecked";
      checklistItem6: "checked" | "unchecked";
    }) => {
      handleChange((before) => ({
        ...before,
        ...v,
      }));
    },
    [handleChange],
  );
  const handleChangeSignatureSection = useCallback(
    (v: SignatureValue) =>
      onChangeSignatureValue?.((_before) => ({
        correctnessCheck: v.correctnessCheck,
        checkDate: v.checkDate,
      })),
    [onChangeSignatureValue],
  );

  return {
    handleChangeProjectNameFormSection,
    handleChangeInstitutionNameSection,
    handleChangePrincipalInvestigatorSection,
    handleChangeContactPeopleSection,
    handleChangeRequestingCellsSectionResearch,
    handleChangeRequestingCellsSectionClinical,
    handleChangeIpsCellStockUsePurposeSection,
    handleChangeIpsCellStockRequirementReasonSection,
    handleChangePlanSummaryFormSection,
    handleChangeHasCollaborativePartnerSection,
    handleChangeUsageEndDateSection,
    handleChangeResearchSiteSection,
    handleChangeIpsCultureExperienceSection,
    handleChangeEthicalApplicationStatusSection,
    handleChangeHandlingAfterUseSection,
    handleChangeFoundationContractStateSection,
    handleChangeAttachmentFilesSection,
    handleChangePartnersSection,
    handleChangeChecklistSection,
    handleChangeSignatureSection,
  };
}

function useChangeReasonHandlers(
  onChangeReason?: (
    change: (before: ChangeReasonViewModel) => ChangeReasonViewModel,
  ) => void,
) {
  const handleChangeReason = useCallback(
    (change: (before: ChangeReasonViewModel) => ChangeReasonViewModel) => {
      onChangeReason?.(change);
    },
    [onChangeReason],
  );

  const handleChangeReasonProjectName = useCallback(
    (
      change: (before: ChangeReasonItemViewModel) => ChangeReasonItemViewModel,
    ) =>
      handleChangeReason((before) => ({
        ...before,
        projectName: change(before.projectName),
      })),
    [handleChangeReason],
  );
  const handleChangeReasonInstitutionName = useCallback(
    (
      change: (before: ChangeReasonItemViewModel) => ChangeReasonItemViewModel,
    ) =>
      handleChangeReason((before) => ({
        ...before,
        institutionName: change(before.institutionName),
      })),
    [handleChangeReason],
  );
  const handleChangeReasonPrincipalInvestigator = useCallback(
    (
      change: (before: ChangeReasonItemViewModel) => ChangeReasonItemViewModel,
    ) =>
      handleChangeReason((before) => ({
        ...before,
        principalInvestigator: change(before.principalInvestigator),
      })),
    [handleChangeReason],
  );
  const handleChangeReasonContactPeople = useCallback(
    (
      change: (before: ChangeReasonItemViewModel) => ChangeReasonItemViewModel,
    ) =>
      handleChangeReason((before) => ({
        ...before,
        contactPeople: change(before.contactPeople),
      })),
    [handleChangeReason],
  );
  const handleChangeReasonRequestingCells = useCallback(
    (
      change: (before: ChangeReasonItemViewModel) => ChangeReasonItemViewModel,
    ) =>
      handleChangeReason((before) => ({
        ...before,
        requestingCells: change(before.requestingCells),
      })),
    [handleChangeReason],
  );
  const handleChangeReasonIpsCellStockUsePurpose = useCallback(
    (
      change: (before: ChangeReasonItemViewModel) => ChangeReasonItemViewModel,
    ) =>
      handleChangeReason((before) => ({
        ...before,
        ipsCellStockUsePurpose: change(before.ipsCellStockUsePurpose),
      })),
    [handleChangeReason],
  );
  const handleChangeReasonIpsCellStockRequirementReason = useCallback(
    (
      change: (before: ChangeReasonItemViewModel) => ChangeReasonItemViewModel,
    ) =>
      handleChangeReason((before) => ({
        ...before,
        ipsCellStockRequirementReason: change(
          before.ipsCellStockRequirementReason,
        ),
      })),
    [handleChangeReason],
  );
  const handleChangeReasonPlanSummary = useCallback(
    (
      change: (before: ChangeReasonItemViewModel) => ChangeReasonItemViewModel,
    ) =>
      handleChangeReason((before) => ({
        ...before,
        planSummary: change(before.planSummary),
      })),
    [handleChangeReason],
  );
  const handleChangeReasonHasCollaborativePartner = useCallback(
    (
      change: (before: ChangeReasonItemViewModel) => ChangeReasonItemViewModel,
    ) =>
      handleChangeReason((before) => ({
        ...before,
        hasCollaborativePartner: change(before.hasCollaborativePartner),
      })),
    [handleChangeReason],
  );
  const handleChangeReasonUsageEndDate = useCallback(
    (
      change: (before: ChangeReasonItemViewModel) => ChangeReasonItemViewModel,
    ) =>
      handleChangeReason((before) => ({
        ...before,
        usageEndDate: change(before.usageEndDate),
      })),
    [handleChangeReason],
  );
  const handleChangeReasonResearchSite = useCallback(
    (
      change: (before: ChangeReasonItemViewModel) => ChangeReasonItemViewModel,
    ) =>
      handleChangeReason((before) => ({
        ...before,
        researchSite: change(before.researchSite),
      })),
    [handleChangeReason],
  );
  const handleChangeReasonIpsCultureExperience = useCallback(
    (
      change: (before: ChangeReasonItemViewModel) => ChangeReasonItemViewModel,
    ) =>
      handleChangeReason((before) => ({
        ...before,
        ipsCultureExperience: change(before.ipsCultureExperience),
      })),
    [handleChangeReason],
  );
  const handleChangeReasonEthicalApplicationStatus = useCallback(
    (
      change: (before: ChangeReasonItemViewModel) => ChangeReasonItemViewModel,
    ) =>
      handleChangeReason((before) => ({
        ...before,
        ethicalApplicationStatus: change(before.ethicalApplicationStatus),
      })),
    [handleChangeReason],
  );
  const handleChangeReasonHandlingAfterUse = useCallback(
    (
      change: (before: ChangeReasonItemViewModel) => ChangeReasonItemViewModel,
    ) =>
      handleChangeReason((before) => ({
        ...before,
        handlingAfterUse: change(before.handlingAfterUse),
      })),
    [handleChangeReason],
  );
  const handleChangeReasonFoundationContractState = useCallback(
    (
      change: (before: ChangeReasonItemViewModel) => ChangeReasonItemViewModel,
    ) =>
      handleChangeReason((before) => ({
        ...before,
        foundationContractState: change(before.foundationContractState),
      })),
    [handleChangeReason],
  );
  const handleChangeReasonAttachmentFiles = useCallback(
    (
      change: (before: ChangeReasonItemViewModel) => ChangeReasonItemViewModel,
    ) =>
      handleChangeReason((before) => ({
        ...before,
        attachmentFiles: change(before.attachmentFiles),
      })),
    [handleChangeReason],
  );
  const handleChangeReasonPartners = useCallback(
    (
      change: (
        before: { key: number; value: ChangeReasonItemViewModel }[],
      ) => { key: number; value: ChangeReasonItemViewModel }[],
    ) =>
      handleChangeReason((before) => ({
        ...before,
        partners: change(before.partners),
      })),
    [handleChangeReason],
  );

  return {
    handleChangeReasonProjectName,
    handleChangeReasonInstitutionName,
    handleChangeReasonPrincipalInvestigator,
    handleChangeReasonContactPeople,
    handleChangeReasonRequestingCells,
    handleChangeReasonIpsCellStockUsePurpose,
    handleChangeReasonIpsCellStockRequirementReason,
    handleChangeReasonPlanSummary,
    handleChangeReasonHasCollaborativePartner,
    handleChangeReasonUsageEndDate,
    handleChangeReasonResearchSite,
    handleChangeReasonIpsCultureExperience,
    handleChangeReasonEthicalApplicationStatus,
    handleChangeReasonHandlingAfterUse,
    handleChangeReasonFoundationContractState,
    handleChangeReasonAttachmentFiles,
    handleChangeReasonPartners,
  };
}
