import { CMFrameSection } from "@pscsrvlab/psc-react-components";
import { HStack, Text } from "@chakra-ui/react";

import { memo } from "react";
import { CMButtonFormCommentProps } from "@pscsrvlab/psc-react-components/src/components/form/comment";
import { ComponentStyleProps } from "../../../../../../../../lib/styles/props/component-style-props";
import { SectionContentFrame } from "../../../../../application/_components/ApplicationDocument/_components/frame/SectionContentFrame/SectionContentFrame";
import { ymdToDateText } from "../../../../../../../../lib/util/common-date-util";
import { hasValue } from "../../../../../../../../lib/util/common-util";
import { useAppTranslation } from "../../../../../../../../hooks/use-app-translation";

export type NewApplicationApprovalDateSectionProps = {
  value: Partial<{ year: number; month: number; day: number }> | undefined;

  /**
   * コメントボタンのprops。
   * これが存在すれば、コメントボタンを表示する。
   */
  commentButtonProps?: CMButtonFormCommentProps;
} & ComponentStyleProps;

/**
 * 使用期間セクション
 */
export const NewApplicationApprovalDateTerminationReportSection = memo(
  function UsageEndDateSection({
    value,
    commentButtonProps,

    sx,
    ...rest
  }: NewApplicationApprovalDateSectionProps) {
    const { t } = useAppTranslation();

    return (
      <CMFrameSection
        title={t("lbl.承認年月日")}
        commentButtonProps={commentButtonProps}
        sx={{ w: "100%", ...sx }}
        {...rest}
      >
        <SectionContentFrame>
          <HStack spacing={"20px"} mt={"10px"}>
            {hasValue(value) &&
              hasValue(value?.year) &&
              hasValue(value?.month) &&
              hasValue(value?.day) && (
                <Text>
                  {ymdToDateText({
                    year: value.year,
                    month: value.month,
                    day: value.day,
                  })}
                </Text>
              )}
          </HStack>
        </SectionContentFrame>
      </CMFrameSection>
    );
  },
);
