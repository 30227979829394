import log from "loglevel";
import { isNullish } from "../lib/util/common-util";
import useCustomToast from "./use-custom-toast";
import { useAppTranslation } from "./use-app-translation";
import { Auth } from "@aws-amplify/auth";

const useFileDownload = () => {
  const { t } = useAppTranslation();

  // 接続先情報
  const baseUrl = process.env.REACT_APP_BACKEND_API_BASE_URL;

  // トースト設定
  const { errorToast } = useCustomToast();

  /**
   * ファイルダウンロードを行う
   * @param fileId ストレージファイルID
   * @param fileName ファイル名
   */
  const fileDownload = async (fileId: string | number, fileName: string) => {
    const errorMessage = t("mes.ファイルダウンロードエラーメッセージ");

    const accessToken = await Auth.currentSession()
      .then((session) => {
        return session.getAccessToken().getJwtToken();
      })
      .catch(() => {
        return undefined;
      });

    log.debug("useFileDownload", `fileId=${fileId}`, `fileName=${fileName}`);

    // 接続情報が設定されていない場合は処理を行わない
    if (isNullish(accessToken) || isNullish(baseUrl)) {
      log.error("useFileDownload", "settings error");
      log.debug(
        "useFileDownload",
        "settings error",
        `accessToken=${accessToken}`,
        `baseUrl=${baseUrl}`,
      );
      errorToast(errorMessage);
      return;
    }

    try {
      // ファイルダウンロードAPIの呼び出し
      const response = await fetch(
        `${baseUrl}/storage-files/${fileId}:download`,
        {
          method: "GET",
          headers: new Headers({
            Authorization: `Bearer ${accessToken}`,
          }),
        },
      );

      // レスポンスがNGの場合は処理を行わない
      if (!response.ok) {
        errorToast(errorMessage);
        log.error("useFileDownload", `response status=${response.status}`);

        return;
      }

      const blob = await response.blob();

      // ダウンロードリンクを作成
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName); //or any other extension

      // ダウンロード開始
      document.body.appendChild(link);
      link.click();

      // 使用済みのダウンロードリンクを削除
      link.parentNode?.removeChild(link);
    } catch (e) {
      log.error(`error=${e}`);
      errorToast(errorMessage);
    }
  };

  return { fileDownload };
};
export default useFileDownload;
