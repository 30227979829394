import { Container, Flex, HStack, VStack } from "@chakra-ui/react";
import {
  CMFormFileUpload,
  CMMessageInfo,
} from "@pscsrvlab/psc-react-components";
import { FrameUpperRightButton } from "../../../ui/frame/FrameUpperRightButton/FrameUpperRightButton";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FileUploadSectionContent } from "@pscsrvlab/psc-react-components/src/components/form/control/FormFileUpload/types";
import { hasValue, isNullish } from "../../../../lib/util/common-util";
import log from "loglevel";
import {
  CloseResult,
  PromiseModalOptionsCancel,
  PromiseModalOptionsCancelProps,
} from "../../../ui/modal/PromiseModalOptionsCancel/PromiseModalOptionsCancel";
import { UploadButtonEmpty } from "../../../ui/button/UploadButtonEmpty/UploadButtonEmpty";
import { FileUploadItemContent } from "@pscsrvlab/psc-react-components/src/components/file-upload/types";
import useFileUpload from "../../../../hooks/use-file-upload";
import useFileDownload from "../../../../hooks/use-file-download";
import { stockRequestApi } from "../../../../store/api/enhanced-api";
import { AttachmentFileInformation } from "../../../../lib/object/value/attachment-file-information";
import { attachmentFileToFileUploadItem } from "../../../../lib/util/app-util";
import { useAppGetProjectQuery } from "../../../../hooks/query/use-app-get-project-query";
import {
  DisclosureDocuments,
  disclosureDocumentsMeta,
} from "../../../../lib/object/value/disclosure-documents";
import { useCreateJsonPatchOperations } from "../../../../hooks/use-create-json-patch-operations";
import { EditButton } from "../../../ui/button/EditButton/EditButton";
import { EditEndButton } from "../../../ui/button/EditEndButton/EditEndButton";
import { SaveChangesButton } from "../../../ui/button/SaveChangesButton/SaveChangesButton";
import { useAppTranslation } from "../../../../hooks/use-app-translation";
import { FormSection } from "../../../ui/form/FormSection/FormSection";
import { errorMessageOf } from "../../../../lib/util/error-util";
import useCustomToast from "../../../../hooks/use-custom-toast";
import { useLeaveEditingPrompt } from "../../../../hooks/use-leave-editing-prompt";

export type ProjectContentDisclosureDocumentProps = {
  projectId: number;

  /**
   * 画面編集モード
   */
  initialEditMode?: "editable" | "readOnly";
};

/**
 * FN30-S08情報公開文書
 */
export const ProjectContentDisclosureDocument = ({
  projectId,
  initialEditMode = "readOnly",
}: ProjectContentDisclosureDocumentProps) => {
  const { t } = useAppTranslation();
  const { errorToast } = useCustomToast();

  // 案件に保存済の添付ファイル一覧
  const [savedFileList, setSavedFileList] = useState<
    AttachmentFileInformation[] | null
  >(null);

  // 案件
  const { data: project } = useAppGetProjectQuery(
    { projectId },
    { skip: hasValue(savedFileList) },
  );

  // 画面モード
  const [editMode, setEditMode] = useState<"editable" | "readOnly">(
    initialEditMode,
  );

  useLeaveEditingPrompt({ skip: editMode === "readOnly" });

  const { fileUpload } = useFileUpload();
  const { fileDownload } = useFileDownload();

  // 表示中・編集中の添付ファイル一覧
  const [fileList, setFileList] = useState<AttachmentFileInformation[]>([]);

  // 情報公開文書保存
  const [updateDisclosureDocument] =
    stockRequestApi.useUpdateDisclosureDocumentMutation();

  useEffect(() => {
    // 初期化済なら何もしない。
    if (hasValue(savedFileList)) return;

    // 案件が取得できていなければ何もしない。
    if (isNullish(project)) return;

    const files = project.disclosureDocument.files;

    setFileList(files);
    setSavedFileList(files);
  }, [project, savedFileList]);

  const content: FileUploadSectionContent = useMemo(() => {
    const fileItems: FileUploadItemContent[] =
      fileList.map((v) => attachmentFileToFileUploadItem(t, v)) ?? [];

    const displayContent: FileUploadSectionContent = {
      id: "disclosure_document",
      title: "",
      subSectionContents: [
        {
          editMode,
          variant: "default",
          id: "disclosure_document_0",
          title: t("lbl.情報公開文書"),
          childrenButton: <UploadButtonEmpty />,
          files: fileItems,
        },
      ],
    };

    return displayContent;
  }, [fileList, editMode, t]);

  /**
   * ファイルアップロード処理
   */
  const handleAddFile = useCallback(
    async (_sectionId: string, _subSectionId: string, file: File) => {
      const response = await fileUpload(file, "public", null, projectId);

      if (isNullish(response)) {
        log.error("ファイルアップロードに失敗しました。");
        return;
      }

      // 内部のファイル一覧を更新
      const newFile: AttachmentFileInformation = {
        storageFileId: response.id,
        attachmentFileType: "disclosure_document",
        attachmentFileName: file.name,
        uploadedAt: response.created.datetime,
      };
      setFileList((before) => [...before, newFile]);
    },
    [fileUpload, projectId],
  );

  /**
   * ファイルダウンロード処理
   */
  const handleDownloadFile = useCallback(
    (fileId: string, fileName: string) => {
      // ダウンロード処理の呼び出し
      void fileDownload(fileId, fileName);
    },
    [fileDownload],
  );

  // 削除確認モーダルの表示制御設定
  const [deleteConfirmModalConfig, setDeleteConfirmModalConfig] =
    useState<PromiseModalOptionsCancelProps>();

  /**
   * 添付ファイル削除時に呼ばれる。
   */
  const handleDeleteFile = useCallback(
    async (_sectionId: string, _subSectionId: string, fileId: string) => {
      const result = await new Promise<CloseResult>((resolve) => {
        setDeleteConfirmModalConfig({
          onCloseModal: resolve,
        });
      });
      setDeleteConfirmModalConfig(undefined);
      if (result !== "OK") return;

      // 削除処理
      setFileList((before) =>
        before.filter((value) => value.storageFileId.toString(10) !== fileId),
      );
    },
    [],
  );

  /**
   * 編集ボタンイベント
   */
  const handleEdit = useCallback(() => {
    if (isNullish(savedFileList)) return;

    // 編集モードに切り替え
    setEditMode("editable");
  }, [savedFileList]);

  // 保存確認モーダルの表示制御設定
  const [saveModalConfig, setSaveModalConfig] =
    useState<PromiseModalOptionsCancelProps>();

  const { createJsonPatchOperations } = useCreateJsonPatchOperations(
    disclosureDocumentsMeta,
  );

  /**
   * 変更を保存するボタンイベント
   */
  const handleSave = useCallback(async () => {
    if (isNullish(savedFileList)) return;

    // 確認モーダルの表示
    const result = await new Promise<CloseResult>((resolve) => {
      setSaveModalConfig({
        onCloseModal: resolve,
      });
    });

    // モーダルを非表示にする
    setSaveModalConfig(undefined);

    if (result !== "OK") return;

    // 参照モードに切り替え
    setEditMode("readOnly");

    const disclosureDocumentsBefore: DisclosureDocuments = {
      files: savedFileList,
    };
    const disclosureDocumentsAfter: DisclosureDocuments = {
      files: fileList,
    };
    const jsonPatchOperations = createJsonPatchOperations(
      disclosureDocumentsBefore,
      disclosureDocumentsAfter,
    );

    try {
      await updateDisclosureDocument({
        projectId: projectId,
        commonUpdateRequest: {
          patchOperations: jsonPatchOperations,
        },
      }).unwrap();
    } catch (e) {
      log.error(errorMessageOf(e));
      errorToast(t("mes.汎用エラーメッセージ"));
    }

    // 保存済ファイルリストを更新
    setSavedFileList(fileList);
  }, [
    createJsonPatchOperations,
    errorToast,
    fileList,
    projectId,
    savedFileList,
    t,
    updateDisclosureDocument,
  ]);

  // 編集モード終了確認モーダルの表示制御設定
  const [editCancelModalConfig, setEditCancelModalConfig] =
    useState<PromiseModalOptionsCancelProps>();

  /**
   * 編集終了ボタンイベント
   */
  const handleEditCancel = useCallback(async () => {
    // 確認モーダルの表示
    const result = await new Promise<CloseResult>((resolve) => {
      setEditCancelModalConfig({
        onCloseModal: resolve,
      });
    });

    // モーダルを非表示にする
    setEditCancelModalConfig(undefined);
    log.debug("result", result);

    if (result === "OK") {
      // 添付ファイルを初期化
      setFileList(savedFileList ?? []);

      // 参照モードに切り替え
      setEditMode("readOnly");
    }
  }, [savedFileList]);

  return (
    <>
      <VStack
        flex={"1 1 auto"}
        minH={0}
        alignSelf={"stretch"}
        alignItems={"stretch"}
        overflow={"auto"}
        pb={"40px"}
        className={"ProjectContentDisclosureDocument"}
      >
        {/*ヘッダエリア*/}
        <Flex
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-between"}
        >
          <Flex mt={"20px"} ml={"20px"} mr={"20px"}>
            <CMMessageInfo
              size={"sm"}
              label={t("gdc.情報公開文書ダウンロード案内")}
            />
          </Flex>
          <FrameUpperRightButton minW={"max-content"}>
            {editMode === "readOnly" ? (
              // 参照モード
              <EditButton onClick={handleEdit} />
            ) : (
              // 編集モード
              <HStack>
                <SaveChangesButton onClick={handleSave} />
                <EditEndButton onClick={handleEditCancel} />
              </HStack>
            )}
          </FrameUpperRightButton>
        </Flex>
        {/*ファイルアップロード*/}
        <VStack>
          <Container pt={"24px"} pb={"50px"} minW={"400px"} maxW={"720px"}>
            <VStack alignItems={"stretch"}>
              <FormSection title={t("lbl.情報公開文書")}>
                <CMFormFileUpload
                  value={content}
                  onAddFile={handleAddFile}
                  onDeleteFile={handleDeleteFile}
                  onDownloadFile={handleDownloadFile}
                />
              </FormSection>
            </VStack>
          </Container>
        </VStack>
      </VStack>

      {/*変更保存確認メッセージ*/}
      {saveModalConfig && (
        <PromiseModalOptionsCancel
          labelBody={t("mes.変更保存確認メッセージ")}
          onCloseModal={saveModalConfig.onCloseModal}
        />
      )}
      {/*変更内容破棄メッセージ*/}
      {editCancelModalConfig && (
        <PromiseModalOptionsCancel
          labelBody={t("mes.変更内容破棄メッセージ")}
          onCloseModal={editCancelModalConfig.onCloseModal}
        />
      )}
      {/*添付ファイル削除確認メッセージ*/}
      {deleteConfirmModalConfig && (
        <PromiseModalOptionsCancel
          labelBody={t("mes.添付資料削除確認メッセージ")}
          onCloseModal={deleteConfirmModalConfig.onCloseModal}
        />
      )}
    </>
  );
};
