import {
  Container,
  Flex,
  HStack,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { FrameUpperRightButton } from "../../../ui/frame/FrameUpperRightButton/FrameUpperRightButton";
import {
  CMExtendedMenu,
  DeleteConfirmationModal,
} from "@pscsrvlab/psc-react-components";
import React, { useCallback, useMemo, useState } from "react";
import log from "loglevel";
import { SectionShippingInformation } from "./_components/SectionShippingInformation/SectionShippingInformation";
import { cellStockDeliveryMeta } from "../../../../lib/object/entity/cell-stock-delivery";
import { SectionComment } from "./_components/SectionComment/SectionComment";
import { SectionDeliveryDate } from "./_components/SectionDeliveryDate/SectionDeliveryDate";
import { CellStockDeliveryAttachmentFilesSection } from "./_components/SectionAttachmentFiles/CellStockDeliveryAttachmentFilesSection";
import { SectionShippingDestination } from "./_components/SectionShippingDestination/SectionShippingDestination";
import { SectionShippingContainers } from "./_components/SectionShippingContainers/SectionShippingContainers";
import { SectionDeliverer } from "./_components/SectionDeliverer/SectionDeliverer";
import { SectionReceiptInformation } from "./_components/SectionReceiptInformation/SectionReceiptInformation";
import { freeTextMeta } from "../../../../lib/object/value/free-text";
import { errorMessageOf } from "../../../../lib/util/error-util";
import useCustomToast from "../../../../hooks/use-custom-toast";
import { getDateTimeStr } from "../../../../lib/util/common-date-util";
import { useCreateJsonPatchOperations } from "../../../../hooks/use-create-json-patch-operations";
import { SectionShippingCell } from "./_components/SectionShippingCell/SectionShippingCell";
import { ShippingCell } from "../../../../lib/object/value/shipping-cell";
import { useSelector } from "react-redux";
import { selectHasRole } from "../../../../store/auth/slice";
import {
  useDeleteCellStockDeliveryMutation,
  useUpdateCellStockDeliveryMutation,
} from "../../../../store/api/generated/stock-request-api";
import { useAppTranslation } from "../../../../hooks/use-app-translation";
import { useLeaveEditingPrompt } from "../../../../hooks/use-leave-editing-prompt";
import { YearMonthDay } from "../../../../lib/object/value/year-month-day";
import { ReceiptInformation } from "../../../../lib/object/value/receipt-information";
import { EditButton } from "../../../ui/button/EditButton/EditButton";
import { SaveChangesButton } from "../../../ui/button/SaveChangesButton/SaveChangesButton";
import { EditEndButton } from "../../../ui/button/EditEndButton/EditEndButton";
import { TitleText } from "../../../ui/text/TitleText/TitleText";
import { WeakTextItem } from "../../../ui/text/WeakTextItem/WeakTextItem";
import { ConfirmationModal } from "../../../ui/modal/ConfirmationModal/ConfirmationModal";
import { ShippingInformation } from "../../../../lib/object/value/shipping-information";
import { ShippingDestination } from "../../../../lib/object/value/shipping-destination";
import { AttachmentFileInformation } from "../../../../lib/object/value/attachment-file-information";
import {
  cellStockDeliveryFromViewModel,
  CellStockDeliveryViewModel,
} from "../../../../lib/object/vm/cell-stock-delivery-view-model";
import { PickupDestination } from "../../../../lib/object/value/pickup-destination";

export type CellStockDeliveryProps = {
  initialVM: CellStockDeliveryViewModel;

  /**
   * 初期表示時の画面編集モード
   * ※ただし、ロールが適切でなければ強制的にreadOnlyになる。
   */
  initialEditMode?: "editable" | "readOnly";
};

/**
 * FN30-S09細胞発送管理
 */
export const CellStockDeliveryPage = ({
  initialVM,

  initialEditMode = "readOnly",
}: CellStockDeliveryProps) => {
  const { t } = useAppTranslation();
  const { successToast, errorToast, validationErrorToast } = useCustomToast();
  const { isOfficeMember } = useSelector(selectHasRole);

  // 画面モード
  const [editMode, setEditMode] = useState<"editable" | "readOnly">(
    isOfficeMember ? initialEditMode : "readOnly",
  );

  const { navigateWithoutPrompt } = useLeaveEditingPrompt({
    skip: editMode === "readOnly",
  });

  const [savedVM, setSavedVM] = useState<CellStockDeliveryViewModel>(initialVM);
  const [vm, setVm] = useState<CellStockDeliveryViewModel>(initialVM);

  // 細胞発送情報の更新
  const [updateCellStockDelivery] = useUpdateCellStockDeliveryMutation();

  // 細胞発送情報の削除
  const [deleteCellStockDelivery] = useDeleteCellStockDeliveryMutation();

  const handleChange = useCallback(
    (
      change: (
        before: CellStockDeliveryViewModel,
      ) => CellStockDeliveryViewModel,
    ) => {
      setVm(change);
    },
    [],
  );

  const createdDatetime = useMemo(() => {
    return getDateTimeStr(vm.created.datetime);
  }, [vm.created.datetime]);
  const updatedDatetime = useMemo(() => {
    return getDateTimeStr(vm.updated.datetime);
  }, [vm.updated.datetime]);

  // 保存確認モーダルの表示制御設定
  const {
    isOpen: isOpenSaveModal,
    onOpen: onOpenSaveModal,
    onClose: onCloseSaveModal,
  } = useDisclosure();

  // キャンセルモーダルの表示制御設定
  const {
    isOpen: isOpenCancelModal,
    onOpen: onOpenCancelModal,
    onClose: onCloseCancelModal,
  } = useDisclosure();

  // 削除確認モーダルの表示制御設定
  const {
    isOpen: isOpenDeleteModal,
    onOpen: onOpenDeleteModal,
    onClose: onCloseDeleteModal,
  } = useDisclosure();

  /**
   * 編集ボタンイベント
   */
  const handleEdit = useCallback(() => {
    // 参照モードに切り替え
    setEditMode("editable");
  }, []);

  /**
   * 編集終了ボタンイベント
   */
  const handleEditCancel = useCallback(() => {
    onCloseCancelModal();
    setVm(savedVM);
    setEditMode("readOnly");
  }, [onCloseCancelModal, savedVM]);

  const { createJsonPatchOperations } = useCreateJsonPatchOperations(
    cellStockDeliveryMeta,
  );

  /**
   * 変更を保存するボタンイベント
   */
  const handleSave = useCallback(async () => {
    onCloseSaveModal();

    const newCellStockDelivery = cellStockDeliveryFromViewModel(vm);
    const validationState =
      cellStockDeliveryMeta.validate(newCellStockDelivery);
    if (validationState.state === "error") {
      validationErrorToast(validationState.errors, [
        "shippingResearchCells.#.cellStockId",
        "shippingResearchCells.#.cellType",
        "shippingResearchCells.#.cellStockCategoryId",
        "shippingResearchCells.#.cellCategoryNumber",
        "shippingResearchCells.#.cellCategoryNameJa",
        "shippingResearchCells.#.cellCategoryNameEn",
        "shippingResearchCells.#.cellNameJa",
        "shippingResearchCells.#.cellNameEn",

        "shippingClinicalCells.#.cellStockId",
        "shippingClinicalCells.#.cellType",
        "shippingClinicalCells.#.cellStockCategoryId",
        "shippingClinicalCells.#.cellCategoryNumber",
        "shippingClinicalCells.#.cellCategoryNameJa",
        "shippingClinicalCells.#.cellCategoryNameEn",
        "shippingClinicalCells.#.cellNameJa",
        "shippingClinicalCells.#.cellNameEn",
      ]);
      return;
    }

    const savedCellStockDelivery = cellStockDeliveryFromViewModel(savedVM);
    const jsonPatchOperations = createJsonPatchOperations(
      savedCellStockDelivery,
      newCellStockDelivery,
    );

    try {
      await updateCellStockDelivery({
        cellStockDeliveryId: newCellStockDelivery.id,
        commonUpdateRequest: {
          patchOperations: jsonPatchOperations,
        },
      }).unwrap();

      successToast(t("mes.細胞発送更新成功メッセージ"));
      setSavedVM(vm);
      setEditMode("readOnly");
    } catch (e) {
      errorToast(t("mes.細胞発送更新失敗エラー"));
      log.error(errorMessageOf(e));
    }
  }, [
    createJsonPatchOperations,
    errorToast,
    onCloseSaveModal,
    savedVM,
    successToast,
    t,
    updateCellStockDelivery,
    validationErrorToast,
    vm,
  ]);

  /**
   * 削除確認ダイアログの実行時イベント
   */
  const handleConfirmDelete = useCallback(async () => {
    onCloseDeleteModal();
    try {
      await deleteCellStockDelivery({
        cellStockDeliveryId: vm.id,
      }).unwrap();

      successToast(t("mes.細胞発送削除成功メッセージ"));
      await navigateWithoutPrompt(`../`);
    } catch (e) {
      errorToast(t("mes.細胞発送削除失敗エラー"));
      log.error(errorMessageOf(e));
    }
  }, [
    deleteCellStockDelivery,
    errorToast,
    navigateWithoutPrompt,
    onCloseDeleteModal,
    successToast,
    t,
    vm.id,
  ]);

  const handleChangeShippingInformation = useCallback(
    (change: (before: ShippingInformation) => ShippingInformation) => {
      handleChange((before) => ({
        ...before,
        shippingInformation: change(before.shippingInformation),
      }));
    },
    [handleChange],
  );
  const handleChangeReceiptInformation = useCallback(
    (change: (before: ReceiptInformation) => ReceiptInformation) => {
      handleChange((before) => ({
        ...before,
        receiptInformation: change(before.receiptInformation),
      }));
    },
    [handleChange],
  );
  const handleChangeResearchCells = useCallback(
    (change: (before: Partial<ShippingCell>[]) => Partial<ShippingCell>[]) => {
      handleChange((before) => ({
        ...before,
        shippingResearchCells: change(before.shippingResearchCells),
      }));
    },
    [handleChange],
  );
  const handleChangeClinicalCells = useCallback(
    (change: (before: Partial<ShippingCell>[]) => Partial<ShippingCell>[]) => {
      handleChange((before) => ({
        ...before,
        shippingClinicalCells: change(before.shippingClinicalCells),
      }));
    },
    [handleChange],
  );
  const handleChangeShippingDestination = useCallback(
    (change: (before: ShippingDestination) => ShippingDestination) => {
      handleChange((before) => ({
        ...before,
        shippingDestination: change(before.shippingDestination),
      }));
    },
    [handleChange],
  );
  const handleChangeDeliveryDate = useCallback(
    (v: YearMonthDay) => {
      handleChange((before) => ({
        ...before,
        preferredDeliveryDate: v,
      }));
    },
    [handleChange],
  );
  const handleChangeDeliverer = useCallback(
    (
      change: (
        before: CellStockDeliveryViewModel["deliverer"],
      ) => CellStockDeliveryViewModel["deliverer"],
    ) => {
      handleChange((before) => ({
        ...before,
        deliverer: change(before.deliverer),
      }));
    },
    [handleChange],
  );
  const handleChangeContainer = useCallback(
    (
      change: (
        before: CellStockDeliveryViewModel["container"],
      ) => CellStockDeliveryViewModel["container"],
    ) => {
      handleChange((before) => ({
        ...before,
        container: change(before.container),
      }));
    },
    [handleChange],
  );
  const handleChangePickupDestination = useCallback(
    (change: (before: PickupDestination) => PickupDestination) => {
      handleChange((before) => ({
        ...before,
        pickupDestination: change(before.pickupDestination),
      }));
    },
    [handleChange],
  );
  const handleChangeProvisionFeeMemo = useCallback(
    (v: string) => {
      handleChange((before) => ({
        ...before,
        provisionFeeMemo: v,
      }));
    },
    [handleChange],
  );
  const handleChangeOfficeMemberCommentForApplicants = useCallback(
    (v: string) => {
      handleChange((before) => ({
        ...before,
        officeMemberCommentForApplicants: v,
      }));
    },
    [handleChange],
  );
  const handleChangeOfficeMemberCommentInternal = useCallback(
    (v: string) => {
      handleChange((before) => ({
        ...before,
        officeMemberCommentInternal: v,
      }));
    },
    [handleChange],
  );
  const handleChangeAttachmentFiles = useCallback(
    (
      change: (
        before: AttachmentFileInformation[],
      ) => AttachmentFileInformation[],
    ) => {
      handleChange((before) => ({
        ...before,
        attachmentFiles: change(before.attachmentFiles),
      }));
    },
    [handleChange],
  );

  return (
    <>
      <VStack flex={1} alignSelf={"stretch"} overflow={"auto"} pb={"50px"}>
        {/*ボタンエリア*/}
        <FrameUpperRightButton sx={{ alignSelf: "flex-end" }} mb={"10px"}>
          {editMode === "readOnly" ? (
            // 参照モード
            <EditButton onClick={handleEdit} />
          ) : (
            // 編集モード
            <HStack>
              <SaveChangesButton onClick={onOpenSaveModal} />
              <EditEndButton onClick={onOpenCancelModal} />
            </HStack>
          )}
          {isOfficeMember && (
            <CMExtendedMenu
              size={"sm"}
              menuItems={[{ key: 0, label: t("btn.細胞発送を削除ボタン") }]}
              onClick={onOpenDeleteModal}
              ml={"10px"}
            />
          )}
        </FrameUpperRightButton>
        <Container flex={1} minW={"400px"} maxW={"720px"}>
          <VStack alignItems={"stretch"}>
            {/*タイトルエリア*/}
            <Flex
              flexDirection={"row"}
              justifyContent={"space-between"}
              pr={"12px"}
            >
              <TitleText title={t("lbl.細胞発送")} />
              <VStack alignItems={"flex-start"} spacing={0}>
                <WeakTextItem
                  label={t("lbl.作成日時")}
                  value={createdDatetime}
                />
                <WeakTextItem
                  label={t("lbl.更新日時")}
                  value={updatedDatetime}
                />
              </VStack>
            </Flex>
            {/*発送状況*/}
            <SectionShippingInformation
              editMode={editMode}
              value={vm.shippingInformation}
              isOfficeMember={isOfficeMember}
              onChange={handleChangeShippingInformation}
            />
            {/*受領状況*/}
            <SectionReceiptInformation
              editMode={editMode}
              value={vm.receiptInformation}
              isOfficeMember={isOfficeMember}
              onChange={handleChangeReceiptInformation}
            />
            {/*発送する細胞*/}
            <SectionShippingCell
              editMode={editMode}
              projectId={vm.projectId}
              valueResearchCells={vm.shippingResearchCells}
              valueClinicalCells={vm.shippingClinicalCells}
              isOfficeMember={isOfficeMember}
              onChangeResearchCells={handleChangeResearchCells}
              onChangeClinicalCells={handleChangeClinicalCells}
            />
            {/*出荷先*/}
            <SectionShippingDestination
              editMode={editMode}
              value={vm.shippingDestination}
              onChange={handleChangeShippingDestination}
            />
            {/*到着希望日*/}
            <SectionDeliveryDate
              editMode={editMode}
              value={vm.preferredDeliveryDate}
              onChange={handleChangeDeliveryDate}
            />
            {/*輸送業者について*/}
            <SectionDeliverer
              editMode={editMode}
              value={vm.deliverer}
              onChange={handleChangeDeliverer}
            />
            {/*輸送容器について*/}
            <SectionShippingContainers
              editMode={editMode}
              container={vm.container}
              pickup={vm.pickupDestination}
              onChangeContainer={handleChangeContainer}
              onChangePickupDestination={handleChangePickupDestination}
            />
            {/*提供代メモ*/}
            {isOfficeMember && (
              <SectionComment
                editMode={editMode}
                title={t("lbl.提供代メモ")}
                valueObjectMeta={freeTextMeta}
                value={vm.provisionFeeMemo}
                onChange={handleChangeProvisionFeeMemo}
              />
            )}

            {/* 申請者向け事務局コメント欄*/}
            <SectionComment
              editMode={isOfficeMember ? editMode : "readOnly"}
              title={t("lbl.申請者向け事務局コメント欄")}
              valueObjectMeta={freeTextMeta}
              value={vm.officeMemberCommentForApplicants}
              onChange={handleChangeOfficeMemberCommentForApplicants}
            />

            {/*事務局コメント欄*/}
            {isOfficeMember && (
              <SectionComment
                editMode={editMode}
                title={t("lbl.事務局コメント欄")}
                valueObjectMeta={freeTextMeta}
                value={vm.officeMemberCommentInternal}
                onChange={handleChangeOfficeMemberCommentInternal}
              />
            )}
            {/*添付資料*/}
            <CellStockDeliveryAttachmentFilesSection
              editMode={editMode}
              projectId={vm.projectId}
              value={vm.attachmentFiles}
              isOfficeMember={isOfficeMember}
              onChange={handleChangeAttachmentFiles}
            />
          </VStack>
        </Container>
      </VStack>
      <ConfirmationModal
        isOpen={isOpenSaveModal}
        message={t("mes.変更保存確認メッセージ")}
        onSubmit={handleSave}
        onCancel={onCloseSaveModal}
      />
      <ConfirmationModal
        isOpen={isOpenCancelModal}
        message={t("mes.変更内容破棄メッセージ")}
        onSubmit={handleEditCancel}
        onCancel={onCloseCancelModal}
      />
      <DeleteConfirmationModal
        isOpen={isOpenDeleteModal}
        onClose={onCloseDeleteModal}
        title={t("lbl.確認ポップアップタイトル")}
        message={t("mes.細胞発送削除確認メッセージ")}
        deleteButtonLabel={t("btn.削除ボタン")}
        cancelButtonLabel={t("btn.キャンセルボタン")}
        onConfirm={handleConfirmDelete}
        onCancel={undefined}
      />
    </>
  );
};
