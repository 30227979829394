import { UserInfoCardContent } from "../../content/UserInfoCardContent/UserInfoCardContent";
import React, { memo, useCallback, useMemo } from "react";
import { CMButtonFormCommentProps } from "@pscsrvlab/psc-react-components/src/components/form/comment";
import { UserInformationKeyedViewModel } from "../../../ApplicationDocument";
import { UserInformation } from "../../../../../../../../../lib/object/value/user-information";
import { UserInformationKeyed } from "../../../../../../../../../lib/object/value/user-information-keyed";
import { useSectionDiffMode } from "../../../../../../../../../hooks/document/change-application/use-section-diff-mode";
import { FormCard } from "../../../../../../../../ui/form/FormCard/FormCard";
import { useAppTranslation } from "../../../../../../../../../hooks/use-app-translation";

export type ContactPersonCardProps = {
  documentType:
    | "new_application"
    | "change_application"
    | "project_force_update"
    | "project_force_create"
    | "project_content";
  editMode: "editable" | "readOnly";

  mode: "none" | "added" | "deleted";

  institutionId?: number;
  freeze: boolean;

  index: number;
  value: UserInformationKeyedViewModel;

  onSelectUser: (key: number, value: UserInformation | null) => void;
  onDelete: (key: number) => void;

  changedFrom?: UserInformationKeyed;

  commentButtonProps?: CMButtonFormCommentProps;
};
export const ContactPersonCard = memo(function ContactPersonCard({
  documentType,
  editMode,

  mode,

  institutionId,

  value,

  index,
  freeze,

  onSelectUser,
  onDelete,

  changedFrom,

  commentButtonProps,
}: ContactPersonCardProps) {
  const { t } = useAppTranslation();

  const cardTitle = useMemo(() => {
    return t("lbl.連絡担当者") + (index + 1).toString(10);
  }, [t, index]);
  const _editMode: "readOnly" | "editable" = useMemo(() => {
    if (freeze) return "readOnly";
    if (mode === "deleted") return "readOnly";
    return editMode;
  }, [editMode, freeze, mode]);

  const handleSelectUser = useCallback(
    (v: UserInformation | null) => onSelectUser(value.key, v),
    [onSelectUser, value.key],
  );

  const handleDelete = useCallback(
    () => onDelete(value.key),
    [onDelete, value.key],
  );

  const sectionDiffModeOverwrite = useMemo(() => {
    return mode === "none" ? undefined : mode;
  }, [mode]);
  const sectionDiffMode = useSectionDiffMode(
    documentType,
    changedFrom,
    value,
    undefined,
    sectionDiffModeOverwrite,
  );

  return (
    <FormCard
      editMode={_editMode}
      title={cardTitle}
      onDelete={handleDelete}
      displayMode={sectionDiffMode}
      commentButtonProps={commentButtonProps}
    >
      <UserInfoCardContent
        documentType={documentType}
        value={value}
        editMode={_editMode}
        institutionId={institutionId}
        onSelect={handleSelectUser}
        changedFrom={changedFrom}
      />
    </FormCard>
  );
});
