import { ComponentStyleProps } from "../../../../../../../../../lib/styles/props/component-style-props";

import { HStack, VStack } from "@chakra-ui/react";
import { LabelAndEnumValue } from "../../../../../../../../ui/form/LabelAndEnumValue/LabelAndEnumValue";
import {
  hasValue,
  isNullish,
} from "../../../../../../../../../lib/util/common-util";
import { CMButtonFormCommentProps } from "@pscsrvlab/psc-react-components/src/components/form/comment";
import React, { memo, useEffect } from "react";
import { ChangeReasonForm } from "../../form/ChangeReasonForm/ChangeReasonForm";
import { useSectionDiffMode } from "../../../../../../../../../hooks/document/change-application/use-section-diff-mode";
import { useChangeBadgeProperties } from "../../../../../../../../../hooks/document/change-application/use-change-badge-properties";
import { foreignTypeMeta } from "../../../../../../../../../lib/object/value/foreign-type";
import { commercialTypeMeta } from "../../../../../../../../../lib/object/value/commercial-type";
import { FormValueLink } from "../../../../../../../../ui/form/FormValueLink/FormValueLink";
import { InstitutionInformation } from "../../../../../../../../../lib/object/value/institution-information";
import { useAppGetInstitutionQuery } from "../../../../../../../../../hooks/query/use-app-get-institution-query";
import { FormSection } from "../../../../../../../../ui/form/FormSection/FormSection";
import { useAppTranslation } from "../../../../../../../../../hooks/use-app-translation";
import { ChangeReasonItemViewModel } from "../../../../../../../../../lib/object/vm/change-application-view-model";

export type InstitutionNameSectionProps = {
  documentType:
    | "new_application"
    | "change_application"
    | "project_force_update"
    | "project_force_create"
    | "project_content";
  editMode: "editable" | "readOnly";

  /**
   * 機関情報。
   * 新規申請作成時には起票者の機関となり、参照時、下書き再開時は書類情報の機関となる。
   */
  value: Partial<InstitutionInformation>;
  onChange?: (value: Partial<InstitutionInformation>) => void;

  changedFrom?: InstitutionInformation;
  /**
   * 変更理由の値。
   */
  changeReason?: string;
  onChangeReason?: (
    change: (before: ChangeReasonItemViewModel) => ChangeReasonItemViewModel,
  ) => void;
  displayChangesOnly?: boolean;

  /**
   * コメントボタンのprops。
   * これが存在すれば、コメントボタンを表示する。
   */
  commentButtonProps?: CMButtonFormCommentProps;
} & ComponentStyleProps;

/**
 * 機関名セクション
 */
export const InstitutionNameSection = memo(function InstitutionNameSection({
  documentType,
  editMode,

  value,
  onChange,

  changedFrom,
  changeReason,
  onChangeReason,
  displayChangesOnly,

  commentButtonProps,

  sx,
  ...rest
}: InstitutionNameSectionProps) {
  const { t } = useAppTranslation();

  // 編集モードなら常にマスタの最新情報に更新する。
  // (参照モードなら素直に保存済のデータをそのまま表示する。)
  const { data: institution } = useAppGetInstitutionQuery(
    {
      institutionId: value.institutionId ?? -1,
    },
    { skip: editMode !== "editable" || isNullish(value.institutionId) },
  );
  useEffect(() => {
    if (editMode !== "editable" || isNullish(institution)) return;
    onChange?.({
      institutionId: institution.id,
      displayInstitutionId: institution.displayInstitutionId,
      institutionName: institution.name,
      institutionNameKana: institution.nameKana,
      foreignType: institution.foreignType,
      commercialType: institution.commercialType,
    });
  }, [editMode, institution, onChange]);

  const sectionDiffMode = useSectionDiffMode(
    documentType,
    changedFrom,
    value,
    onChangeReason,
  );

  const changeBadgePropertiesInstitutionName = useChangeBadgeProperties(
    documentType,
    changedFrom?.institutionName,
  );
  const changeBadgePropertiesForeignType = useChangeBadgeProperties(
    documentType,
    changedFrom?.foreignType,
  );
  const changeBadgePropertiesCommercialType = useChangeBadgeProperties(
    documentType,
    changedFrom?.commercialType,
  );

  return (
    <FormSection
      title={t("lbl.機関名")}
      displayMode={sectionDiffMode}
      displayChangesOnly={displayChangesOnly}
      commentButtonProps={commentButtonProps}
      sx={sx}
      {...rest}
    >
      <HStack alignItems={"flex-start"}>
        <FormValueLink
          label={t("lbl.機関名")}
          value={value?.institutionName}
          to={
            hasValue(value) ? `/institution/${value.institutionId}` : undefined
          }
          changeBadgeProperties={changeBadgePropertiesInstitutionName}
          sx={{ flex: "2 1 auto" }}
        />
        <VStack flex={"1 1 auto"} minW={0}>
          <LabelAndEnumValue
            valueObjectMeta={foreignTypeMeta}
            label={t("lbl.国内外区分")}
            value={value?.foreignType}
            changeBadgeProperties={changeBadgePropertiesForeignType}
          />
        </VStack>
        <VStack flex={"1 1 auto"} minW={0}>
          <LabelAndEnumValue
            valueObjectMeta={commercialTypeMeta}
            label={t("lbl.営利非営利区分")}
            value={value?.commercialType}
            changeBadgeProperties={changeBadgePropertiesCommercialType}
          />
        </VStack>
      </HStack>
      {sectionDiffMode === "updated" && (
        <ChangeReasonForm
          value={changeReason}
          onChange={onChangeReason}
          editMode={editMode}
        />
      )}
    </FormSection>
  );
});
