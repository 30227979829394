import { ComponentStyleProps } from "../../../../../../../../../lib/styles/props/component-style-props";
import { PartnerInfo } from "../../section/PartnersSection/PartnersSection";
import { HStack, Stack, Text, VStack } from "@chakra-ui/react";
import {
  CMFormControlHeader,
  CMFormInputRadio,
  CMFormInputText,
  CMFormInputTextArea,
} from "@pscsrvlab/psc-react-components";
import { collaborativePartnerNameMeta } from "../../../../../../../../../lib/object/value/collaborative-partner-name";
import {
  ForeignType,
  foreignTypeMeta,
} from "../../../../../../../../../lib/object/value/foreign-type";
import {
  CommercialType,
  commercialTypeMeta,
} from "../../../../../../../../../lib/object/value/commercial-type";
import { collaborativePartnerRoleMeta } from "../../../../../../../../../lib/object/value/collaborative-partner-role";
import { CMButtonFormCommentProps } from "@pscsrvlab/psc-react-components/src/components/form/comment";
import { CollaborativePartner } from "../../../../../../../../../lib/object/value/collaborative-partner";
import { memo, useCallback } from "react";
import { isEqual } from "lodash";
import log from "loglevel";
import { CollaborativePartnerUserInformation } from "../../../../../../../../../lib/object/value/collaborative-partner-user-information";
import { useChangeBadgeProperties } from "../../../../../../../../../hooks/document/change-application/use-change-badge-properties";
import { PartnerContentUserInfo } from "./_components/PartnerContentUserInfo/PartnerContentUserInfo";
import { useAppTranslation } from "../../../../../../../../../hooks/use-app-translation";

// [注意] propsを追加・変更するときは、memoの第二引数の比較関数が適切に動いているかを確認すること。
export type PartnerContentProps = {
  documentType:
    | "new_application"
    | "change_application"
    | "project_force_update"
    | "project_force_create"
    | "project_content";
  editMode: "editable" | "readOnly";

  value: PartnerInfo;
  onChange: (change: (before: PartnerInfo) => PartnerInfo) => void;

  changedFrom?: CollaborativePartner;

  /**
   * コメントボタンのprops。
   * これが存在すれば、コメントボタンを表示する。
   */
  commentButtonProps?: {
    root: CMButtonFormCommentProps | undefined;
    collaborativePartnerName: CMButtonFormCommentProps | undefined;
    principalInvestigator: CMButtonFormCommentProps | undefined;
    contactPerson: CMButtonFormCommentProps | undefined;
  };
} & ComponentStyleProps;

export const PartnerContent = memo(
  function PartnerContent({
    documentType,
    editMode,

    value,
    onChange,

    changedFrom,

    commentButtonProps,

    sx,
    ...rest
  }: PartnerContentProps) {
    const { t } = useAppTranslation();

    /**
     * 分担機関の機関名が変更されると呼ばれる。
     */
    const handleChangeCollaborativePartnerName = useCallback(
      (v: string) => {
        onChange((before) => ({ ...before, collaborativePartnerName: v }));
      },
      [onChange],
    );

    /**
     * 分担機関の国内外区分が変更されると呼ばれる。
     */
    const handleChangeForeignType = useCallback(
      (v: ForeignType) => {
        onChange((before) => ({ ...before, foreignType: v }));
      },
      [onChange],
    );

    /**
     * 分担機関の営利非営利区分が変更されると呼ばれる。
     */
    const handleChangeCommercialType = useCallback(
      (v: CommercialType) => {
        onChange((before) => ({ ...before, commercialType: v }));
      },
      [onChange],
    );

    /**
     * 分担機関の役割が変更されると呼ばれる。
     */
    const handleChangeCollaborativePartnerRole = useCallback(
      (v: string) => {
        onChange((before) => ({ ...before, collaborativePartnerRole: v }));
      },
      [onChange],
    );

    const handleChangePrincipalInvestigator = useCallback(
      (
        change: (
          before: CollaborativePartnerUserInformation,
        ) => CollaborativePartnerUserInformation,
      ) => {
        onChange((before) => ({
          ...before,
          principalInvestigator: change(before.principalInvestigator),
        }));
      },
      [onChange],
    );
    const handleChangeContactPerson = useCallback(
      (
        change: (
          before: CollaborativePartnerUserInformation,
        ) => CollaborativePartnerUserInformation,
      ) => {
        onChange((before) => ({
          ...before,
          contactPerson: change(before.contactPerson),
        }));
      },
      [onChange],
    );

    const changeBadgePropertiesPartnerName = useChangeBadgeProperties(
      documentType,
      changedFrom?.collaborativePartnerName,
    );
    const changeBadgePropertiesForeignType = useChangeBadgeProperties(
      documentType,
      changedFrom?.foreignType,
    );
    const changeBadgePropertiesCommercialType = useChangeBadgeProperties(
      documentType,
      changedFrom?.commercialType,
    );
    const changeBadgePropertiesRole = useChangeBadgeProperties(
      documentType,
      changedFrom?.collaborativePartnerRole,
    );

    return (
      <VStack
        spacing={"20px"}
        alignItems={"stretch"}
        sx={sx}
        {...rest}
        className={"PartnerContent"}
      >
        <VStack alignItems={"stretch"} spacing={"18px"}>
          <HStack>
            <Text fontWeight={"bold"} alignSelf={"flex-start"}>
              {t("lbl.機関名")}
            </Text>
            <CMFormControlHeader
              commentButtonProps={commentButtonProps?.collaborativePartnerName}
            />
          </HStack>

          <VStack alignItems={"stretch"} spacing={"18px"} mb={"10px"}>
            <CMFormInputText
              label={t("lbl.機関名")}
              editMode={editMode}
              valueObjectMeta={collaborativePartnerNameMeta}
              value={value?.collaborativePartnerName}
              onChange={handleChangeCollaborativePartnerName}
              changeBadgeProperties={changeBadgePropertiesPartnerName}
              minW={"320px"}
            />
            <HStack alignItems={"flex-start"}>
              <CMFormInputRadio
                label={t("lbl.国内外区分")}
                direction={"row"}
                editMode={editMode}
                valueObjectMeta={foreignTypeMeta}
                value={value?.foreignType}
                onChange={handleChangeForeignType}
                changeBadgeProperties={changeBadgePropertiesForeignType}
                sx={{ flex: "1 1 auto" }}
              />
              <CMFormInputRadio
                label={t("lbl.営利非営利区分")}
                direction={"row"}
                editMode={editMode}
                valueObjectMeta={commercialTypeMeta}
                value={value?.commercialType}
                onChange={handleChangeCommercialType}
                changeBadgeProperties={changeBadgePropertiesCommercialType}
                sx={{ flex: "1 1 auto" }}
              />
            </HStack>
          </VStack>
          <Stack direction={"row"} mb={"10px"}>
            <CMFormInputTextArea
              resize={editMode === "editable" ? "vertical" : "none"}
              label={t("lbl.役割")}
              editMode={editMode}
              valueObjectMeta={collaborativePartnerRoleMeta}
              value={value?.collaborativePartnerRole}
              onChange={handleChangeCollaborativePartnerRole}
              changeBadgeProperties={changeBadgePropertiesRole}
            />
          </Stack>
        </VStack>

        <PartnerContentUserInfo
          documentType={documentType}
          editMode={editMode}
          title={t("lbl.研究責任者")}
          value={value.principalInvestigator}
          onChange={handleChangePrincipalInvestigator}
          changedFrom={changedFrom?.principalInvestigator}
          commentButtonProps={commentButtonProps?.principalInvestigator}
        />
        <PartnerContentUserInfo
          documentType={documentType}
          editMode={editMode}
          title={t("lbl.連絡担当者")}
          value={value.contactPerson}
          onChange={handleChangeContactPerson}
          changedFrom={changedFrom?.contactPerson}
          commentButtonProps={commentButtonProps?.contactPerson}
        />
      </VStack>
    );
  },
  (prevProps, nextProps) => {
    const _isEqual = isEqual(prevProps, nextProps);
    log.debug(`PartnerContent: _isEqual=${_isEqual}`);
    return _isEqual;
  },
);
