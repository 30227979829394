import { CMFormControlHeader } from "@pscsrvlab/psc-react-components";
import { HStack, VStack } from "@chakra-ui/react";
import { CMButtonFormCommentProps } from "@pscsrvlab/psc-react-components/src/components/form/comment";
import React, { memo } from "react";
import { InstitutionInformation } from "../../../../../../lib/object/value/institution-information";
import { ComponentStyleProps } from "../../../../../../lib/styles/props/component-style-props";
import { hasValue } from "../../../../../../lib/util/common-util";
import { AppLink } from "../../../../../ui/link/AppLink/AppLink";
import { LabelAndEnumValue } from "../../../../../ui/form/LabelAndEnumValue/LabelAndEnumValue";
import { foreignTypeMeta } from "../../../../../../lib/object/value/foreign-type";
import { commercialTypeMeta } from "../../../../../../lib/object/value/commercial-type";
import { useAppTranslation } from "../../../../../../hooks/use-app-translation";
import { FormSection } from "../../../../../ui/form/FormSection/FormSection";

export type InstitutionNameSectionProps = {
  /**
   * 機関情報。
   * 新規申請作成時には起票者の機関となり、参照時、下書き再開時は書類情報の機関となる。
   */
  value: Partial<InstitutionInformation> | null;

  /**
   * コメントボタンのprops。
   * これが存在すれば、コメントボタンを表示する。
   */
  commentButtonProps?: CMButtonFormCommentProps;
} & ComponentStyleProps;

/**
 * 機関名セクション
 */
export const InstitutionNameReportSection = memo(
  function InstitutionNameReportSection({
    value,
    commentButtonProps,
    sx,
    ...rest
  }: InstitutionNameSectionProps) {
    const { t } = useAppTranslation();

    return (
      <FormSection
        title={t("lbl.機関名")}
        commentButtonProps={commentButtonProps}
        sx={sx}
        {...rest}
      >
        <HStack alignItems={"flex-start"}>
          <VStack flex={"2 1 auto"} minW={0} alignItems={"flex-start"}>
            <CMFormControlHeader label={t("lbl.機関名")} mb={"2px"} />
            {hasValue(value) && hasValue(value?.institutionId) && (
              <AppLink
                isExternal
                to={`/institution/${value.institutionId}`}
                mt={"10px"}
                ml={"10px"}
                sx={{ alignSelf: "stretch" }}
              >
                {value?.institutionName ?? ""}
              </AppLink>
            )}
          </VStack>
          <VStack flex={"1 1 auto"} minW={0}>
            <LabelAndEnumValue
              valueObjectMeta={foreignTypeMeta}
              label={t("lbl.国内外区分")}
              value={value?.foreignType}
            />
          </VStack>
          <VStack flex={"1 1 auto"} minW={0}>
            <LabelAndEnumValue
              valueObjectMeta={commercialTypeMeta}
              label={t("lbl.営利非営利区分")}
              value={value?.commercialType}
            />
          </VStack>
        </HStack>
      </FormSection>
    );
  },
);
