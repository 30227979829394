import React, { memo, ReactNode, useMemo } from "react";
import { ComponentStyleProps } from "../../../../lib/styles/props/component-style-props";
import { CMButtonFormCommentProps } from "@pscsrvlab/psc-react-components/src/components/form/comment";
import { CMFrameCard } from "@pscsrvlab/psc-react-components";
import { SectionContentFrame } from "../../../model/document/application/_components/ApplicationDocument/_components/frame/SectionContentFrame/SectionContentFrame";
import { RiDeleteBin5Line } from "react-icons/ri";
import { SystemProps } from "@chakra-ui/system";
import { useAppTranslation } from "../../../../hooks/use-app-translation";

export type FormCardProps = {
  editMode: "editable" | "disabled" | "readOnly";

  title?: string;
  onDelete?: () => void;
  backgroundColor?: string;

  /**
   * 画面表示モード
   */
  displayMode?: "normal" | "added" | "updated" | "deleted";

  /**
   * コメントボタンのprops。
   * これが存在すれば、コメントボタンを表示する。
   */
  commentButtonProps?: CMButtonFormCommentProps;

  spacing?: SystemProps["margin"];

  children?: ReactNode;
} & ComponentStyleProps;

export const FormCard = memo(function FormCard({
  editMode,

  title,
  onDelete,
  backgroundColor = "gray.200",

  displayMode = "normal",

  commentButtonProps,

  spacing,

  children,

  sx,
  ...rest
}: FormCardProps) {
  const { t } = useAppTranslation();

  const deleteButtonIcon = useMemo(
    () => editMode === "editable" && <RiDeleteBin5Line />,
    [editMode],
  );
  const displayModeMessage = useMemo(() => {
    return {
      added: t("lbl.追加"),
      deleted: t("lbl.削除"),
    };
  }, [t]);
  return (
    <CMFrameCard
      backgroundColor={backgroundColor}
      title={title}
      deleteButtonIcon={deleteButtonIcon}
      onDelete={onDelete}
      displayMode={displayMode}
      displayModeMessage={displayModeMessage}
      commentButtonProps={commentButtonProps}
      sx={sx}
      {...rest}
    >
      <SectionContentFrame spacing={spacing}>{children}</SectionContentFrame>
    </CMFrameCard>
  );
});
