import { Center, Container, Text, VStack } from "@chakra-ui/react";
import React, { useCallback, useMemo } from "react";
import { UsedCell } from "../../../../../../lib/object/value/used-cell";
import { DifferentiatedCellProvisionType } from "../../../../../../lib/object/value/differentiated-cell-provision-type";
import { AttachmentFileInformation } from "../../../../../../lib/object/value/attachment-file-information";
import { ComponentStyleProps } from "../../../../../../lib/styles/props/component-style-props";
import { ProjectNameFormReportSection } from "../../_components/section/ProjectNameFormReportSection";
import { InstitutionNameReportSection } from "../../_components/section/InstitutionNameReportSection";
import { PrincipalInvestigatorReportSection } from "../../_components/section/PrincipalInvestigatorReportSection";
import { ReportingPeriodAnnualReportSection } from "../AnnualReportCreatePage/_components/section/ReportingPeriodAnnualReportSection";
import { UsedCellsAnnualReportSection } from "../AnnualReportCreatePage/_components/section/UsedCellsAnnualReportSection";
import { CellProvisionTypeReportSection } from "../../_components/section/CellProvisionTypeReportSection";
import { CellProvisionsReportSection } from "../../_components/section/CellProvisionsReportSection";
import { PartnersReportSection } from "../../_components/section/PartnersReportSection";
import { ProgressResultOutlineAnnualReportSection } from "../AnnualReportCreatePage/_components/section/ProgressResultOutlineAnnualReportSection";
import { AttachmentFilesReportSection } from "../../_components/section/AttachmentFilesReportSection";
import { LoginUserInfo } from "../../../../../../store/auth/types";
import { useAnnualReportDocumentCommentProps } from "../AnnualReportCreatePage/_hooks/use-annual-report-document-comment-props";
import { RevisionRequestItemViewModel } from "../../../../../../lib/object/vm/revision-request-view-model";
import {
  AnnualReportContentViewModel,
  AnnualReportViewModel,
} from "../../../../../../lib/object/vm/annual-report-view-model";
import { ValidationError } from "@pscsrvlab/psc-react-components";
import { hasValue } from "../../../../../../lib/util/common-util";
import { ErrorMessageArea } from "../../../../../ui/form/ErrorMessageArea/ErrorMessageArea";
import { useAppTranslation } from "../../../../../../hooks/use-app-translation";
import { DifferentiatedCellProvision } from "../../../../../../lib/object/value/differentiated-cell-provision";
import { CollaborativePartnerOnReport } from "../../../../../../lib/object/value/collaborative-partner-on-report";
import { DocumentHeaderControlNumbers } from "../../../_components/DocumentHeaderControlNumbers/DocumentHeaderControlNumbers";
import { DocumentHeaderDates } from "../../../_components/DocumentHeaderDates/DocumentHeaderDates";

export type AnnualReportDocumentProps = {
  loginUserInfo: LoginUserInfo;

  editMode: "editable" | "readOnly";

  /**
   * 値。
   */
  value: AnnualReportViewModel;
  onChange?: (
    change: (before: AnnualReportViewModel) => AnnualReportViewModel,
  ) => void;

  /**
   * 修正依頼。
   */
  revisionRequestItems?: RevisionRequestItemViewModel[];
  /**
   * 修正依頼コメントを選択したときのコールバック。
   * 未選択状態になる場合はnullを送出する。
   */
  onSelectComment?: (path: string | null) => void;
  /**
   * 修正依頼の変更。
   */
  onChangeRevisionRequestItems?: (
    change: (
      before: RevisionRequestItemViewModel[],
    ) => RevisionRequestItemViewModel[],
  ) => void;

  revisionMode?:
    | "none"
    | "readOnly"
    | "office_member_editable"
    | "applicant_editable";

  /**
   * バリデーションエラー。
   * 編集画面でのみ必要。
   */
  validationErrors?: ValidationError[];
} & ComponentStyleProps;

export const AnnualReportDocument = ({
  loginUserInfo,

  editMode,

  value,
  onChange,

  revisionRequestItems,
  onSelectComment,
  onChangeRevisionRequestItems,
  revisionMode = "none",

  validationErrors,

  sx,
  ...rest
}: AnnualReportDocumentProps) => {
  const { t } = useAppTranslation();

  const projectId = useMemo(() => value.projectId, [value.projectId]);

  const handleChange = useCallback(
    (
      change: (
        before: AnnualReportContentViewModel,
      ) => AnnualReportContentViewModel,
    ) => {
      onChange?.((before) => {
        return {
          ...before,
          contentVM: change(before.contentVM),
        };
      });
    },
    [onChange],
  );

  const {
    handleChangeUsedSectionResearchCells,
    handleChangeUsedSectionClinicalCells,
    handleChangeCellProvisionTypeSection,
    handleChangeCellProvisionsSection,
    handleChangePartnersSection,
    handleChangeProgressResultOutlineSection,
    handleChangeAttachmentFilesSection,
  } = useChangeHandlers(handleChange);

  const commentProps = useAnnualReportDocumentCommentProps(
    loginUserInfo,
    value.contentVM,
    revisionMode,
    revisionRequestItems,
    onSelectComment,
    onChangeRevisionRequestItems,
  );

  return (
    <Container
      maxW={"720px"}
      px={0}
      sx={sx}
      {...rest}
      className={"AnnualReportDocument"}
    >
      <VStack justifyContent={"flex-start"} alignItems={"stretch"}>
        {hasValue(validationErrors) && validationErrors.length > 0 && (
          <VStack
            pt={"10px"}
            position={"sticky"}
            top={0}
            bgColor={"white"}
            zIndex={20}
          >
            <ErrorMessageArea validationErrors={validationErrors} />
          </VStack>
        )}
        <DocumentHeaderControlNumbers
          projectControlNumber={value.projectControlNumber}
          documentControlNumber={value.documentControlNumber}
        />
        <Center>
          <Text fontWeight={"bold"}>{t("lbl.年次報告タイトル")}</Text>
        </Center>
        <DocumentHeaderDates
          submissionDate={value.submissionDate}
          receptionDate={value.receptionDate}
          approvalDate={value.approvalDate}
          documentCompletionDate={value.documentCompletionDate}
        />
        <VStack
          alignItems={"stretch"}
          overflowX={"hidden"}
          overflowY={"hidden"}
          className={"VStack"}
        >
          <ProjectNameFormReportSection
            value={value.contentVM?.projectName}
            commentButtonProps={commentProps.projectName}
          />
          <InstitutionNameReportSection
            value={value.contentVM.institution}
            commentButtonProps={commentProps.institution}
          />
          <PrincipalInvestigatorReportSection
            value={value.contentVM.principalInvestigator}
            commentButtonProps={commentProps.principalInvestigator}
          />
          <ReportingPeriodAnnualReportSection
            editMode={editMode}
            value={value.contentVM.reportPeriodStartDate}
            commentButtonProps={commentProps.reportPeriod}
          />
          <UsedCellsAnnualReportSection
            editMode={editMode}
            valueResearchCells={value.contentVM.usedResearchCells}
            valueClinicalCells={value.contentVM.usedClinicalCells}
            onChangeResearchCells={handleChangeUsedSectionResearchCells}
            onChangeClinicalCells={handleChangeUsedSectionClinicalCells}
            commentButtonProps={commentProps.usedCells}
            commentButtonPropsResearchCells={commentProps.usedResearchCellsN}
            commentButtonPropsClinicalCells={commentProps.usedClinicalCellsN}
          />
          <CellProvisionTypeReportSection
            editMode={editMode}
            value={value.contentVM.cellProvisionType}
            onChange={handleChangeCellProvisionTypeSection}
            commentButtonProps={commentProps.cellProvisionType}
          />
          {value.contentVM.cellProvisionType === "provided" && (
            <CellProvisionsReportSection
              editMode={editMode}
              value={value.contentVM.cellProvisions}
              onChange={handleChangeCellProvisionsSection}
              commentButtonProps={commentProps.cellProvisions}
              commentButtonPropsChildren={commentProps.cellProvisionsN}
            />
          )}
          {value.contentVM.partners.length > 0 && (
            <PartnersReportSection
              editMode={editMode}
              value={value.contentVM.partners}
              onChange={handleChangePartnersSection}
              commentButtonProps={commentProps.partners}
              commentButtonPropsChildren={commentProps.partnersN}
            />
          )}
          <ProgressResultOutlineAnnualReportSection
            editMode={editMode}
            value={value.contentVM.progressResultOutline}
            onChange={handleChangeProgressResultOutlineSection}
            commentButtonProps={commentProps.progressResultOutline}
          />
          <AttachmentFilesReportSection
            editMode={editMode}
            projectId={projectId}
            value={value.contentVM.attachmentFiles}
            onChange={handleChangeAttachmentFilesSection}
            commentButtonProps={commentProps.attachmentFiles}
          />
        </VStack>
      </VStack>
    </Container>
  );
};

function useChangeHandlers(
  onChange?: (
    change: (
      before: AnnualReportContentViewModel,
    ) => AnnualReportContentViewModel,
  ) => void,
) {
  const handleChange = useCallback(
    (
      change: (
        before: AnnualReportContentViewModel,
      ) => AnnualReportContentViewModel,
    ) => {
      onChange?.(change);
    },
    [onChange],
  );

  const handleChangeUsedSectionResearchCells = useCallback(
    (change: (before: UsedCell[]) => UsedCell[]) => {
      handleChange((before) => ({
        ...before,
        usedResearchCells: change(before.usedResearchCells),
      }));
    },
    [handleChange],
  );

  const handleChangeUsedSectionClinicalCells = useCallback(
    (change: (before: UsedCell[]) => UsedCell[]) =>
      handleChange((before) => ({
        ...before,
        usedClinicalCells: change(before.usedClinicalCells),
      })),
    [handleChange],
  );

  const handleChangePartnersSection = useCallback(
    (
      change: (
        before: Partial<CollaborativePartnerOnReport>[],
      ) => Partial<CollaborativePartnerOnReport>[],
    ) =>
      handleChange((before) => ({
        ...before,
        partners: change(before.partners),
      })),
    [handleChange],
  );

  const handleChangeCellProvisionTypeSection = useCallback(
    (v: DifferentiatedCellProvisionType) =>
      handleChange((before) => ({
        ...before,
        cellProvisionType: v,
      })),
    [handleChange],
  );

  const handleChangeCellProvisionsSection = useCallback(
    (
      change: (
        before: DifferentiatedCellProvision[],
      ) => DifferentiatedCellProvision[],
    ) =>
      handleChange((before) => ({
        ...before,
        cellProvisions: change(before.cellProvisions),
      })),
    [handleChange],
  );

  const handleChangeProgressResultOutlineSection = useCallback(
    (v: string) =>
      handleChange((before) => ({
        ...before,
        progressResultOutline: v,
      })),
    [handleChange],
  );

  const handleChangeAttachmentFilesSection = useCallback(
    (
      change: (
        before: AttachmentFileInformation[],
      ) => AttachmentFileInformation[],
    ) =>
      handleChange((before) => ({
        ...before,
        attachmentFiles: change(before.attachmentFiles),
      })),
    [handleChange],
  );

  return {
    handleChangeUsedSectionResearchCells,
    handleChangeUsedSectionClinicalCells,
    handleChangeCellProvisionTypeSection,
    handleChangeCellProvisionsSection,
    handleChangePartnersSection,
    handleChangeProgressResultOutlineSection,
    handleChangeAttachmentFilesSection,
  };
}
