import {
  CMFormInputDate,
  CMFrameSection,
} from "@pscsrvlab/psc-react-components";
import { HStack, Text } from "@chakra-ui/react";
import { memo } from "react";
import { CMButtonFormCommentProps } from "@pscsrvlab/psc-react-components/src/components/form/comment";
import { ComponentStyleProps } from "../../../../../../../../lib/styles/props/component-style-props";
import { SectionContentFrame } from "../../../../../application/_components/ApplicationDocument/_components/frame/SectionContentFrame/SectionContentFrame";
import { hasValue } from "../../../../../../../../lib/util/common-util";
import { useAppTranslation } from "../../../../../../../../hooks/use-app-translation";

export type UsageEndDateSectionProps = {
  editMode?: "editable" | "disabled" | "readOnly";
  value:
    | {
        start: { year: number; month: number; day: number } | undefined;
        end: { year: number; month: number; day: number } | undefined;
      }
    | undefined;

  onChange: (
    value: { year: number; month: number; day: number } | undefined,
  ) => void;

  /**
   * コメントボタンのprops。
   * これが存在すれば、コメントボタンを表示する。
   */
  commentButtonProps?: CMButtonFormCommentProps;
} & ComponentStyleProps;

/**
 * 使用期間セクション
 */
export const UsageEndDateTerminationReportSection = memo(
  function UsageEndDateSection({
    value,
    commentButtonProps,
    editMode = "readOnly",
    onChange,
    sx,
    ...rest
  }: UsageEndDateSectionProps) {
    const { t } = useAppTranslation();
    const handleChange = (
      v: { year: number; month: number; day: number } | null,
    ) => {
      onChange(v ?? undefined);
    };
    return (
      <CMFrameSection
        title={t("lbl.使用期間")}
        description={
          editMode === "editable" ? t("gdc.使用期間補足") : undefined
        }
        commentButtonProps={commentButtonProps}
        sx={{ w: "100%", ...sx }}
        {...rest}
      >
        {hasValue(value) && (
          <SectionContentFrame>
            <HStack mt={"10px"}>
              <CMFormInputDate
                label={t("lbl.使用開始日")}
                editMode={"readOnly"}
                value={value.start}
                w={"120px"}
              />
              {/*<Text>{ymdToDateText(value)}</Text>*/}
              <Text fontSize={"lg"} pt={"20px"} w={"20px"}>
                {t("lbl.範囲シンボル")}
              </Text>
              <CMFormInputDate
                onChange={handleChange}
                label={t("lbl.使用終了日")}
                editMode={editMode}
                value={value.end}
                w={"150px"}
                pl={"10px"}
              />
            </HStack>
          </SectionContentFrame>
        )}
      </CMFrameSection>
    );
  },
);
