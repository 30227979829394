import {
  CMFormInputRadio,
  CMFormInputTextArea,
  CMFrameSection,
} from "@pscsrvlab/psc-react-components";
import { Stack, Text } from "@chakra-ui/react";
import React, { memo } from "react";
import { CMButtonFormCommentProps } from "@pscsrvlab/psc-react-components/src/components/form/comment";
import { ComponentStyleProps } from "../../../../../../../../lib/styles/props/component-style-props";
import { SectionContentFrame } from "../../../../../application/_components/ApplicationDocument/_components/frame/SectionContentFrame/SectionContentFrame";
import { planComplianceTypeMeta } from "../../../../../../../../lib/object/value/plan-compliance-type";
import { reasonsForNotBeingAbleToComplyMeta } from "../../../../../../../../lib/object/value/reasons-for-not-being-able-to-comply";
import { useAppTranslation } from "../../../../../../../../hooks/use-app-translation";

export type HandlingAfterTerminationSectionProps = {
  editMode: "editable" | "disabled" | "readOnly";

  value: {
    planComplianceType: "yes" | "no";
    reasonsForNotBeingAbleToComply?: string | undefined;
  };

  onChange: (value: {
    planComplianceType: "yes" | "no";
    reasonsForNotBeingAbleToComply?: string | undefined;
  }) => void;

  /**
   * コメントボタンのprops。
   * これが存在すれば、コメントボタンを表示する。
   */
  commentButtonProps?: CMButtonFormCommentProps;
} & ComponentStyleProps;

/**
 * 分化細胞等の提供先の有無(分担機関以外)セクション
 */
export const HandlingAfterTerminationReportSection = memo(
  function HandlingAfterTerminationSection({
    editMode,
    value,
    onChange,
    commentButtonProps,
    sx,
    ...rest
  }: HandlingAfterTerminationSectionProps) {
    const { t } = useAppTranslation();

    const handleChangePlanComplianceType = (val: "yes" | "no") => {
      if (val === "yes") {
        onChange({
          planComplianceType: val,
          reasonsForNotBeingAbleToComply: undefined,
        });
      }
      if (val === "no") {
        onChange({
          planComplianceType: val,
          reasonsForNotBeingAbleToComply: "",
        });
      }
    };

    const handleChangeReasonsForNotBeingAbleToComply = (val: string) => {
      onChange({
        ...value,
        reasonsForNotBeingAbleToComply: val,
      });
    };

    return (
      <>
        <CMFrameSection
          title={t("lbl.研究終了後の試料・情報の取り扱い(分担機関を含む)")}
          commentButtonProps={commentButtonProps}
          sx={{ w: "100%", ...sx }}
          {...rest}
        >
          <SectionContentFrame>
            <Stack spacing={"10px"}>
              <Text fontSize={"sm"}>
                {t("lbl.研究終了後の試料・情報の取り扱い(分担機関を含む)説明")}
              </Text>
              <CMFormInputRadio<["yes", "no"]>
                direction={"row"}
                noHeader={true}
                editMode={editMode}
                value={value.planComplianceType}
                valueObjectMeta={planComplianceTypeMeta}
                onChange={handleChangePlanComplianceType}
              />
              {value.planComplianceType === "no" && (
                <CMFormInputTextArea
                  label={t("lbl.遵守できなかった理由")}
                  value={value.reasonsForNotBeingAbleToComply}
                  valueObjectMeta={reasonsForNotBeingAbleToComplyMeta}
                  editMode={editMode}
                  onChange={handleChangeReasonsForNotBeingAbleToComply}
                />
              )}
            </Stack>
          </SectionContentFrame>
        </CMFrameSection>
      </>
    );
  },
);
