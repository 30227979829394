import { ComponentStyleProps } from "../../../../../../../../../lib/styles/props/component-style-props";
import { UserInfoCardContent } from "../../content/UserInfoCardContent/UserInfoCardContent";
import React, { memo, useCallback, useMemo } from "react";
import { UserInformation } from "../../../../../../../../../lib/object/value/user-information";
import { CMButtonFormCommentProps } from "@pscsrvlab/psc-react-components/src/components/form/comment";
import { useSectionDiffMode } from "../../../../../../../../../hooks/document/change-application/use-section-diff-mode";
import { ChangeReasonForm } from "../../form/ChangeReasonForm/ChangeReasonForm";
import { FormSection } from "../../../../../../../../ui/form/FormSection/FormSection";
import { useAppTranslation } from "../../../../../../../../../hooks/use-app-translation";
import { ChangeReasonItemViewModel } from "../../../../../../../../../lib/object/vm/change-application-view-model";

export type PrincipalInvestigatorSectionProps = {
  documentType:
    | "new_application"
    | "change_application"
    | "project_force_update"
    | "project_force_create"
    | "project_content";
  editMode: "editable" | "readOnly";

  institutionId?: number;

  value: UserInformation | null;
  onChange?: (value: UserInformation | null) => void;

  /**
   * 変更前の値。
   * 変更申請では、この値を与えること。
   */
  changedFrom?: UserInformation;
  /**
   * 変更理由の値。
   */
  changeReason?: string;
  onChangeReason?: (
    change: (before: ChangeReasonItemViewModel) => ChangeReasonItemViewModel,
  ) => void;
  displayChangesOnly?: boolean;

  /**
   * コメントボタンのprops。
   * これが存在すれば、コメントボタンを表示する。
   */
  commentButtonProps?: CMButtonFormCommentProps;
} & ComponentStyleProps;

/**
 * 研究責任者セクション
 */
export const PrincipalInvestigatorSection = memo(
  function PrincipalInvestigatorSection({
    documentType,
    editMode,
    institutionId,

    value,
    onChange,

    changedFrom,
    changeReason,
    onChangeReason,
    displayChangesOnly,

    commentButtonProps,

    sx,

    ...rest
  }: PrincipalInvestigatorSectionProps) {
    const { t } = useAppTranslation();

    const handleSelect = useCallback(
      (v: UserInformation | null) => {
        onChange?.(v);
      },
      [onChange],
    );

    const sectionDiffMode = useSectionDiffMode(
      documentType,
      changedFrom,
      value,
      onChangeReason,
    );

    const description = useMemo(
      () => (editMode === "editable" ? t("gdc.研究責任者補足") : undefined),
      [editMode, t],
    );

    return (
      <FormSection
        title={t("lbl.研究責任者")}
        description={description}
        commentButtonProps={commentButtonProps}
        displayMode={sectionDiffMode}
        displayChangesOnly={displayChangesOnly}
        sx={sx}
        {...rest}
      >
        <UserInfoCardContent
          documentType={documentType}
          editMode={editMode}
          onSelect={handleSelect}
          value={value ?? {}}
          changedFrom={changedFrom}
          institutionId={institutionId}
        />
        {sectionDiffMode === "updated" && (
          <ChangeReasonForm
            value={changeReason}
            onChange={onChangeReason}
            editMode={editMode}
          />
        )}
      </FormSection>
    );
  },
);
