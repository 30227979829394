import { ComponentStyleProps } from "../../../../lib/styles/props/component-style-props";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { hasValue, isNullish } from "../../../../lib/util/common-util";
import { Container, Flex, HStack, VStack } from "@chakra-ui/react";
import { RevisionRequestCommentList } from "./_components/RevisionRequestCommentList/RevisionRequestCommentList";
import { CMMessageInfo } from "@pscsrvlab/psc-react-components";
import { useAppGetRevisionRequestQuery } from "../../../../hooks/query/use-app-get-revision-request-query";
import { useSavedDocumentVM } from "../../../../hooks/document/use-saved-document-vm";
import { NewApplicationDocumentReadOnly } from "../application/new-application/NewApplicationDocumentReadOnly/NewApplicationDocumentReadOnly";
import { ChangeApplicationDocumentReadOnly } from "../application/change-application/ChangeApplicationDocumentReadOnly/ChangeApplicationDocumentReadOnly";
import { AnnualReportDocumentReadOnly } from "../report/annual-report/AnnualReportDocumentReadOnly/AnnualReportDocumentReadOnly";
import { TerminationReportDocumentReadOnly } from "../report/termination-report/TerminationReportDocumentReadOnly/TerminationReportDocumentReadOnly";
import { RevisionMainCommentBalloon } from "../../revision/RevisionMainCommentBalloon/RevisionMainCommentBalloon";
import { FrameUpperRightButton } from "../../../ui/frame/FrameUpperRightButton/FrameUpperRightButton";
import { EditButton } from "../../../ui/button/EditButton/EditButton";
import { useProjectPagePathPrefix } from "../../../../hooks/use-project-page-path-prefix";
import { useNavigate } from "react-router-dom";
import { useAppTranslation } from "../../../../hooks/use-app-translation";
import { useCanRevise } from "../../../../hooks/business-logic/use-can-revise";

export type RevisionRequestViewPageProps = {
  documentId: number;
  revisionRequestId: number;
} & ComponentStyleProps;

/**
 * 参照用修正依頼ページ
 */
export const RevisionRequestViewPage = ({
  documentId,
  revisionRequestId,

  sx,
  ...rest
}: RevisionRequestViewPageProps) => {
  const { t } = useAppTranslation();
  const navigate = useNavigate();
  const { projectPagePathPrefix } = useProjectPagePathPrefix();

  const { projectId, vmAndCallback } = useSavedDocumentVM(documentId);

  const { data: revisionRequest } = useAppGetRevisionRequestQuery({
    revisionRequestId,
  });

  const [selectedCommentPath, setSelectedCommentPath] = useState<string | null>(
    null,
  );

  const canRevise = useCanRevise(documentId);

  const showReviseButton = useMemo(() => {
    if (isNullish(revisionRequest)) return;
    return canRevise && revisionRequest.revisionRequestState === "revising";
  }, [canRevise, revisionRequest]);
  const handleClickReviseButton = useCallback(() => {
    navigate(
      `${projectPagePathPrefix}/document/${documentId}/content/revise/${revisionRequestId}/create`,
    );
  }, [revisionRequestId, navigate, projectPagePathPrefix, documentId]);

  const scrollableRef = useRef(null);

  return (
    <>
      {hasValue(projectId) &&
        hasValue(revisionRequest) &&
        hasValue(vmAndCallback) && (
          <HStack
            flex={1}
            minW={0}
            spacing={0}
            alignItems={"stretch"}
            sx={sx}
            {...rest}
          >
            <VStack
              flex={1}
              overflowY={"scroll"}
              pb={"50px"}
              ref={scrollableRef}
            >
              <Flex
                alignSelf={"stretch"}
                direction={"row"}
                alignItems={"flex-start"}
              >
                <CMMessageInfo
                  label={t("gdc.書類内容インフォメーション(書類内容は最新)")}
                  sx={{ alignSelf: "flex-start" }}
                  mt={"8px"}
                  ml={"8px"}
                />
                {showReviseButton && (
                  <FrameUpperRightButton ml={"auto"}>
                    <EditButton
                      label={t("btn.書類内容を修正するボタン")}
                      onClick={handleClickReviseButton}
                    />
                  </FrameUpperRightButton>
                )}
              </Flex>
              <HStack
                alignSelf={"stretch"}
                pl={"10px"}
                alignItems={"flex-start"}
              >
                <RevisionMainCommentBalloon
                  mode={"request"}
                  revisionRequestId={revisionRequestId}
                />
                <RevisionMainCommentBalloon
                  mode={"reply"}
                  revisionRequestId={revisionRequestId}
                />
              </HStack>
              <Container minW={"500px"} maxW={"720px"}>
                {vmAndCallback.type === "new_application" ? (
                  <NewApplicationDocumentReadOnly
                    vm={vmAndCallback.vm}
                    revisionRequestItems={revisionRequest.items}
                    onSelectComment={setSelectedCommentPath}
                    revisionMode={"readOnly"}
                    stickyAreaTop={0}
                    scrollableRef={scrollableRef}
                    scrollOffset={60}
                  />
                ) : vmAndCallback.type === "change_application" ? (
                  <ChangeApplicationDocumentReadOnly
                    vm={vmAndCallback.vm}
                    revisionRequestItems={revisionRequest.items}
                    onSelectComment={setSelectedCommentPath}
                    revisionMode={"readOnly"}
                    stickyAreaTop={0}
                    scrollableRef={scrollableRef}
                    scrollOffset={60}
                  />
                ) : vmAndCallback.type === "annual_report" ? (
                  <AnnualReportDocumentReadOnly
                    vm={vmAndCallback.vm}
                    revisionRequestItems={revisionRequest.items}
                    onSelectComment={setSelectedCommentPath}
                    revisionMode={"readOnly"}
                  />
                ) : vmAndCallback.type === "termination_report" ? (
                  <TerminationReportDocumentReadOnly
                    vm={vmAndCallback.vm}
                    revisionRequestItems={revisionRequest.items}
                    onSelectComment={setSelectedCommentPath}
                    revisionMode={"readOnly"}
                  />
                ) : null}
              </Container>
            </VStack>
            <RevisionRequestCommentList
              revisionMode={"readOnly"}
              revisionRequestItems={revisionRequest.items}
              selectedCommentPath={selectedCommentPath}
              scrollableRef={scrollableRef}
              scrollOffset={60}
            />
          </HStack>
        )}
    </>
  );
};
