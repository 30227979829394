import {
  CMFormInputRadio,
  CMFormInputTextArea,
  CMFrameSection,
} from "@pscsrvlab/psc-react-components";
import { Stack } from "@chakra-ui/react";
import React, { memo } from "react";
import { CMButtonFormCommentProps } from "@pscsrvlab/psc-react-components/src/components/form/comment";
import { ComponentStyleProps } from "../../../../../../../../lib/styles/props/component-style-props";
import { SectionContentFrame } from "../../../../../application/_components/ApplicationDocument/_components/frame/SectionContentFrame/SectionContentFrame";
import { problemPresenceMeta } from "../../../../../../../../lib/object/value/problem-presence";
import { problemDetailsMeta } from "../../../../../../../../lib/object/value/problem-details";
import { responseToProblemMeta } from "../../../../../../../../lib/object/value/response-to-problem";
import { useAppTranslation } from "../../../../../../../../hooks/use-app-translation";

export type ProblemsDuringThePeriodSectionProps = {
  editMode: "editable" | "disabled" | "readOnly";

  value: {
    problemPresence: "none" | "has_problem";
    problemDetails?: string | undefined;
    responseToProblem?: string | undefined;
  };

  onChange: (value: {
    problemPresence: "none" | "has_problem";
    problemDetails?: string | undefined;
    responseToProblem?: string | undefined;
  }) => void;

  /**
   * コメントボタンのprops。
   * これが存在すれば、コメントボタンを表示する。
   */
  commentButtonProps?: CMButtonFormCommentProps;
} & ComponentStyleProps;

/**
 * 分化細胞等の提供先の有無(分担機関以外)セクション
 */
export const ProblemsDuringThePeriodTerminationReportSection = memo(
  function CellProvisionTypeSection({
    editMode,
    value,
    onChange,
    commentButtonProps,
    sx,
    ...rest
  }: ProblemsDuringThePeriodSectionProps) {
    const { t } = useAppTranslation();

    const handleChangeProblemPresence = (val: "has_problem" | "none") => {
      if (val === "none") {
        onChange({
          problemDetails: undefined,
          responseToProblem: undefined,
          problemPresence: val,
        });
      }
      if (val === "has_problem") {
        onChange({
          problemDetails: "",
          responseToProblem: "",
          problemPresence: val,
        });
      }
    };

    const handleChangeProblemDetails = (val: string) => {
      onChange({
        ...value,
        problemDetails: val,
      });
    };

    const handleChangeResponseToProblem = (val: string) => {
      onChange({
        ...value,
        responseToProblem: val,
      });
    };

    return (
      <>
        <CMFrameSection
          title={t("lbl.期間中の問題点の有無")}
          commentButtonProps={commentButtonProps}
          sx={{ w: "100%", ...sx }}
          {...rest}
        >
          <SectionContentFrame>
            <Stack>
              <CMFormInputRadio<["has_problem", "none"]>
                direction={"row"}
                noHeader={true}
                editMode={editMode}
                value={value.problemPresence}
                valueObjectMeta={problemPresenceMeta}
                onChange={handleChangeProblemPresence}
              />
              {value.problemPresence === "has_problem" && (
                <Stack spacing={"10px"}>
                  <CMFormInputTextArea
                    label={t("lbl.問題の内容")}
                    value={value.problemDetails}
                    onChange={handleChangeProblemDetails}
                    editMode={editMode}
                    valueObjectMeta={problemDetailsMeta}
                  />
                  <CMFormInputTextArea
                    label={t("lbl.問題への対応")}
                    value={value.responseToProblem}
                    onChange={handleChangeResponseToProblem}
                    editMode={editMode}
                    valueObjectMeta={responseToProblemMeta}
                  />
                </Stack>
              )}
            </Stack>
          </SectionContentFrame>
        </CMFrameSection>
        {/*分担機関有無で無が選択された際に、分担機関情報のリセット確認のために出現するモーダル*/}
        {/*<DeleteConfirmationModal*/}
        {/*  isOpen={isOpenDeleteModal}*/}
        {/*  title={t("lbl.確認ポップアップタイトル")}*/}
        {/*  message={t("mes.分担機関リセット確認メッセージ")}*/}
        {/*  deleteButtonLabel={t("btn.削除ボタン")}*/}
        {/*  cancelButtonLabel={t("btn.キャンセルボタン")}*/}
        {/*  onConfirm={handleConfirmDelete}*/}
        {/*  onCancel={handleCancelModal}*/}
        {/*/>*/}
      </>
    );
  },
);
