import { ComponentStyleProps } from "../../../../../../lib/styles/props/component-style-props";
import { memo } from "react";
import { useAppSelector } from "../../../../../../hooks/redux-hooks";
import { selectUserInfo } from "../../../../../../store/auth/slice";
import { hasValue } from "../../../../../../lib/util/common-util";
import { TerminationReportDocument } from "../TerminationReportDocument/TerminationReportDocument";
import { TerminationReportViewModel } from "../../../../../../lib/object/vm/termination-report-view-model";
import { RevisionRequestItemViewModel } from "../../../../../../lib/object/vm/revision-request-view-model";

export type TerminationReportDocumentReadOnlyProps = {
  vm: TerminationReportViewModel;

  /**
   * 修正依頼。
   */
  revisionRequestItems?: RevisionRequestItemViewModel[];
  /**
   * 修正依頼コメントを選択したときのコールバック。
   * 未選択状態になる場合はnullを送出する。
   */
  onSelectComment?: (path: string | null) => void;
  revisionMode?: "none" | "readOnly";
} & ComponentStyleProps;

export const TerminationReportDocumentReadOnly = memo(
  function TerminationReportDocumentReadOnly({
    vm,

    revisionRequestItems,
    onSelectComment,
    revisionMode,

    sx,
    ...rest
  }: TerminationReportDocumentReadOnlyProps) {
    const loginUserInfo = useAppSelector(selectUserInfo);

    return (
      <>
        {hasValue(loginUserInfo) && (
          <TerminationReportDocument
            loginUserInfo={loginUserInfo}
            editMode={"readOnly"}
            value={vm}
            revisionRequestItems={revisionRequestItems}
            onSelectComment={onSelectComment}
            revisionMode={revisionMode}
            sx={sx}
            {...rest}
          />
        )}
      </>
    );
  },
);
