import { ComponentStyleProps } from "../../../../../../../../../lib/styles/props/component-style-props";
import { AttachmentFilesContent } from "../../content/AttachmentFilesContent/AttachmentFilesContent";
import React, { memo, useMemo } from "react";
import { AttachmentFileInformation } from "../../../../../../../../../lib/object/value/attachment-file-information";
import { IpsCellStockUsePurposeType } from "../../../../../../../../../lib/object/value/ips-cell-stock-use-purpose-type";
import { CMButtonFormCommentProps } from "@pscsrvlab/psc-react-components/src/components/form/comment";
import { ChangeReasonForm } from "../../form/ChangeReasonForm/ChangeReasonForm";
import { useSectionDiffMode } from "../../../../../../../../../hooks/document/change-application/use-section-diff-mode";
import { FormSection } from "../../../../../../../../ui/form/FormSection/FormSection";
import { AttachmentFilesSectionViewModel } from "../../../../../../../../../lib/object/vm/attachment-files-section-view-model";
import { useAppTranslation } from "../../../../../../../../../hooks/use-app-translation";
import { ChangeReasonItemViewModel } from "../../../../../../../../../lib/object/vm/change-application-view-model";

export type AttachmentFilesSectionProps = {
  documentType:
    | "new_application"
    | "change_application"
    | "project_force_update"
    | "project_force_create"
    | "project_content";
  editMode: "editable" | "readOnly";

  /**
   * ファイルアップロード時の権限制御情報付与に使用する、機関ID。
   * 機関IDと案件IDの両方が未設定の場合は、案件強制作成でしかあり得ないため、ひとまず財団プライベートに設定する。
   * ※どのように設定した場合も、書類/案件の新規保存時に、これらファイルはすべて申請者パブリックかつ機関IDと案件IDが設定された状態となる。
   */
  institutionId: number | null;
  /**
   * ファイルアップロード時の権限制御情報付与に使用する、案件ID。
   * 機関IDと案件IDの両方が未設定の場合は、案件強制作成でしかあり得ないため、ひとまず財団プライベートに設定する。
   * ※どのように設定した場合も、書類/案件の新規保存時に、これらファイルはすべて申請者パブリックかつ機関IDと案件IDが設定された状態となる。
   */
  projectId: number | null;

  /**
   * 使用実績の有無をチェックするための、研究責任者ID。
   * TODO 読み取り専用時はチェック不要（チェックするようにすると、使用実績が変わったタイミングで書類内容が見た目上変わってしまい得る）。読み取り専用時には一貫性のある表示としたいが、どのような表示とするかは決めること。
   */
  principalInvestigatorAppUserId?: number;
  /**
   * iPS細胞の使用目的
   */
  purposeType?: IpsCellStockUsePurposeType;

  valueResearchPlanProjectName?: string;
  valueAttachmentFiles: AttachmentFileInformation[];
  onChange?: (
    change: (
      before: AttachmentFilesSectionViewModel,
    ) => AttachmentFilesSectionViewModel,
  ) => void;

  changedFromResearchPlanProjectName?: string;
  changedFromAttachmentFiles?: AttachmentFileInformation[];
  changeReason?: string;
  onChangeReason?: (
    change: (before: ChangeReasonItemViewModel) => ChangeReasonItemViewModel,
  ) => void;
  displayChangesOnly?: boolean;

  /**
   * コメントボタンのprops。
   * これが存在すれば、コメントボタンを表示する。
   */
  commentButtonProps?: {
    root: CMButtonFormCommentProps | undefined;
    research_proposal: CMButtonFormCommentProps | undefined;
    certificate_of_ethics_committee_approval:
      | CMButtonFormCommentProps
      | undefined;
    management_system: CMButtonFormCommentProps | undefined;
    principal_investigator_resume: CMButtonFormCommentProps | undefined;
    organization_overview: CMButtonFormCommentProps | undefined;
    research_achievement: CMButtonFormCommentProps | undefined;
    facility_gmp_compliance: CMButtonFormCommentProps | undefined;
    other: CMButtonFormCommentProps | undefined;
  };
} & ComponentStyleProps;

/**
 * 添付資料(代表機関用)セクション
 */
export const AttachmentFilesSection = memo(function AttachmentFilesSection({
  documentType,
  editMode,

  institutionId,
  projectId,

  principalInvestigatorAppUserId,
  purposeType,

  valueResearchPlanProjectName,
  valueAttachmentFiles,
  onChange,

  changedFromResearchPlanProjectName,
  changedFromAttachmentFiles,
  changeReason,
  onChangeReason,
  displayChangesOnly,

  commentButtonProps,

  sx,
  ...rest
}: AttachmentFilesSectionProps) {
  const { t } = useAppTranslation();

  const mergedChangedFrom = useMemo(
    () => ({
      researchPlanProjectName: changedFromResearchPlanProjectName,
      attachmentFiles: changedFromAttachmentFiles,
    }),
    [changedFromResearchPlanProjectName, changedFromAttachmentFiles],
  );
  const mergedValue = useMemo(
    () => ({
      researchPlanProjectName: valueResearchPlanProjectName,
      attachmentFiles: valueAttachmentFiles,
    }),
    [valueResearchPlanProjectName, valueAttachmentFiles],
  );
  const sectionDiffMode = useSectionDiffMode(
    documentType,
    mergedChangedFrom,
    mergedValue,
    onChangeReason,
  );

  return (
    <FormSection
      title={t("lbl.添付資料（代表機関用）")}
      displayMode={sectionDiffMode}
      displayChangesOnly={displayChangesOnly}
      commentButtonProps={commentButtonProps?.root}
      sx={sx}
      {...rest}
    >
      <AttachmentFilesContent
        sectionType={"attachmentFiles"}
        documentType={documentType}
        editMode={editMode}
        institutionId={institutionId}
        projectId={projectId}
        principalInvestigatorAppUserId={principalInvestigatorAppUserId}
        purposeType={purposeType}
        valueResearchPlanProjectName={valueResearchPlanProjectName}
        valueAttachmentFiles={valueAttachmentFiles}
        onChange={onChange}
        changedFromResearchPlanProjectName={changedFromResearchPlanProjectName}
        changedFromAttachmentFiles={changedFromAttachmentFiles}
        commentButtonProps={commentButtonProps}
      />
      {sectionDiffMode === "updated" && (
        <ChangeReasonForm
          value={changeReason}
          onChange={onChangeReason}
          editMode={editMode}
        />
      )}
    </FormSection>
  );
});
