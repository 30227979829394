import React, { memo, useCallback, useMemo, useRef, useState } from "react";
import { ComponentStyleProps } from "../../../../lib/styles/props/component-style-props";
import { hasValue, isNullish } from "../../../../lib/util/common-util";
import {
  Container,
  Flex,
  HStack,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { ChangeApplicationDocumentReadOnly } from "../application/change-application/ChangeApplicationDocumentReadOnly/ChangeApplicationDocumentReadOnly";
import { NewApplicationDocumentReadOnly } from "../application/new-application/NewApplicationDocumentReadOnly/NewApplicationDocumentReadOnly";
import { useAppListRevisionRequestQuery } from "../../../../hooks/query/use-app-list-revision-request-query";
import { RevisionRequestSaved } from "../../../../lib/object/entity/revision-request";
import { CMButton } from "@pscsrvlab/psc-react-components";

import {
  useReceiveAnnualReportMutation,
  useReceiveChangeApplicationMutation,
  useReceiveNewApplicationMutation,
  useReceiveTerminationReportMutation,
} from "../../../../store/api/generated/stock-request-api";
import log from "loglevel";
import { errorMessageOf } from "../../../../lib/util/error-util";
import useCustomToast from "../../../../hooks/use-custom-toast";
import { useNavigate } from "react-router-dom";
import { AnnualReportDocumentReadOnly } from "../report/annual-report/AnnualReportDocumentReadOnly/AnnualReportDocumentReadOnly";
import { TerminationReportDocumentReadOnly } from "../report/termination-report/TerminationReportDocumentReadOnly/TerminationReportDocumentReadOnly";
import { LoginUserInfo } from "../../../../store/auth/types";
import { RevisionRequestCommentList } from "../RevisionRequestPage/_components/RevisionRequestCommentList/RevisionRequestCommentList";
import { EditButton } from "../../../ui/button/EditButton/EditButton";
import { FrameUpperRightButton } from "../../../ui/frame/FrameUpperRightButton/FrameUpperRightButton";
import { RevisionMainCommentBalloon } from "../../revision/RevisionMainCommentBalloon/RevisionMainCommentBalloon";
import { useProjectPagePathPrefix } from "../../../../hooks/use-project-page-path-prefix";
import { useAppTranslation } from "../../../../hooks/use-app-translation";
import { useCanRevise } from "../../../../hooks/business-logic/use-can-revise";
import { useSavedDocumentReadonlyVM } from "../../../../hooks/document/use-saved-document-readonly-vm";
import { ConfirmationModal } from "../../../ui/modal/ConfirmationModal/ConfirmationModal";

export type DocumentContentReadOnlyProps = {
  documentId: number;
  loginUserInfo: LoginUserInfo;
} & ComponentStyleProps;

export const DocumentContentReadOnly = memo(function DocumentContentReadOnly({
  documentId,
  loginUserInfo,

  sx,
  ...rest
}: DocumentContentReadOnlyProps) {
  const { t } = useAppTranslation();
  const navigate = useNavigate();
  const { errorToast } = useCustomToast();

  const myRole = useMemo(() => loginUserInfo.role, [loginUserInfo.role]);
  const { projectPagePathPrefix } = useProjectPagePathPrefix();

  const { projectId, document, vm } = useSavedDocumentReadonlyVM(documentId);
  const { data: activeRevisionRequests } = useAppListRevisionRequestQuery({
    documentId,
    revisionRequestState: "revising",
  });

  /**
   * 現在進行中の修正依頼。
   */
  const activeRevisionRequest: RevisionRequestSaved | undefined =
    useMemo(() => {
      if (
        isNullish(activeRevisionRequests) ||
        activeRevisionRequests.length <= 0
      )
        return undefined;
      return activeRevisionRequests[0];
    }, [activeRevisionRequests]);

  const canRevise = useCanRevise(documentId);

  const mode = useMemo(() => {
    if (isNullish(document)) return undefined;

    // 以下の作りだと、書類より後に修正依頼を取得した場合、一瞬だけnormalモードになってからrevisingモードに変わるが、参照画面なのでそれで問題ないと思われる。

    const _position = document.documentPosition;
    const _state = document.documentState;

    if (
      myRole === "office_member" &&
      _position === "office_member" &&
      _state === "submitted"
    )
      return "reception";
    if (canRevise) return "revising";

    // ※修正依頼中も、申請者以外は通常の表示モードとする。

    return "normal";
  }, [document, myRole, canRevise]);

  const revisionMode = useMemo(
    () => (mode === "revising" ? "readOnly" : "none"),
    [mode],
  );

  /**
   * trueの場合、申請者用の下書き編集ボタンを表示する。
   */
  const showEditDraftButton: boolean = useMemo(() => {
    if (isNullish(document)) return false;
    return (
      myRole === "applicant" &&
      document.documentPosition === "applicant" &&
      document.documentState === "draft"
    );
  }, [document, myRole]);
  /**
   * trueの場合、事務局用の編集ボタンを表示する。
   */
  const showOfficeMemberEditButton: boolean = useMemo(() => {
    if (isNullish(document)) return false;
    return (
      myRole === "office_member" &&
      document.documentPosition === "office_member" &&
      [
        "office_check",
        "reviewing",
        "awaiting_conclusion_rereview",
        "awaiting_conclusion",
        "awaiting_conclusion_no_review",
        "awaiting_conditions_met",
      ].includes(document.documentState)
    );
  }, [document, myRole]);

  const handleClickEditDraftButton = useCallback(() => {
    if (isNullish(document)) return;
    switch (document.documentType) {
      case "new_application":
        navigate(`/document/create-new-application/create/${documentId}`);
        break;
      case "change_application":
        navigate(
          `/document/create-change-application/${document.projectId}/create/${documentId}`,
        );
        break;
      case "annual_report":
        navigate(
          `/document/create-annual-report/${document.projectId}/create/${documentId}`,
        );
        break;
      case "termination_report":
        navigate(
          `/document/create-termination-report/${document.projectId}/create/${documentId}`,
        );
        break;
    }
  }, [document, navigate, documentId]);
  const handleClickOfficeMemberEditButton = useCallback(() => {
    navigate(`${projectPagePathPrefix}/document/${documentId}/content/edit`);
  }, [projectPagePathPrefix, documentId, navigate]);
  const handleClickReviseButton = useCallback(() => {
    if (isNullish(activeRevisionRequest)) return;
    navigate(
      `${projectPagePathPrefix}/document/${documentId}/content/revise/${activeRevisionRequest.id}/create`,
    );
  }, [activeRevisionRequest, navigate, projectPagePathPrefix, documentId]);

  //region 受付関係
  const [receiveNewApplication] = useReceiveNewApplicationMutation();
  const [receiveChangeApplication] = useReceiveChangeApplicationMutation();
  const [receiveAnnualReport] = useReceiveAnnualReportMutation();
  const [receiveTerminationReport] = useReceiveTerminationReportMutation();

  const {
    isOpen: isOpenReceptionModal,
    onOpen: onOpenReceptionModal,
    onClose: onCloseReceptionModal,
  } = useDisclosure();
  const handleClickReceiveButton = useCallback(() => {
    if (mode !== "reception") return;
    onOpenReceptionModal();
  }, [mode, onOpenReceptionModal]);
  const handleConfirmReceive = useCallback(async () => {
    if (mode !== "reception" || isNullish(document)) return;
    try {
      switch (document.type) {
        case "new_application": {
          await receiveNewApplication({ documentId }).unwrap();
          break;
        }
        case "change_application": {
          await receiveChangeApplication({ documentId }).unwrap();
          break;
        }
        case "annual_report": {
          await receiveAnnualReport({ documentId }).unwrap();
          break;
        }
        case "termination_report": {
          await receiveTerminationReport({ documentId }).unwrap();
          break;
        }
      }
    } catch (e) {
      log.error(errorMessageOf(e));
      errorToast(t("mes.汎用エラーメッセージ"));
      return;
    }
    navigate(
      `${projectPagePathPrefix}/document/${documentId}/content/received`,
    );
  }, [
    projectPagePathPrefix,
    document,
    documentId,
    errorToast,
    mode,
    receiveAnnualReport,
    receiveChangeApplication,
    receiveNewApplication,
    receiveTerminationReport,
    t,
    navigate,
  ]);
  //endregion

  //region 修正依頼関係
  const [selectedCommentPath, setSelectedCommentPath] = useState<string | null>(
    null,
  );
  //endregion

  const scrollableRef = useRef(null);

  return (
    <>
      {hasValue(projectId) && hasValue(vm) && (
        <HStack
          flex={1}
          alignSelf={"stretch"}
          alignItems={"stretch"}
          className={"DocumentContentReadOnly"}
          overflow={"hidden"}
          spacing={0}
          sx={sx}
          {...rest}
        >
          <VStack flex={1} overflowY={"auto"} pb={"50px"} ref={scrollableRef}>
            <Flex direction={"row"} alignSelf={"stretch"}>
              {mode === "reception" && (
                <VStack
                  flex={1}
                  pt={"24px"}
                  pb={"24px"}
                  borderColor={"gray.300"}
                  borderBottomWidth={"1px"}
                >
                  <Text>{t("mes.受付確認")}</Text>
                  <CMButton
                    size={"sm"}
                    onClick={handleClickReceiveButton}
                    label={t("btn.書類を受け付けるボタン")}
                  />
                </VStack>
              )}
              {showEditDraftButton && (
                <FrameUpperRightButton ml={"auto"}>
                  <EditButton onClick={handleClickEditDraftButton} />
                </FrameUpperRightButton>
              )}
              {showOfficeMemberEditButton && (
                <FrameUpperRightButton ml={"auto"}>
                  <EditButton onClick={handleClickOfficeMemberEditButton} />
                </FrameUpperRightButton>
              )}
              {mode === "revising" && hasValue(activeRevisionRequest) && (
                <>
                  <HStack
                    pt={"10px"}
                    pl={"10px"}
                    mr={"10px"}
                    alignItems={"flex-start"}
                  >
                    <RevisionMainCommentBalloon
                      mode={"request"}
                      revisionRequestId={activeRevisionRequest.id}
                    />
                    <RevisionMainCommentBalloon
                      mode={"reply"}
                      revisionRequestId={activeRevisionRequest.id}
                    />
                  </HStack>
                  <FrameUpperRightButton ml={"auto"}>
                    <EditButton
                      label={t("btn.書類内容を修正するボタン")}
                      onClick={handleClickReviseButton}
                    />
                  </FrameUpperRightButton>
                </>
              )}
            </Flex>
            <Container minW={"500px"} maxW={"720px"}>
              {vm.type === "new_application" ? (
                <NewApplicationDocumentReadOnly
                  vm={vm}
                  revisionMode={revisionMode}
                  revisionRequestItems={activeRevisionRequest?.items}
                  onSelectComment={setSelectedCommentPath}
                  scrollableRef={scrollableRef}
                  scrollOffset={60}
                />
              ) : vm.type === "change_application" ? (
                <ChangeApplicationDocumentReadOnly
                  vm={vm}
                  revisionMode={revisionMode}
                  revisionRequestItems={activeRevisionRequest?.items}
                  onSelectComment={setSelectedCommentPath}
                  scrollableRef={scrollableRef}
                  scrollOffset={60}
                />
              ) : vm.type === "annual_report" ? (
                <AnnualReportDocumentReadOnly
                  vm={vm}
                  revisionMode={revisionMode}
                  revisionRequestItems={activeRevisionRequest?.items}
                  onSelectComment={setSelectedCommentPath}
                />
              ) : vm.type === "termination_report" ? (
                <TerminationReportDocumentReadOnly
                  vm={vm}
                  revisionMode={revisionMode}
                  revisionRequestItems={activeRevisionRequest?.items}
                  onSelectComment={setSelectedCommentPath}
                />
              ) : null}
            </Container>
            {mode === "reception" && (
              <VStack flex={1} pb={"24px"}>
                <Text>{t("mes.受付確認")}</Text>
                <CMButton
                  size={"sm"}
                  onClick={handleClickReceiveButton}
                  label={t("btn.書類を受け付けるボタン")}
                />
              </VStack>
            )}
          </VStack>
          {mode === "revising" && hasValue(activeRevisionRequest) && (
            <RevisionRequestCommentList
              revisionMode={"readOnly"}
              revisionRequestItems={activeRevisionRequest.items}
              selectedCommentPath={selectedCommentPath}
              scrollableRef={scrollableRef}
              scrollOffset={60}
            />
          )}
        </HStack>
      )}

      {/* 受付の確認モーダル */}
      <ConfirmationModal
        isOpen={isOpenReceptionModal}
        onCancel={onCloseReceptionModal}
        message={t("mes.受付確認メッセージ")}
        onSubmit={handleConfirmReceive}
      />
    </>
  );
});
